@mixin InitializeLoading(){
  .rocket-loading-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow:hidden;
    background: #f2f7fc;
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .rocket-full{
      z-index:2;
      display: flex;
      align-items: center;
      .rocket{
        height: 150px;
        animation: rocket_animation 0.42s cubic-bezier(.36,.07,.19,.97) both infinite;
        &-fire{
          position: relative;
          width: 90px;
          height: 45px;
          background: #ffe36c;
          border-radius: 100px 60px 60px 100px / 50px 50px 50px 50px;
          box-shadow: -5px 0px 100px 0px #ffad00;
          animation: flame-twirl 0.9s ease infinite, rocket_animation 0.42s cubic-bezier(.36,.07,.19,.97) both infinite;
          &-red{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translate(0%, -50%);
            width: 40px;
            height: 20px;
            background: #f6a32c;
            border-radius: 40px 20px 20px 40px / 16px 16px 16px 16px;
            box-shadow: 0px 0px 60px 0px #ffad00;
            animation: flame-twirl 0.9s ease infinite;
          }
        }
      }
    }

    p{
      font-size:2rem;
      text-transform: uppercase;
      font-weight:bold;
      font-family: Noto sans, sans-serif;
      color: #5c83ce;
      z-index:2;
      .dot{
        opacity:0;
        animation: dot 1s ease infinite;
      }
      .dot1{
        animation-delay:0;
      }
      .dot2{
        animation-delay:0.33s;
      }
      .dot3{
        animation-delay:0.66s;
      }
    }

    .planet1{
      position: absolute;
      width: 500px;
      right:-30%;
      top:10%;
      animation: planet 1.5s linear infinite;
    }

    .planet2{
      position: absolute;
      width: 100px;
      right:-30%;
      top:60%;
      animation: planet 3s linear infinite;
    }

    .planet3{
      position: absolute;
      width: 200px;
      right:-30%;
      top:75%;
      animation: planet 1.9s linear infinite;
    }
    &--close{
      transform: translate(100%,0);
    }
    &--hide{
      display: none;
    }
  }

  /********** ANIMATIONS **********/

  @keyframes rocket_animation {
    10%, 90% {
      transform: translate3d(-1px, 1px, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 2px, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-2px, -2px, 0);
    }
    40%, 60% {
      transform: translate3d(2px, 2px, 0);
    }
  }

  @keyframes flame-twirl {
    0%,
    22%,
    49%,
    62%,
    81%,
    100% {
      border-radius: 100px 60px 60px 100px / 60px 50px 50px 40px;
    }
    14%,
    32%,
    56%,
    70%,
    89% {
      border-radius: 180px 60px 60px 180px / 40px 50px 50px 60px;
    }
  }

  @keyframes planet {
    from{
      right:-30%;
    }
    to{
      right:130%;
    }
  }

  @keyframes dot {
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
}
@include InitializeLoading();
