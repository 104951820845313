@mixin InitializeBenefitsBlockV2() {
  .benefitsV2-container {
    padding-bottom: 50px;
    img{
      width:85px;
    }
    text-align: center;
    h4{
      color: #5C83CE;
      font-size: 1.5rem;
      margin: 0;
    }
    p{
        font-size: 1rem;
        margin: 0;
    }
  }
}

@include InitializeBenefitsBlockV2();