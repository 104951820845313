@mixin InitializeRegister(){
    .register-container{
      @extend .common-container;
      @include breakpointDown(sm){
        padding-top: 170px;
      }
      .title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
          font-weight: 700;
          margin:0 0 80px 0;
          font-size: 1.2rem;
        }
      }

      p{
        font-weight: bold;
      }

      form{
        p{
            margin-bottom: 20px;
          margin-top: 45px;
          text-transform: uppercase;
          font-size: 1.2rem;
        }
        .button-light{
          margin: 0 auto;
          display: block;
          padding: 15px 40px;
          margin-bottom: 30px;
        }
      }
      a{
        color: #5c83ce;
        text-decoration: none;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      .fiscal-container{
        display: flex;
        justify-content: space-between;
        gap:15px;
        @include breakpointDown(sm){
          flex-direction: column;
        }
        div[class*='input-container']{
          width: -webkit-fill-available;
        }
        margin-bottom: 30px;
      }

      .width-100-sm{
        width: 45%;
        @include breakpointDown(sm){
          width: 100%;
        }
      }
      .double-textbox{
        display: flex;
        justify-content: space-between;
        div[class*='input-container']{
          width: 49%;
        }
        margin-bottom: 30px;
        @include breakpointDown(sm){
          flex-direction: column;
          div[class*='input-container']{
            width: 100%;
          }
        }
      }
    }
}

@include InitializeRegister();