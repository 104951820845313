@mixin InitializeHome(){
  .row-art-1{
    background-image: url("./image/image-fond-haut.jpg") ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    height: fit-content;
    .container-image-column{
      @include breakpointDown(lg){
        align-items: center;
        display: flex;
        flex-direction: column;
      }
      padding-left: 40px;
    }
    .ddal-h2 {
      font-size: 20px;
      font-weight: bold;
    }
    .ddal-span {
      font-size: 75px;
      margin-right: 15px;
    }
    .position-image{
      margin-top: 230px;
      margin-left: auto;
      @include breakpointDown(xl)
        {
            margin-top: 50px;
            margin-left: 0px;
        }
    }
  }

  .marge-left{
    @include breakpointDown(xl)
        {
      padding: 0 60px;
        }
    @include breakpointDown(lg)
        {
        padding: 0 30px;
            }
    @include breakpointDown(sm)
        {
        padding: 0 15px;
    }
  }
  .row-art-2{
    height: fit-content;
  }
  .row-art-2-1{
    padding-bottom: 200px;

    @include breakpointDown(xl)
        {
      padding-bottom: 30px;
        }

    @include breakpointDown(sm)
        {
      padding-bottom: 120px;
        }

    .container{
      height: 100%;
      width: 100%;
      @include breakpointDown(xl)
        {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        }
      @include breakpointDown(sm)
        {
        gap:20px;
        }
    }
    .position-image-man{
    }
    .block-text{

      h2{
        font-size: 2rem;
      }
    }
    .position-image-girl{
      position: absolute;
      top: 40%;
      right: 0px;
      @media screen and (max-width: 1500px){
        right: -100px;
      }
      @include breakpointDown(xl)
        {
        position: inherit;
            top: 0;
            left: 0;
        }
    }
  }
}

@include InitializeHome();