@mixin breakpointUp($name: lg) {
  @media screen and (min-width: map-get($devices, $name)) {
    @content;
  }
}

@mixin breakpointDown($name: lg) {
  @media screen and (max-width: map-get($devices, $name)) {
    @content;
  }
}

/*____________________DISPLAY HELPER____________________*/
@for $i from 1 through length($displays) {
  $display: nth($displays, $i);
  .d-#{$display} {
    display: $display;
  }
}

/*____________________JUSTIFY HELPER____________________*/
@for $i from 1 through length($justify-contents) {
  $justify-content: nth($justify-contents, $i);
  .jc-#{$justify-content} {
    justify-content: $justify-content;
  }
}

/*____________________ALIGN HELPER____________________*/
@for $i from 1 through length($align-items) {
  $align-item: nth($align-items, $i);
  .ai-#{$align-item} {
    align-items: $align-item;
  }
}

/*____________________HEIGHT HELPER____________________*/
@for $i from 1 through 10 {
  $height: $i * 10;
  .h-#{$height} {
    height: #{$height} + '%';
  }
}

/*____________________WIDTH HELPER____________________*/
@for $i from 1 through 10 {
  $width: $i * 10;
  .w-#{$width} {
    width: #{$width} + '%';
  }
}

@for $i from 1 through length($widths){
    $width: nth($widths, $i);
    .w-#{$width}{
        width: $width;
    }
}

/*____________________POSITIONS HELPER____________________*/
@for $i from 1 through length($positions) {
  $position: nth($positions, $i);
  .pos-#{$position} {
    position: $position;
  }
}

/*____________________MARGIN HELPER____________________*/
@for $i from 0 through 10 {
  $margin: $i * 10;
  .m-#{$margin} {
    margin: #{$margin} + 'px';
  }
}

@for $i from 0 through 10 {
  $margin: $i * 10;
  .mt-#{$margin} {
    margin-top: #{$margin} + 'px' !important;
  }
}

@for $i from 0 through 10 {
  $margin: $i * 10;
  .ml-#{$margin} {
    margin-left: #{$margin} + 'px' !important;
  }
}

@for $i from 0 through 10 {
  $margin: $i * 10;
  .mr-#{$margin} {
    margin-right: #{$margin} + 'px' !important;
  }
}

@for $i from 0 through 10 {
  $margin: $i * 10;
  .mb-#{$margin} {
    margin-bottom: #{$margin} + 'px' !important;
  }
}

@for $i from 0 through 10 {
  $margin: $i * 10;
  .m-percent-#{$margin} {
    margin: #{$margin} + '%';
  }
}


.m-left-auto {
  margin-left: auto;
}

.m-right-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/*____________________PADDING HELPER____________________*/
@for $i from 0 through 10 {
  $padding: $i * 10;
  .p-#{$padding} {
    padding: #{$padding} + 'px'!important;
  }
}

@for $i from 0 through 10 {
  $padding: $i * 10;
  .p-percent-#{$padding} {
    padding: #{$padding} + '%'!important;
  }
}

@for $i from 0 through 10 {
  $padding: $i * 10;
  .pt-#{$padding} {
    padding-top: #{$padding} + 'px'!important;
  }
}

/*____________________OVERFLOW HELPER____________________*/
@for $i from 1 through length($overflows) {
  $overflow: nth($overflows, $i);
  .overflow-#{$overflow} {
    overflow: $overflow;
  }
}

/*____________________Z-INDEX HELPER____________________*/
@for $i from 1 through 10 {
  $z-index: $i * 10;
  .z-#{$z-index} {
    z-index: #{$z-index};
  }
}

/*____________________TEXT ALIGN HELPER____________________*/
@for $i from 1 through length($text-aligns) {
  $text-align: nth($text-aligns, $i);
  .text-#{$text-align} {
    text-align: $text-align;
  }
}

/*____________________FONT SIZE____________________*/
@for $i from 1 through 10 {
  $font-size: $i * 10;
  .fs-#{$font-size} {
    font-size: #{$font-size} + 'px';
  }
}

/*____________________GAP____________________*/
@for $i from 1 through 10 {
  $font-size: $i * 10;
  .gap-#{$font-size} {
    gap: #{$font-size} + 'px';
  }
}