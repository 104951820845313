@mixin InitializeForgotPassword(){
  .forgot-password-container{
    background: #f2f7fc;
    height: fit-content;
    min-height: 100%;

    .common-block{
      box-shadow: 0px 0px 30px 1px rgba(220, 233, 249, 1);
      padding: 75px;
      border-radius: 60px;
      background-color: #fff;
    }

    .button-light{
      margin: 0 auto;
      display: block;
      padding: 15px 40px;
    }
  }
  .success-password-container{
    p{
      font-weight: bold;
        font-size: 2rem;
      text-align: center;
      color: #5C83CE;
    }
    img{
      display: block;
      margin: 0 auto;
    }
  }
}
@include InitializeForgotPassword();