@mixin InitializeDemonstrator(){
  .demonstrator-container{
    @include breakpointDown(xl){
      .button-light{
        margin-top: 20px;
      }
    }

    .svg-question-mark{
      fill: #5C83CE;
      height: 20px;
      margin-top: 25px;
      margin-left: 10px;
    }

    .assistance-block-static{
      @media screen and (max-width: 380px){
        font-size: 0.8rem;
      }
      p{
        font-weight: 400;
      }

    }

    .assistance-block{
      padding: 20px;
      background: #e1e8fa;
        border-radius: 10px;
      position: absolute;
      z-index: 100;
top: -230px;
      left: 160px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      font-weight: 500;
      &--display{
        pointer-events: auto;
        opacity: 1;
      }
    }

    .mail-form-container{
      p{
        text-align: center;
      }
      .warning{
        color: #E11D1D;
        margin: 0;
        font-weight: 500;
        a{
            color: #5C83CE;
            text-decoration: underline;
        }
      }
      .p-title{
        font-size: 1.5rem;
        font-weight: 700;
        color: #5C83CE;
        margin-bottom: 10px;
      }
      button{
        display: block;
        margin: 0 auto;
      }
    }

    div[role="tree"]{
      overflow: auto;
      @for $i from 576 through 300 {
        $w : $i / 2.1;
        @media screen and (max-width: #{$i} + 'px') {
          width: #{$w} + 'px';
        }
      }
    }


    padding-bottom: 100px;

    .example-card{
      padding: 10px;
      transition: all 0.3s ease-in-out;
      border-radius: 10px;
      cursor: pointer;
      background-color: #ceddfd;
      margin-bottom: 5px;
      &:hover{
        background-color: #a2bdfc;
        color: white;
      }
      &--selected{
        background-color: #5C83CE;
        color: white;
      }
    }
    .inner-block{
      height: calc(100% - 32px);
      padding: 30px;
        border-radius: 30px;
        background-color: #f2f7fc;
      @include breakpointDown(sm){
        padding: 20px;
      }
      &--blue{
        background-color: #ceddfd;
      }
    }
    .parent{
      font-weight: bold;
      color: #5C83CE;
      cursor: pointer;
    }
    h5{
        font-size: 1.2rem;
        font-weight: 700;
        color: #5C83CE;
      margin-bottom: 10px;
      margin-top: 25px;
    }
    .tree{
      height: fit-content!important;
      width: 100%!important;
      text-wrap: nowrap;
    }
  }
}

@include InitializeDemonstrator();