@mixin InitializeBenefitsDDAL(){
  .benefitsDDAL-container{
    .common-block-tree{
      flex-direction: row;
    }
    button{
        margin: 0 auto;
        display: block;
    }
    h4{
      margin: 0;
    }
    @include breakpointUp(lg){
      .col-lg-4{
        flex: 0 0 32.3333333333%;
        max-width: 32.3333333333%;
      }
    }
  }
}

@include InitializeBenefitsDDAL();