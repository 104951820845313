@mixin InitializeContact(){
  .contact-container{
    @extend .common-container;

    @include breakpointDown(sm){
      padding-top: 170px;
    }



    p{
      font-weight: 700;
      margin:0 0 80px 0;
      font-size: 1.2rem;
      @include breakpointDown(sm){
        text-align: center;
      }
    }

    .double-textbox{
      display: flex;
      justify-content: space-between;
      div[class*='input-container']{
        width: 49%;
      }
      margin-bottom: 30px;
      @include breakpointDown(sm){
        flex-direction: column;
        div[class*='input-container']{
          width: 100%;
        }
      }
    }
    .button-light{
      margin: 0 auto;
      display: block;
      padding: 15px 40px;
    }
  }
}

@include InitializeContact();