@mixin InitializePrice(){
  .price-container{
    @extend .common-container;
    @include breakpointDown(sm){
      padding-top: 170px;
    }

    .advantage-block{
      img{
        display: block;
        margin: 0 auto;
        width: 80px;
      }
      h4{
        text-align: center;
        font-size: 1.3rem;
        color: #5C83CE;
      }
        p{
            text-align: center;
            color: #202020;
          a{
            color: #5C83CE;
            text-decoration: none;
          }
        }
    }
    .column-free{
      padding-top: 85px;
      padding-right: 30px;
      margin-right:50px;
      @include breakpointDown(sm){
        margin:0;
        padding: 30px 30px;
      }
      @include breakpointDown(md){
        margin:0;
      }
      @include breakpointDown(lg){
        margin:0;
      }
    }
    .column-pro{
      margin-left:50px;
      padding-top: 40px;
      padding-left: 10px;
      @include breakpointDown(sm){
        margin:0;
        padding: 30px 30px;
        order:-1;
      }
      @include breakpointDown(md){
        margin:0;
      }
      @include breakpointDown(lg){
        margin:0;
      }
    }

    button{
      padding: 15px 45px !important;
      @include breakpointDown(sm){
        padding: 10px 30px !important;
      }
    }

    h4{
      margin-bottom: 0;
      margin-top: 30px;
      text-transform: uppercase;
      text-align: left;
      font-weight: 700;
      @include breakpointDown(sm){
        margin-top: 20px;
      }
    }

    svg{
      height: 110px;
      margin: 0 auto;
      display: block;
        @include breakpointDown(sm){
            height: 80px;
        }
    }

    %container-common{
      box-shadow: 1px 1px 30px 0px rgba(43,49,145,0.49);
      border-radius: 50px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      transition: all 0.3s;
      @include breakpointDown(sm){
        padding-bottom: 60px;
      }
    }

    .container-free{
      @extend %container-common;
      background: white;
      padding-bottom: 60px;
      padding-top: 60px;

      h2{
        color: #5c83ce;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      .button-light{
        margin: 0 auto;
        display: block;

        .span-dark{
          z-index: 1!important;
        }

      }
      h4{
        color: #5c83ce !important;
        font-size: 1.7rem;
        @include breakpointDown(sm){
          font-size: 1.3rem;
        }
      }
      p{
        color:#202020;
        position: relative;
        text-align: left;
        margin-bottom: 40px;
        font-size: 1.3rem;
        margin-top: 10px;
        @include breakpointDown(sm){
          font-size: 1.1rem;
        }
        &:after{
          content:'';
          position: absolute;
          bottom:-10px;
          left:0;
          width: 100%;
          background: #202020;
          height: 2px;
        }
      }
    }

    .container-pro{
      @extend %container-common;
      background: linear-gradient(-25deg, rgba(167,196,252,1) 0%, rgba(43,49,145,1) 100%);
      padding-bottom: 60px;
      padding-top: 60px;
      margin-left: auto;

      h2{
        color: white;
        text-transform: uppercase;
        margin-bottom: 0px;
      }

      .button-white{
        margin: 0 auto;
        display: block;
        .span-dark{
          z-index: 1!important;
        }
        label{
          font-size: 1.8rem!important;
          font-weight: 600;
        }
      }
      h4{
        color: white !important;
        font-size: 2rem;
        @include breakpointDown(sm){
          font-size: 1.7rem;
        }

      }

      p{
        color:white;
        position: relative;
        text-align: left;
        margin-bottom: 60px;
        font-size: 1.7rem;
        margin-top: 10px;
        @include breakpointDown(sm){
          font-size: 1.3rem;
        }
        &:after{
          content:'';
          position: absolute;
          bottom:-10px;
          left:0;
          width: 100%;
          background: white;
          height: 2px;
        }
      }

      svg{
        fill: white;
      }
    }

    .pricing-table{
      border-collapse:separate;
      border-spacing:0;
      width:100%;
      table-layout: fixed;
      a{
        text-decoration: none;
        color: #5C83CE;
        cursor: pointer;
      }
      th{
        padding: 30px;
        font-weight: 400;
        line-height: 30px;
        @include breakpointDown(sm){
          font-size: 0.85rem;
          padding: 20px;
        }
        span{
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
      svg{
        height: 30px;
        fill: #2C358B;
      }
      tr:nth-child(even){
        background-color: #f0f0f0;
      }
    }

    .check-background{
      fill:#2C358B;
    }
    .check-inner-background{
      fill:#FFFFFF;
    }
    .header-th{
      font-weight: bold!important;
        font-size: 1.2rem;
      @include breakpointDown(sm){
        font-size: 0.9rem;
      }
      svg{
        height: 50px;
      }
      p{
        font-size: 1.1rem;
        font-weight: bold;
        color:#2C358B;
        text-transform: uppercase;
      }
    }
  }
}

@include InitializePrice();