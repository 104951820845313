@use "sass:math";

$gutter: 1.5rem !default;
$columns: 12 !default;

.row {
  display: flex;
  flex-wrap: wrap;

  &-centered {
    @extend .row;
    justify-content: center;
  }
}

//Cause a bug with the flexbox :
//div[class^="col-"] .row {
// margin-left: -15px;
//  margin-right: -15px;
//}


%col {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}

@mixin make-col($device: '') {
  @for $i from 1 through $columns {
    .col-#{$device}#{$i} {
      flex: 0 0 calc($i / $columns * 100%);
      max-width: calc($i / $columns * 100%);
    }
    .col-push-#{$device}#{$i} {
      margin-left: math.percentage(math.div($i, $columns));
    }
  }
}

@mixin generateColumns() {
  @for $i from 1 through $columns {
    .col-#{$i} {
      @extend %col;
    }
  }
  @each $device, $value in $devices {
    @for $i from 1 through $columns {
      .col-#{$device}-#{$i} {
        @extend %col;
      }
    }
  }
}

@include generateColumns();

@include make-col();

@each $device, $value in $devices {
  @include breakpointUp($device) {
    @include make-col($device + '-');
  }
}
