@mixin InitializeAddPaymentMethod(){
  .add-payment-method-container{
    padding: 50px;
    background: white;
    border-radius: 20px;
    max-height: 700px;
    overflow: auto;
    button{
      margin-top: 20px!important;
      display: block;
        margin: 0 auto;
    }
  }
}

@include InitializeAddPaymentMethod();