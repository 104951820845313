@mixin subButton($name,$color){
    .button--#{$name}{
    background: none;
    border-radius: 5px;
    border: 1px solid $color;
    font-weight: bold;
    font-size: 0.95rem;
    padding: 5px 20px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color: $color;
        width: 120px;
        &--disabled{
            opacity: 0.3;
            cursor: not-allowed;
            &:hover{
                background: none!important;
                color: $color!important;
            }
        }
    &:hover{
        background: $color;
        color: white;
    }
    }
}

@mixin subStatus($status, $color){
    .status--#{$status}{
        color: $color;
        font-weight: bold;
    }
}


@mixin InitializeMySubscription(){
    @include subStatus('canceled',#E11D1D);
    @include subStatus('active',#46b642);
    @include subStatus('pay',#f28315);
    @include subStatus('trial',#5C83CE);
    @include subStatus('incomplete',#f28315);
    @include subStatus('incomplete_expired', #e14b1d);

    .my-subscription-container{
        table{
            margin-top: 50px;
            border-collapse: collapse;
            tr:nth-child(even){
                background-color: #F3F7FF;
            }
            td{
                padding: 5px 20px;
            }
            th{
                border-bottom: #5C83CE 2px solid;
                padding: 10px 20px;
                color:#5C83CE;
                text-align: left;
            }
            @include subButton('cancel',#E11D1D);
            @include subButton('renew',#46b642);
            @include subButton('pay',#f28315);



        }
        .subscription-card{
            &--title{
                box-shadow: none;
                margin-bottom: 0;
                &:hover{
                    transform: translate(0,0) !important;
                }
            }
            padding: 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            width: fit-content;
            p{
                margin: 0;
            }

            box-shadow: 0px 0px 15px 1px rgba(43, 49, 145, 0.1);
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            border-radius: 20px;
            @include subButton('cancel',#E11D1D);
            @include subButton('renew',#46b642);
            @include subButton('pay',#f28315);

            @include subStatus('canceled',#E11D1D);
            @include subStatus('active',#46b642);
            @include subStatus('pay',#f28315);
            @include subStatus('trial',#5C83CE);
            @include subStatus('incomplete',#f28315);

            margin-bottom: 20px;
            width: 100%;
            justify-content: space-between;
            &:hover{
                transform: translate(0, -5px);
            }
            @include breakpointDown(lg){
                justify-content: center;
            }
        }

        .spinner-loading{
            svg{
                height:80px;
                margin: 0;
            }
        }


        .free-container{
            box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
            padding: 20px 10px;
            border-radius: 10px;

            margin-right: 50px;

            button{
                margin: 0 auto;
                display: block;
                padding: 10px 30px;
                label{
                    color: white!important;
                    font-weight: 700;
                    font-size: 0.9rem;
                }
            }

            label{
                color:#2b3191;
                font-weight: bold ;
                span{
                    color:#5C83CE;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-right: 5px;
                }
            }
            p{
                color:#202020;
                font-weight: bold;
                text-transform: lowercase;
                span{
                    font-size: 1.2rem;
                    color:#202020;
                    font-weight: bold;
                }
            }
            margin-bottom: 15px;
        }

        .pro-container {
            padding: 20px 10px;
            border-radius: 10px;
            background: linear-gradient(25deg, rgba(43,49,145,1) 0%, rgba(92,131,206,1) 100%);
            margin-bottom: 15px;
            svg{
                fill: white;
            }
            p{
                color: white;
                font-weight: 600;
                text-transform: uppercase;
                margin: 0;
                span{
                    text-transform: lowercase;
                }
            }
            button{
                margin: 0 auto;
                display: block;
                padding: 10px 30px;
                label{
                    font-weight: 700;
                    font-size: 0.9rem;
                }
            }
        }
        svg{
            height: 30px;
            margin-right: 20px;
        }
    }
}

@include InitializeMySubscription();
