@mixin InitializePreLaunch{
    .prelaunch-container{
        @include breakpointDown(xl){
            .mb-50{
                margin-bottom: 0px!important;
            }
            .row{
                padding: 0 50px;
            }
        }

        @include breakpointDown(sm){
            .row{
                padding: 0 5px;
            }
        }

        .no-padding{
            @include breakpointDown(xl){
                    .row{
                        padding: 0!important;
                    }
            }
        }

        .responsive-double-img{
            @include breakpointDown(xl){
                margin-bottom: 30px;
            }
        }

        .order-responsive{
            @include breakpointDown(xl){
                order: 2;
                margin-top: 20px;
            }
        }

        .row-art-2-1{
            @include breakpointDown(xl){
                padding-bottom: 0!important;
            }
        }

        .background-image{
            background-position: right top;
            background-image: url("./image/image-fond-haut.jpg") ;
            background-size: contain;
            background-repeat: no-repeat;
        }
        .background-end-image{
            background-image: url("./image/image-fond-bas.jpg") ;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom left;
        }
        h2{
            font-size: 3rem;
            font-weight: bold;
            text-align: center;
            @include breakpointDown(sm){
                font-size: 2rem;
            }
            color: #5c83ce;

        }


        .history-block-container{
            .common-block{
                background-color: #f2f7fc;
                box-shadow: none;
            }
        }
        .row-art-2-1{
            padding-bottom: 100px;
        }
        .svg-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .benefits-block{
            display: flex;
            align-items: center;
            justify-content: start;
            h3{
                font-size: 1.2rem;
                text-transform: none;
            }
            p{
                margin: 7px 0 0 0;
            }
            img{
                margin-right: 30px;
            }
        }

        .formulas-block-container{
            a{
                display: block;
                margin: 0 auto;
                width: 35%;
                text-align: center;
                text-decoration: none;
            }
        }



        .common-block{
            box-shadow: 0px 0px 30px 1px rgba(220, 233, 249, 1);
            padding: 75px;
            border-radius: 60px;
            background-color: #fff;
            @include breakpointDown(xl){
               margin-bottom: 30px;
            }
            @include breakpointDown(sm){
                padding:40px
            }
            img{
                margin: 0 auto;
                display: block;
            }
            button{
                display: block;
                margin: 0 auto;
            }
        }
        p{
            font-weight: 300;
            span{
                font-weight: bold;
            }
        }
        @include breakpointUp(xl){
            div[class*='col-xl-5']{
                flex: 0 0 47.6666666667%;
            }
        }
        h3{
            text-transform: uppercase;
            font-size: 2rem;
            margin: 0;
            color: #202020;
            @media screen and (max-width: 1500px) {
                font-size: 1.5rem;
            }
            @include breakpointDown(sm){
                font-size: 1.2rem;
            }
        }
    }
}

@include InitializePreLaunch();