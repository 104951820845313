@mixin InitializeConnexion(){
  .connexion-container{
    @extend .common-container;
    @include breakpointDown(sm){
      padding-top: 170px;
      padding-bottom: 100px;
    }
    h2{
      font-size: 1.3rem!important;
      font-weight: 700;
      margin-top: 0;
      @include breakpointDown(sm){
        text-align: center;
        margin: 0;
      }
    }
    .column-container{
      align-items: center;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      color: #202020;
      .input-container{
        font-weight: normal!important;
      }
      @include breakpointDown(sm){
        padding: 0 20px;
      }
      .container-checkbox{
        margin-top: 30px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpointDown(sm){
          flex-direction: column;
          gap:25px;
        }
        label{
          cursor: pointer;
        }
      }
      form{
        width: 100%;
        max-width: 500px;
        margin-top: 50px;
        input[type="text"], input[type="password"]{
          margin-bottom: 20px;
        }
        input[type="checkbox"]{
          margin-right: 10px;
        }
        button{
          display: block;
          margin: 0 auto;
          margin-bottom: 10px;
          .span-dark{
            z-index: 1!important;
          }
        }
      }
      a{
        color: #5c83ce;
        text-decoration: none;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}

@include InitializeConnexion();