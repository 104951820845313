@mixin treeviewInitialize() {
  .item-treeview {
    &--selected {
      .inner-icon-container {
        border-radius: 5px;
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 28px;
      height: 0;
      left: -1px;
    }

    span {
      margin-left: 0;
    }

    input {
      margin-right: 5px;
    }

    svg {
      height: 20px;
      left: -10px;
      z-index: 1;
      position: absolute;

      path {
        transition: 0.3s;
      }
    }

    .open-treeview {
      d: path("M 15 15 L 15 15") !important;
    }

    .inner-icon-container {
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 26px;

      &:focus-visible {
        outline: none;
      }

      svg {
        height: 20px;
        position: initial !important;
        margin-right: 5px;
      }
    }

  }
  ul[role="group"] {
    position: relative;
    transition: all 0.3s cubic-bezier(0.175, 0.555, 0.32, 1.175);
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: calc(100% - 14px);
      margin-left: 10px;
      left: 29px;
    }

    li {
      margin-top: 7px !important;
    }
  }
}

@include treeviewInitialize();

//////////////////////////////////////////MIXIN-COLOR////////////////////////////////////////
@mixin treeviewColor($theme) {
  ////////////////////////////VARIABLES-TREEVIEWCOLOR//////////////////////////////////////////
  $colors: (
          light: (
                  color: $blue,
                  border-color:$grey,
                  background-color: $grey-blue,
          )
  );
  .item-treeview {
    span {
      color: map-get(map-get($colors, $theme), color);
    }

    &--selected {
      .inner-icon-container {
        background-color: map-get(map-get($colors, $theme), background-color);
      }
    }

    &:before {
      border-top: 1px dashed map-get(map-get($colors, $theme), border-color);
    }
  }
  ul[role="group"] {
    &:before {
      border-left: 1px dashed map-get(map-get($colors, $theme), border-color);
    }
  }
}