//////////////////////////////////////////INITIALIZE//////////////////////////////////////////
@mixin baseModalInitialize() {

  //----------------PROPERTIES-BASEMODALINITIALIZE----------------//
  .modal-box {
    z-index: 100;
    animation: animate-modal 0.4s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cross-close-button{
    background: none;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    svg{
      fill: #202020;
      width: 20px;
      height: 20px;
    }
    &:hover{
      svg{
        fill: #5C83CE;
      }
    }
  }

  .modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0000003a;
    z-index: 10;
  }

  @keyframes animate-modal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @for $i from 1 through 10 {
    $width: $i * 10vw;
    .modal-box-#{$width} {
      @extend .modal-box;
      width: $width;
      @include breakpointDown(xl) {
        width: 70%;
      }
      @include breakpointDown(md) {
        width: 100%;
      }
    }
  }

}

@include baseModalInitialize();