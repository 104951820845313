@mixin buttonBase{
  %button-base{
    border:none ;
    border-radius:15px;
    color: white;
    padding: 18px 25px 18px 25px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    background: none;
    label{
      position: relative;
      z-index: 3;
      font-size: 1.2rem;
      font-weight: 500;
      cursor: pointer;
    }
  }
  %span-base-button{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
  }
  .button-dark-border-light{
    @extend %button-base;

    span{
      transition: 0.4s;
    }
    .span-light{
      @extend %span-base-button;
      z-index: -1;
      margin: -2px;
      background: linear-gradient(25deg, rgba(43,49,145,1) 0%, rgba(92,131,206,1) 100%)!important;
    }
    .span-dark{
      @extend %span-base-button;
      z-index: 2;
      opacity: 1;
      background: rgb(24, 38, 126)!important;
    }
    &:hover{
      .span-dark{
        opacity: 0;
      }
    }
  }

  .button-light{
    @extend %button-base;
    span{
      transition: 0.4s;
    }
    .span-light{
      @extend %span-base-button;
      z-index: 2;
      opacity: 1;
      margin: -2px;
      background: linear-gradient(25deg, rgba(43,49,145,1) 0%, rgba(92,131,206,1) 100%)!important;
    }
    .span-dark{
      @extend %span-base-button;
      z-index: 1;
      margin: -2px;
      background: rgb(24, 38, 126)!important;
    }
    &:hover{
      .span-light{
        opacity: 0;
      }
    }
  }

  .button-orange{
    @extend %button-base;
    span{
      transition: 0.4s;
    }
    .span-light{
      @extend %span-base-button;
      z-index: 2;
      opacity: 1;
      margin: -2px;
      background: linear-gradient(25deg, rgb(255, 107, 0) 0%, rgb(250, 151, 72) 100%)!important;
    }
    .span-dark{
      @extend %span-base-button;
      z-index: 1;
      margin: -2px;
      background: rgb(255, 107, 0)!important;
    }
    &:hover{
      .span-light{
        opacity: 0;
      }
    }
  }

  .button-white{
    @extend %button-base;
    label{
      color: #5c83ce!important;
    }
    span{
      transition: 0.4s;
    }
    .span-light{
      @extend %span-base-button;
      z-index: 2;
      opacity: 1;
      margin: -2px;
      background: white!important;
    }
    .span-dark{
      @extend %span-base-button;
      z-index: 1;
      margin: -2px;
      background: rgb(24, 38, 126)!important;
    }
    &:hover{
      .span-light{
        opacity: 0;
      }
      label{
        color: white!important;
      }
    }
  }
}

@include buttonBase();