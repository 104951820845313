@mixin InitializeCommonViews(){

  .disabled{
    pointer-events: none;
    opacity: 0.5;
  }

  .spinner-payment{
    display: block;
    margin: 0 auto;
    height: fit-content;
    width: fit-content;
  }

  .ul-text{
    padding: 0;
    p{
        margin: 0;
      a{
        color: #5c83ce;
        text-decoration: underline;
      }
    }
    li{
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 300;
      &:before{
        content: "";
        background: #5c83ce;
        font-weight: bold;
        min-width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }

  .newsletter-container{
    @include breakpointUp(sm){
      margin-bottom: -80px;
    }
  }

  .benefit-block{
    padding: 20px;
    background: #f2f7fc;
    border-radius: 20px;
    h4{
      color: #5C83CE;
      font-weight: 500;
    }
  }

  .common-block-tree{
    box-shadow: 0px 0px 30px 1px rgba(220, 233, 249, 1);
    padding: 75px;
    border-radius: 60px;
    background-color: #fff;
    flex-direction: column;
    @include breakpointDown(xl){
      padding: 40px!important;
    }
  }

  .margin-top-30-sm{
    @include breakpointDown(sm){
      margin-top: 30px;
    }
  }
  h5{
    font-size: 1.3rem;
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .spinner-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .success-container{
    display: block;
    margin: 0 auto;
    width: fit-content;
    padding: 50px 0;
    button{
      margin: 0 auto;
      display: block;
      margin-bottom: 30px;
      span{
        z-index: 1;
      }
    }
    img{
      display: block;
      margin: 0 auto;
      margin-top: 70px;
      @include breakpointDown(sm){
        width: 80%;
      }
    }
    p{
      @include breakpointDown(sm){
        font-size: 2rem;
      }
      font-size: 2.5rem;
      font-weight: bold;
      margin: 0;
      margin-bottom: 20px;
      color: #5C83CE;
      text-align: center;
    }
  }

  .common-container{
    background-image: url("./image/image-fond-haut-gauche.jpg") ;
    background-repeat: no-repeat;
    padding-top: 220px;
    padding-bottom: 220px;
    @include breakpointDown(sm){
      padding-top: 100px;
      padding-bottom: 100px;
    }

    h1{
      font-size: 2.5rem!important;
        text-align: center;
      span{
        color: #5c83ce;
      }
    }
  }

  .label-error{
    color: $red;
    margin-bottom: 20px;
    text-align: center;
  }
  .center-items-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@include InitializeCommonViews();