@mixin InitializeNavigationBase{
  .navigation-base{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 100;
    padding: 10px;
    transition: all 0.3s ;
    display: flex;
    @media screen and (max-width: 1450px) {
      display: none;
    }
    .logo-container{
      cursor: pointer;
    }
    &--blue{
      .navigation-horizontal-menu{
        .item{
          color: #2b3191;
        }
      }
    }
    &--sticky{
        background-color: white;
      box-shadow: 0px 0px 10px 1px rgb(43, 49, 145,0.3);
    height: 80px;
        .navigation-horizontal-menu{
            .item{
            color: #2b3191;
            }
        }
    }
  }
  .navigation-horizontal-menu{
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    align-items: center;
    .item{
      color:white;
      margin-right: 60px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      &--active{
        text-decoration: underline!important;
      }
    }

    .button-light{
      padding: 10px 20px 10px 20px !important;
      align-items: center;
      text-decoration: none;
      svg{
        margin-right: 10px;
        height: 20px;
        align-items: center;
        z-index: 20;
      }
      label{
        font-size: 0.9rem;
        white-space: nowrap;
      }
    }
  }
}

@include InitializeNavigationBase();