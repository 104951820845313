@mixin InitializeNavigationCustomer() {
  .container-navigation-customer {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
    display: flex;
    @include breakpointDown(sm){
      border-radius: 30px;
      ul{
        padding-left: 20px;
      }
    }
    .navigation{
      @include breakpointDown(sm){
        width: fit-content;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
      }
      width: 320px;
      background: #E4EDFF;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      li{
        display: flex;
        align-items: center;
        list-style: none;
        margin-top: 20px;
        position: relative;
        cursor: pointer;
        &.selected{
          a{
            font-weight: 700;
          }
          &:after{
            opacity: 1;
          }
        }
        &:after{
          background: #5C83CE;
          border-radius: 5px 0 0 5px;
          content: "";
          height: 30px;
          opacity: 0;
          position: absolute;
          right: 3px;
          top: 50%;
          transform: translate(50%, -50%);
          width: 5px;
        }
        a{
          color:#5C83CE;
          font-weight: 500;
          @include breakpointDown(sm){
            display: none;
          }
        }
        svg{
          width: 20px;
            height: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}

@include InitializeNavigationCustomer();