@mixin InitializeMySociety(){
  .my-society-container{
    padding-top:20px;
      .button-light{
        margin: 0 auto;
        display: block;
        padding: 15px 25px;
      }
  }
}

@include InitializeMySociety();