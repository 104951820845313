@mixin InitializeCustomerArea(){
  .loading-container-customer-area{
    position: absolute;
    transform: translate(-100%,0);
    transition: all 0.5s ease-in-out;
    &--show{
      transform: translate(0,0);
    }
  }
  .customer-area-container{
    @extend .common-container;
    @include breakpointDown(sm){
      padding-top: 200px;
    }
    .p-30-responsive{
       @include breakpointDown(sm) {
         padding: 0!important;
       }
    }
    .margin-responsive{
       @include breakpointDown(xl) {
         margin-top: 30px!important;
       }
    }
    h1{
      text-align: center;
    }
    .inner-container{
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      @include breakpointDown(sm){
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
      }
      background: white;
      min-height: 800px;
    }
  }
}

@include InitializeCustomerArea();