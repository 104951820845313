@mixin InitializePayment(){
    .payment-container{
      background: #f2f7fc;
      height: fit-content;
      min-height: 100%;
      transition: all 0.5s ease-in-out;
      transform: translate(-100%,0);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      svg{
        margin-bottom: 50px;
      }

      %common-block{
        box-shadow: 0px 0px 30px 1px rgba(220, 233, 249, 1);
        padding: 75px;
        border-radius: 60px;
        background-color: #fff;
      }

      .confirmation-container{
        @extend %common-block;
        text-align: center;

        img{
          width: 50px;
        }

        a{
          text-decoration: none;
        }

        h2{
          color: #46b642;
          margin-top: 0;
          margin-bottom: 40px;
        }
        .p-ammount-title{
            font-size: 1.1rem;
            font-weight: 700;
            color: #2b3191;
            margin: 0;
        }
        .p-ammount{
            font-size: 2rem;
            font-weight: 700;
            color: #2b3191;
            margin: 0;
          margin-bottom: 50px;
        }
      }

      .info-container{
          @extend %common-block;

        .total-number{
          font-size: 40px;
          font-weight: 700;
          color: #2b3191;
          margin-right: 15px;
        }
        .input-dropdown{
          height: 30px;
          width: 67px;
        }
        .add-promo-container{
          font-weight: 600;
          width: 100%;
          .input-container{
            display: block;
            width: 100%;
            padding-right: 15px;
          }
          button{
            padding: 0px 20px;
            label{
              font-size: 0.9rem;
            }
          }
        }
        .reduction-number{
          font-weight: 700;
          color: #62dc31;
        }
        .p-period{
          color: #8f8f8f;
          font-weight: 600;
          font-size: 0.9rem;
        }
        .payment-info-line{
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          align-items: center;
          margin: 0 0 20px 0;
          .input-dropdown{
            padding: 0 13px;
            border-radius: 6px;
          }
          p{
            margin: 0;
          }
        }
      }

      .card-container{
        @extend %common-block;

        .p-terms{
          font-size: 0.7rem;
          font-weight: 400;
          color: #6d6e78;
          margin: 0;
          line-height: 17px;
          a{
            color: #5c83ce;
            text-decoration: underline;
          }
        }

        button{
          display: block;
          margin: 0 auto;
          margin-top: 30px;
          padding: 15px 20px;
          label{
            font-size: 0.9rem;
          }
        }
      }

      .form-container{
        .list-payment-method-modal{
          padding: 0px 10px!important;
          overflow: auto;
          max-height: 250px;
        }
        .checkbox{
          width: 45px;
        }
        .button-add{
          margin-top: 0!important;
        }
      }
      &--open{
        transform: translate(0,0);
      }
    }
}

@include InitializePayment;