@mixin InitializeInvoices(){
  .invoices-container{
    a{
      text-decoration: none;
      color: #202020;
    }
    .invoice-card{
      &--title{
        border:none;
        cursor: initial!important;
        &:hover{
          background: none!important;
        }
      }
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      margin: 10px 0;
      border-radius: 20px;
        border: 2px solid #5C83CE;
      cursor: pointer;
        transition: all 0.3s ease-in-out;
      p{
        font-weight: bold;
        @include breakpointDown(lg){
          text-align: center!important;
        }
      }
      .amount{
        font-weight: bold;
        color: #5C83CE;
      }
        &:hover{
          background: #F3F7FF;
        }
    }
  }
}
@include InitializeInvoices();