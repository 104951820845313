@mixin navigationViewMobileInitialize {
  .navigation-view-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    background: none;
    z-index: 100;
    align-items: initial;
    justify-content: center;
    flex-direction: column;
    display: none;
    transition: all 0.4s cubic-bezier(0.175, 0.555, 0.32, 1.175);
    padding-bottom: 20px;
    @media screen and (max-width: 1450px) {
      display: flex;
    }
    .item{
      text-decoration: none;
      &--active{
        text-decoration: underline;
      }
    }
    &--sticky{
      background-color: white;
      padding-bottom: 0;
      box-shadow: 0px 0px 10px 1px rgb(43, 49, 145,0.3);
      ul{
        background: white;
      }
      .ham{
        height: 40px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      overflow: hidden;
      height: 0;
      transition: all 0.4s cubic-bezier(0.175, 0.555, 0.32, 1.175);
      background: none;
  width: 100%;
      li {
        list-style: none;
        padding: 10px 20px 10px 20px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color:#2b3191;
        font-weight: bold;
        svg {
          max-height: 20px;
          max-width: 25px;
          margin-bottom: 5px;
        }
      }
    }

    &--open {
      ul {
        height: 250px;
      }
    }
  }

  .ham {
    transition: all 0.4s;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 150px;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .hamRotate180.active {
    transform: rotate(180deg);
  }
  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke-width: 5.5;
    stroke-linecap: round;
  }
  .ham4 .top {
    stroke-dasharray: 40 121;
  }
  .ham4 .bottom {
    stroke-dasharray: 40 121;
  }
  .ham4.active .top {
    stroke-dashoffset: -68px;
  }
  .ham4.active .bottom {
    stroke-dashoffset: -68px;
  }
}

@include navigationViewMobileInitialize;

@mixin navigationViewMobileColor($theme) {
  $color: (
          light: (
                  svg:#FFFFFF,
                  svg-sticky: #2b3191,
          )
  );
.navigation-view-mobile{
  &--sticky, &--blue{
    .line {
      stroke: map-get(map-get($color, $theme), svg-sticky)!important;
    }
  }
  .line {
    stroke: map-get(map-get($color, $theme), svg);
  }
}


}