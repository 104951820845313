.container {
  &-fluid {
    width: 100%;
  }

  @include breakpointUp(xl) {
    &-xl {
      width: 1140px;
      margin: auto;
    }
  }
}