@mixin InitializeDataPersonal(){
    .data-personal-container{
      padding-top:20px;
      p{
        text-transform: uppercase;
        font-weight: 700;
      }
      .button-light{
        margin: 0 auto;
        display: block;
        padding: 15px 25px;
      }
    }
}

@include InitializeDataPersonal();
