@mixin initializeHeader{
  .header{
    width: 100%;
    height: 100vh;
    &[class*="header--article"]{
      h1{
        position: absolute;
        top: 50%;
        left: 50%;
        color: white;
        font-size: 3rem;
        transform: translate(-50%, -50%);
      }
    }
    &--article-DDAL{
      background-image:linear-gradient(25deg, rgba(1,4,57,1) 0%, rgba(43,49,145,0) 100%), url("./image/image-page-ddal.jpg") ;
      height: 50vh;
    }
    &--article-DDALEF{
      background-image:linear-gradient(25deg, rgba(1,4,57,1) 0%, rgba(43,49,145,0) 100%), url("./image/image-page-ddal-ef.jpg") ;
      height: 50vh;
    }
    background-image:linear-gradient(25deg, rgba(1,4,57,1) 0%, rgba(43,49,145,0) 100%), url("./image/image-fond-accueil.webp") ;
    background-size: cover;
    position: relative;
    .button-header{
      label{
          @include breakpointDown(sm)
          {
            font-size: 0.9rem!important;
          }
      }
    }
    .margin-button{
      @include breakpointDown(sm)
        {
          margin-right: 0;
        margin-bottom: 20px;
        }
      margin-right: 50px;
    }
    &-content{
      position: absolute;
      top: 50%;
      left:30%;
      transform: translate(-50%, -50%);
      color: white;
      @media screen and (max-width: 1500px){
        left: 40%;
      }
      @include breakpointDown(lg)
        {
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        display: flex;
        flex-direction: column;
        }
      h1{
        @include breakpointDown(sm)
        {
          font-size: 2.5rem!important;
          text-align: center;
        }
        @include breakpointDown(xl){
          font-size: 3.2rem;
        }
        span{
          font-size: 5rem;
        }
        font-size: 3rem;
        font-weight: 700;
        margin: 0;
      }
    }
  }
}

@include initializeHeader();