/*____________________DISPLAY HELPER____________________*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import 'react18-json-view/src/style.css';
.d-grid {
  display: grid;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.d-table {
  display: table;
}

.d-table-cell {
  display: table-cell;
}

.d-table-row {
  display: table-row;
}

.d-table-column {
  display: table-column;
}

.d-table-row-group {
  display: table-row-group;
}

.d-table-column-group {
  display: table-column-group;
}

.d-table-header-group {
  display: table-header-group;
}

.d-table-footer-group {
  display: table-footer-group;
}

.d-table-caption {
  display: table-caption;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-inline-grid {
  display: inline-grid;
}

.d-ruby {
  display: ruby;
}

.d-ruby-base {
  display: ruby-base;
}

.d-ruby-text {
  display: ruby-text;
}

.d-ruby-base-container {
  display: ruby-base-container;
}

.d-ruby-text-container {
  display: ruby-text-container;
}

.d-contents {
  display: contents;
}

.d-none {
  display: none;
}

.d-initial {
  display: initial;
}

.d-inherit {
  display: inherit;
}

/*____________________JUSTIFY HELPER____________________*/
.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: start;
}

.jc-end {
  justify-content: end;
}

.jc-flex-start {
  justify-content: flex-start;
}

.jc-flex-end {
  justify-content: flex-end;
}

.jc-left {
  justify-content: left;
}

.jc-right {
  justify-content: right;
}

.jc-normal {
  justify-content: normal;
}

.jc-initial {
  justify-content: initial;
}

.jc-inherit {
  justify-content: inherit;
}

.jc-space-between {
  justify-content: space-between;
}

/*____________________ALIGN HELPER____________________*/
.ai-center {
  align-items: center;
}

.ai-start {
  align-items: start;
}

.ai-end {
  align-items: end;
}

.ai-flex-start {
  align-items: flex-start;
}

.ai-flex-end {
  align-items: flex-end;
}

.ai-left {
  align-items: left;
}

.ai-right {
  align-items: right;
}

.ai-normal {
  align-items: normal;
}

.ai-initial {
  align-items: initial;
}

.ai-inherit {
  align-items: inherit;
}

/*____________________HEIGHT HELPER____________________*/
.h-10 {
  height: 10%;
}

.h-20 {
  height: 20%;
}

.h-30 {
  height: 30%;
}

.h-40 {
  height: 40%;
}

.h-50 {
  height: 50%;
}

.h-60 {
  height: 60%;
}

.h-70 {
  height: 70%;
}

.h-80 {
  height: 80%;
}

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100%;
}

/*____________________WIDTH HELPER____________________*/
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-initial {
  width: initial;
}

.w-inherit {
  width: inherit;
}

.w--webkit-fill-available {
  width: -webkit-fill-available;
}

/*____________________POSITIONS HELPER____________________*/
.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

.pos-sticky {
  position: sticky;
}

.pos-static {
  position: static;
}

.pos-initial {
  position: initial;
}

.pos-inherit {
  position: inherit;
}

/*____________________MARGIN HELPER____________________*/
.m-0 {
  margin: 0px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.m-60 {
  margin: 60px;
}

.m-70 {
  margin: 70px;
}

.m-80 {
  margin: 80px;
}

.m-90 {
  margin: 90px;
}

.m-100 {
  margin: 100px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.m-percent-0 {
  margin: 0%;
}

.m-percent-10 {
  margin: 10%;
}

.m-percent-20 {
  margin: 20%;
}

.m-percent-30 {
  margin: 30%;
}

.m-percent-40 {
  margin: 40%;
}

.m-percent-50 {
  margin: 50%;
}

.m-percent-60 {
  margin: 60%;
}

.m-percent-70 {
  margin: 70%;
}

.m-percent-80 {
  margin: 80%;
}

.m-percent-90 {
  margin: 90%;
}

.m-percent-100 {
  margin: 100%;
}

.m-left-auto {
  margin-left: auto;
}

.m-right-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/*____________________PADDING HELPER____________________*/
.p-0 {
  padding: 0px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-percent-0 {
  padding: 0% !important;
}

.p-percent-10 {
  padding: 10% !important;
}

.p-percent-20 {
  padding: 20% !important;
}

.p-percent-30 {
  padding: 30% !important;
}

.p-percent-40 {
  padding: 40% !important;
}

.p-percent-50 {
  padding: 50% !important;
}

.p-percent-60 {
  padding: 60% !important;
}

.p-percent-70 {
  padding: 70% !important;
}

.p-percent-80 {
  padding: 80% !important;
}

.p-percent-90 {
  padding: 90% !important;
}

.p-percent-100 {
  padding: 100% !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

/*____________________OVERFLOW HELPER____________________*/
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-visible {
  overflow: visible;
}

.overflow-initial {
  overflow: initial;
}

.overflow-inherit {
  overflow: inherit;
}

/*____________________Z-INDEX HELPER____________________*/
.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-60 {
  z-index: 60;
}

.z-70 {
  z-index: 70;
}

.z-80 {
  z-index: 80;
}

.z-90 {
  z-index: 90;
}

.z-100 {
  z-index: 100;
}

/*____________________TEXT ALIGN HELPER____________________*/
.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-initial {
  text-align: initial;
}

.text-inherit {
  text-align: inherit;
}

/*____________________FONT SIZE____________________*/
.fs-10 {
  font-size: 10px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fs-60 {
  font-size: 60px;
}

.fs-70 {
  font-size: 70px;
}

.fs-80 {
  font-size: 80px;
}

.fs-90 {
  font-size: 90px;
}

.fs-100 {
  font-size: 100px;
}

/*____________________GAP____________________*/
.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

.gap-50 {
  gap: 50px;
}

.gap-60 {
  gap: 60px;
}

.gap-70 {
  gap: 70px;
}

.gap-80 {
  gap: 80px;
}

.gap-90 {
  gap: 90px;
}

.gap-100 {
  gap: 100px;
}

/*//////////////////////////////BASE//////////////////////////////*/
* {
  font-family: "Noto sans", sans-serif;
}

/*//////////////////////////////LAYOUT//////////////////////////////*/
.container-fluid {
  width: 100%;
}
@media screen and (min-width: 1203px) {
  .container-xl {
    width: 1140px;
    margin: auto;
  }
}

.row, .row-centered {
  display: flex;
  flex-wrap: wrap;
}
.row-centered {
  justify-content: center;
}

.col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-push-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-push-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-push-3 {
  margin-left: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-push-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-push-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-push-6 {
  margin-left: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-push-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-push-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-push-9 {
  margin-left: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-push-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-push-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-push-12 {
  margin-left: 100%;
}

@media screen and (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-push-sm-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-push-sm-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-push-sm-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-push-sm-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-push-sm-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-push-sm-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-push-sm-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-push-sm-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-push-sm-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-push-sm-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-push-sm-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-push-sm-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-push-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-push-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-push-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-push-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-push-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-push-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-push-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-push-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-push-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-push-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-push-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-push-md-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-push-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-push-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-push-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-push-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-push-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-push-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-push-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-push-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-push-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-push-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-push-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-push-lg-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1203px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-push-xl-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-push-xl-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-push-xl-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-push-xl-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-push-xl-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-push-xl-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-push-xl-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-push-xl-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-push-xl-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-push-xl-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-push-xl-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-push-xl-12 {
    margin-left: 100%;
  }
}
/*//////////////////////////////COMPONENTS//////////////////////////////*/
/*************************************Common**************************************/
.container-img {
  box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.5);
  border-radius: 37px;
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(25deg, rgb(1, 4, 57) 0%, rgba(43, 49, 145, 0) 100%);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-box, .modal-box-100vw, .modal-box-90vw, .modal-box-80vw, .modal-box-70vw, .modal-box-60vw, .modal-box-50vw, .modal-box-40vw, .modal-box-30vw, .modal-box-20vw, .modal-box-10vw {
  z-index: 100;
  animation: animate-modal 0.4s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cross-close-button {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.cross-close-button svg {
  fill: #202020;
  width: 20px;
  height: 20px;
}
.cross-close-button:hover svg {
  fill: #5C83CE;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2274509804);
  z-index: 10;
}

@keyframes animate-modal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal-box-10vw {
  width: 10vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-10vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-10vw {
    width: 100%;
  }
}

.modal-box-20vw {
  width: 20vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-20vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-20vw {
    width: 100%;
  }
}

.modal-box-30vw {
  width: 30vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-30vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-30vw {
    width: 100%;
  }
}

.modal-box-40vw {
  width: 40vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-40vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-40vw {
    width: 100%;
  }
}

.modal-box-50vw {
  width: 50vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-50vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-50vw {
    width: 100%;
  }
}

.modal-box-60vw {
  width: 60vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-60vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-60vw {
    width: 100%;
  }
}

.modal-box-70vw {
  width: 70vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-70vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-70vw {
    width: 100%;
  }
}

.modal-box-80vw {
  width: 80vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-80vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-80vw {
    width: 100%;
  }
}

.modal-box-90vw {
  width: 90vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-90vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-90vw {
    width: 100%;
  }
}

.modal-box-100vw {
  width: 100vw;
}
@media screen and (max-width: 1203px) {
  .modal-box-100vw {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .modal-box-100vw {
    width: 100%;
  }
}

.confirmation-modal-container {
  background: white;
  border-radius: 5px;
  padding: 40px;
  text-align: center;
  box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
  border-radius: 30px;
}
.confirmation-modal-container p {
  margin-top: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.button-confirmation {
  background: none;
  border: 2px solid #5C83CE;
  border-radius: 5px;
  color: #5C83CE;
  padding: 5px 20px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.button-confirmation:hover {
  background: #5C83CE;
  color: white;
}

.add-payment-method-container {
  padding: 50px;
  background: white;
  border-radius: 20px;
  max-height: 700px;
  overflow: auto;
}
.add-payment-method-container button {
  margin-top: 20px !important;
  display: block;
  margin: 0 auto;
}

.details-subscription-container {
  background: white;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
  border-radius: 30px;
}
.details-subscription-container .title {
  color: #2b3191;
  font-weight: bold;
  font-size: 1.2rem;
}
.details-subscription-container .button-clipboard {
  background: none;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
}
.details-subscription-container .button-clipboard svg {
  margin: 0;
  height: 20px;
  fill: #5C83CE;
}
.details-subscription-container .line {
  display: flex;
}
.details-subscription-container .line p {
  font-weight: bold;
  margin-right: 10px;
}
.details-subscription-container .line a {
  color: #5C83CE;
  text-decoration: underline;
  cursor: pointer;
}
.details-subscription-container .line .mount {
  color: #5C83CE;
}

.list-payment-method-modal {
  background: white;
  border-radius: 20px;
  padding: 40px;
}
.list-payment-method-modal .payment-method-card-list {
  text-align: center;
  border: 2px solid #5C83CE;
  border-radius: 10px;
  margin: 10px 0;
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: fit-content;
}
.list-payment-method-modal .payment-method-card-list--selected {
  background: #5C83CE;
  color: #f2f7fc;
}
.list-payment-method-modal .payment-method-card-list img {
  margin-left: 10px;
}
.list-payment-method-modal .payment-method-card-list:hover {
  background: #F3F7FF;
  color: #202020;
}

/*------------------------------------Inputs-------------------------------------*/
.dropdown-list, .input-dropdown, .input {
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.input-container, .area-container {
  position: relative;
  height: fit-content;
}
.input-container label, .area-container label {
  position: absolute;
  transform: translate(20px, -50%);
  transition: all 0.3s;
  color: #818181;
  cursor: text;
}

.area-container label {
  top: 12%;
}
.area-container--focus label {
  transform: translate(10px, -20px);
  font-size: 10px;
  color: #5c83ce;
}
.area-container textarea {
  resize: none;
  height: 200px;
}

.input-container {
  position: relative;
}
.input-container svg {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 5px;
  cursor: pointer;
}
.input-container label {
  top: 50%;
}
.input-container--focus label {
  transform: translate(10px, -45px);
  font-size: 13px;
  color: #202020;
  background: none;
  padding: 3px;
  font-weight: bold;
}
.input-container--error input {
  border: 1.5px solid #e95958 !important;
}
.input-container--error label {
  color: #e95958 !important;
}

.input {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1.5px solid transparent;
}
.input--error {
  border: 1.5px solid #e95958 !important;
}
.input--error + label {
  color: #e95958 !important;
}
.input-dropdown {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1.5px solid transparent;
  padding-right: 45px;
  cursor: pointer !important;
  outline: none;
}
.input-dropdown + svg {
  position: absolute;
  width: 15px;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.input-dropdown--active + svg {
  transform: rotate(180deg) translate(50%, 50%);
}
.input-dropdown--search {
  cursor: text !important;
}

.dropdown-list {
  height: auto;
  padding: 0;
  overflow: auto;
  margin-top: 3px;
  z-index: 10;
}
.dropdown-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.dropdown-list ul li {
  list-style: none;
  padding: 10px 0 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.dropdown-list--open {
  animation: open-list-dropdown 0.3s ease-in-out forwards;
}
.dropdown-list--close {
  animation: close-list-dropdown 0.2s ease-in-out forwards;
}

@keyframes open-list-dropdown {
  0% {
    height: 0;
    opacity: 0;
    display: none;
  }
  1% {
    opacity: 1;
    display: block;
  }
  60% {
    height: 104%;
  }
  100% {
    height: 100%;
  }
}
@keyframes close-list-dropdown {
  0% {
    height: 100%;
    opacity: 1;
  }
  99% {
    opacity: 1;
    display: none;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
.checkbox {
  position: relative;
  appearance: none;
  box-sizing: content-box;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: fit-content;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  width: 25px;
}
.checkbox::before {
  content: "";
  display: block;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: 0.2s border-color ease;
  border: 1px solid;
  background: white;
}
.checkbox::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: content-box;
  top: 50%;
  left: 43%;
  transform-origin: 50% 50%;
  width: 9.6px;
  height: 16px;
  border-radius: 2px;
  transform: translate(-50%, -85%) scale(0) rotate(45deg);
  background-color: transparent;
}
.checkbox:checked::before {
  transition: 0.5s border-color ease;
}
.checkbox:checked::after {
  animation: toggleOnCheckbox 0.2s ease forwards;
}

@keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}
.item-treeview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.item-treeview--selected .inner-icon-container {
  border-radius: 5px;
}
.item-treeview:before {
  content: "";
  display: block;
  position: absolute;
  width: 28px;
  height: 0;
  left: -1px;
}
.item-treeview span {
  margin-left: 0;
}
.item-treeview input {
  margin-right: 5px;
}
.item-treeview svg {
  height: 20px;
  left: -10px;
  z-index: 1;
  position: absolute;
}
.item-treeview svg path {
  transition: 0.3s;
}
.item-treeview .open-treeview {
  d: path("M 15 15 L 15 15") !important;
}
.item-treeview .inner-icon-container {
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 26px;
}
.item-treeview .inner-icon-container:focus-visible {
  outline: none;
}
.item-treeview .inner-icon-container svg {
  height: 20px;
  position: initial !important;
  margin-right: 5px;
}

ul[role=group] {
  position: relative;
  transition: all 0.3s cubic-bezier(0.175, 0.555, 0.32, 1.175);
  overflow: hidden;
}
ul[role=group]:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: calc(100% - 14px);
  margin-left: 10px;
  left: 29px;
}
ul[role=group] li {
  margin-top: 7px !important;
}

/*------------------------------------Navigation------------------------------------*/
/*------------------------------------Buttons------------------------------------*/
.button-white, .button-orange, .button-light, .button-dark-border-light {
  border: none;
  border-radius: 15px;
  color: white;
  padding: 18px 25px 18px 25px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  background: none;
}
.button-white label, .button-orange label, .button-light label, .button-dark-border-light label {
  position: relative;
  z-index: 3;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}

.button-white .span-dark, .button-white .span-light, .button-orange .span-dark, .button-orange .span-light, .button-light .span-dark, .button-light .span-light, .button-dark-border-light .span-dark, .button-dark-border-light .span-light {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.button-dark-border-light span {
  transition: 0.4s;
}
.button-dark-border-light .span-light {
  z-index: -1;
  margin: -2px;
  background: linear-gradient(25deg, rgb(43, 49, 145) 0%, rgb(92, 131, 206) 100%) !important;
}
.button-dark-border-light .span-dark {
  z-index: 2;
  opacity: 1;
  background: rgb(24, 38, 126) !important;
}
.button-dark-border-light:hover .span-dark {
  opacity: 0;
}

.button-light span {
  transition: 0.4s;
}
.button-light .span-light {
  z-index: 2;
  opacity: 1;
  margin: -2px;
  background: linear-gradient(25deg, rgb(43, 49, 145) 0%, rgb(92, 131, 206) 100%) !important;
}
.button-light .span-dark {
  z-index: 1;
  margin: -2px;
  background: rgb(24, 38, 126) !important;
}
.button-light:hover .span-light {
  opacity: 0;
}

.button-orange span {
  transition: 0.4s;
}
.button-orange .span-light {
  z-index: 2;
  opacity: 1;
  margin: -2px;
  background: linear-gradient(25deg, rgb(255, 107, 0) 0%, rgb(250, 151, 72) 100%) !important;
}
.button-orange .span-dark {
  z-index: 1;
  margin: -2px;
  background: rgb(255, 107, 0) !important;
}
.button-orange:hover .span-light {
  opacity: 0;
}

.button-white label {
  color: #5c83ce !important;
}
.button-white span {
  transition: 0.4s;
}
.button-white .span-light {
  z-index: 2;
  opacity: 1;
  margin: -2px;
  background: white !important;
}
.button-white .span-dark {
  z-index: 1;
  margin: -2px;
  background: rgb(24, 38, 126) !important;
}
.button-white:hover .span-light {
  opacity: 0;
}
.button-white:hover label {
  color: white !important;
}

/*------------------------------------Navigation------------------------------------*/
.navigation-base {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
  padding: 10px;
  transition: all 0.3s;
  display: flex;
}
@media screen and (max-width: 1450px) {
  .navigation-base {
    display: none;
  }
}
.navigation-base .logo-container {
  cursor: pointer;
}
.navigation-base--blue .navigation-horizontal-menu .item {
  color: #2b3191;
}
.navigation-base--sticky {
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgba(43, 49, 145, 0.3);
  height: 80px;
}
.navigation-base--sticky .navigation-horizontal-menu .item {
  color: #2b3191;
}

.navigation-horizontal-menu {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  align-items: center;
}
.navigation-horizontal-menu .item {
  color: white;
  margin-right: 60px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.navigation-horizontal-menu .item--active {
  text-decoration: underline !important;
}
.navigation-horizontal-menu .button-light {
  padding: 10px 20px 10px 20px !important;
  align-items: center;
  text-decoration: none;
}
.navigation-horizontal-menu .button-light svg {
  margin-right: 10px;
  height: 20px;
  align-items: center;
  z-index: 20;
}
.navigation-horizontal-menu .button-light label {
  font-size: 0.9rem;
  white-space: nowrap;
}

.navigation-view-mobile {
  position: fixed;
  top: 0;
  width: 100%;
  background: none;
  z-index: 100;
  align-items: initial;
  justify-content: center;
  flex-direction: column;
  display: none;
  transition: all 0.4s cubic-bezier(0.175, 0.555, 0.32, 1.175);
  padding-bottom: 20px;
}
@media screen and (max-width: 1450px) {
  .navigation-view-mobile {
    display: flex;
  }
}
.navigation-view-mobile .item {
  text-decoration: none;
}
.navigation-view-mobile .item--active {
  text-decoration: underline;
}
.navigation-view-mobile--sticky {
  background-color: white;
  padding-bottom: 0;
  box-shadow: 0px 0px 10px 1px rgba(43, 49, 145, 0.3);
}
.navigation-view-mobile--sticky ul {
  background: white;
}
.navigation-view-mobile--sticky .ham {
  height: 40px;
}
.navigation-view-mobile ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 0;
  transition: all 0.4s cubic-bezier(0.175, 0.555, 0.32, 1.175);
  background: none;
  width: 100%;
}
.navigation-view-mobile ul li {
  list-style: none;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2b3191;
  font-weight: bold;
}
.navigation-view-mobile ul li svg {
  max-height: 20px;
  max-width: 25px;
  margin-bottom: 5px;
}
.navigation-view-mobile--open ul {
  height: 250px;
}

.ham {
  transition: all 0.4s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 150px;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham4 .top {
  stroke-dasharray: 40 121;
}

.ham4 .bottom {
  stroke-dasharray: 40 121;
}

.ham4.active .top {
  stroke-dashoffset: -68px;
}

.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

.preLaunch-padding {
  padding-top: 130px !important;
}
@media screen and (max-width: 768px) {
  .preLaunch-padding {
    padding-top: 100px !important;
  }
}
@media screen and (max-width: 576px) {
  .preLaunch-padding {
    padding-top: 50px !important;
  }
}

.container-p-svg {
  text-decoration: none;
}
.container-p-svg p {
  color: #2b3191;
  font-weight: 500;
}

.p-footer {
  color: #2b3191;
  font-weight: 700;
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
  .column-logo {
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.column-logo p {
  text-align: center;
  font-size: 13px;
}
.column-logo p span {
  font-weight: bold;
}
.column-logo .logo-ddal {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .column-logo .logo-ddal {
    width: 300px;
  }
}
@media screen and (max-width: 576px) {
  .column-logo .logo-ddal {
    width: 100%;
  }
}

.nav-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
}
.nav-footer a {
  color: #2b3191;
  text-decoration: none;
}
.nav-footer div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.nav-footer--preLaunch {
  justify-content: start;
}
@media screen and (max-width: 1024px) {
  .nav-footer {
    align-items: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .column-information-contact {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.container-principal-footer {
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #FBFBFB;
}
@media screen and (max-width: 1024px) {
  .container-principal-footer {
    text-align: center;
  }
}

/*************************************Home**************************************/
.header {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(25deg, rgb(1, 4, 57) 0%, rgba(43, 49, 145, 0) 100%), url("./image/image-fond-accueil.webp");
  background-size: cover;
  position: relative;
}
.header[class*=header--article] h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  font-size: 3rem;
  transform: translate(-50%, -50%);
}
.header--article-DDAL {
  background-image: linear-gradient(25deg, rgb(1, 4, 57) 0%, rgba(43, 49, 145, 0) 100%), url("./image/image-page-ddal.jpg");
  height: 50vh;
}
.header--article-DDALEF {
  background-image: linear-gradient(25deg, rgb(1, 4, 57) 0%, rgba(43, 49, 145, 0) 100%), url("./image/image-page-ddal-ef.jpg");
  height: 50vh;
}
@media screen and (max-width: 576px) {
  .header .button-header label {
    font-size: 0.9rem !important;
  }
}
.header .margin-button {
  margin-right: 50px;
}
@media screen and (max-width: 576px) {
  .header .margin-button {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.header-content {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: white;
}
@media screen and (max-width: 1500px) {
  .header-content {
    left: 40%;
  }
}
@media screen and (max-width: 1024px) {
  .header-content {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
.header-content h1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}
@media screen and (max-width: 576px) {
  .header-content h1 {
    font-size: 2.5rem !important;
    text-align: center;
  }
}
@media screen and (max-width: 1203px) {
  .header-content h1 {
    font-size: 3.2rem;
  }
}
.header-content h1 span {
  font-size: 5rem;
}

/*************************************Home**************************************/
.newsletter-container {
  background: #A7c4fc;
  border-radius: 50px;
  padding: 20px 60px;
}
.newsletter-container--article {
  padding: 0;
}
.newsletter-container--article h2 {
  text-align: center;
}
.newsletter-container--article p {
  text-align: center;
}
.newsletter-container h2 {
  color: #2b3191 !important;
  font-size: 1.5rem !important;
  line-height: 1.5;
}
.newsletter-container p {
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .newsletter-container p {
    text-align: center;
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .newsletter-container .margin-bottom-responsive {
    margin-bottom: 0;
  }
}
.newsletter-container form .button-light {
  margin: 0 auto;
  display: block;
  padding: 15px 40px;
  margin-bottom: 30px;
}
.newsletter-container form .button-light .span-dark {
  z-index: 1;
}
.newsletter-container form .double-textbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.newsletter-container form .double-textbox div[class*=input-container] {
  width: 49%;
}
@media screen and (max-width: 576px) {
  .newsletter-container form .double-textbox div[class*=input-container] {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 576px) {
  .newsletter-container form .double-textbox {
    flex-direction: column;
  }
}

.question-container {
  position: fixed;
  top: 50%;
  left: 0;
  background: #A7c4fc;
  display: flex;
  border-radius: 0 20px 20px 0;
  width: 400px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  animation: bounceHorizontal 1s infinite;
  z-index: 1000;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .question-container {
    width: 98%;
  }
}
.question-container--open {
  animation: open 0.3s ease-in-out;
}
.question-container--close {
  animation: close 0.3s ease-in-out;
}
.question-container .inner-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}
.question-container .inner-div a {
  cursor: pointer;
  color: white;
}
.question-container .inner-div p {
  font-size: 1.2rem;
  color: #202020;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
}
.question-container .title-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
}
.question-container .title-div p {
  color: white;
  font-weight: bold;
  text-orientation: mixed;
  writing-mode: vertical-rl;
  margin: 0;
  font-size: 1.4rem;
}
@media screen and (max-width: 576px) {
  .question-container .title-div p {
    font-size: 1.2rem;
  }
}

@keyframes bounceHorizontal {
  0% {
    transform: translateX(-87%);
  }
  50% {
    transform: translateX(-88%);
  }
  100% {
    transform: translateX(-87%);
  }
}
@keyframes open {
  0% {
    transform: translateX(-87%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-87%);
  }
}
.demonstrator-container {
  padding-bottom: 100px;
}
@media screen and (max-width: 1203px) {
  .demonstrator-container .button-light {
    margin-top: 20px;
  }
}
.demonstrator-container .svg-question-mark {
  fill: #5C83CE;
  height: 20px;
  margin-top: 25px;
  margin-left: 10px;
}
@media screen and (max-width: 380px) {
  .demonstrator-container .assistance-block-static {
    font-size: 0.8rem;
  }
}
.demonstrator-container .assistance-block-static p {
  font-weight: 400;
}
.demonstrator-container .assistance-block {
  padding: 20px;
  background: #e1e8fa;
  border-radius: 10px;
  position: absolute;
  z-index: 100;
  top: -230px;
  left: 160px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.demonstrator-container .assistance-block--display {
  pointer-events: auto;
  opacity: 1;
}
.demonstrator-container .mail-form-container p {
  text-align: center;
}
.demonstrator-container .mail-form-container .warning {
  color: #E11D1D;
  margin: 0;
  font-weight: 500;
}
.demonstrator-container .mail-form-container .warning a {
  color: #5C83CE;
  text-decoration: underline;
}
.demonstrator-container .mail-form-container .p-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #5C83CE;
  margin-bottom: 10px;
}
.demonstrator-container .mail-form-container button {
  display: block;
  margin: 0 auto;
}
.demonstrator-container div[role=tree] {
  overflow: auto;
}
@media screen and (max-width: 576px) {
  .demonstrator-container div[role=tree] {
    width: 274.2857142857px;
  }
}
@media screen and (max-width: 575px) {
  .demonstrator-container div[role=tree] {
    width: 273.8095238095px;
  }
}
@media screen and (max-width: 574px) {
  .demonstrator-container div[role=tree] {
    width: 273.3333333333px;
  }
}
@media screen and (max-width: 573px) {
  .demonstrator-container div[role=tree] {
    width: 272.8571428571px;
  }
}
@media screen and (max-width: 572px) {
  .demonstrator-container div[role=tree] {
    width: 272.380952381px;
  }
}
@media screen and (max-width: 571px) {
  .demonstrator-container div[role=tree] {
    width: 271.9047619048px;
  }
}
@media screen and (max-width: 570px) {
  .demonstrator-container div[role=tree] {
    width: 271.4285714286px;
  }
}
@media screen and (max-width: 569px) {
  .demonstrator-container div[role=tree] {
    width: 270.9523809524px;
  }
}
@media screen and (max-width: 568px) {
  .demonstrator-container div[role=tree] {
    width: 270.4761904762px;
  }
}
@media screen and (max-width: 567px) {
  .demonstrator-container div[role=tree] {
    width: 270px;
  }
}
@media screen and (max-width: 566px) {
  .demonstrator-container div[role=tree] {
    width: 269.5238095238px;
  }
}
@media screen and (max-width: 565px) {
  .demonstrator-container div[role=tree] {
    width: 269.0476190476px;
  }
}
@media screen and (max-width: 564px) {
  .demonstrator-container div[role=tree] {
    width: 268.5714285714px;
  }
}
@media screen and (max-width: 563px) {
  .demonstrator-container div[role=tree] {
    width: 268.0952380952px;
  }
}
@media screen and (max-width: 562px) {
  .demonstrator-container div[role=tree] {
    width: 267.619047619px;
  }
}
@media screen and (max-width: 561px) {
  .demonstrator-container div[role=tree] {
    width: 267.1428571429px;
  }
}
@media screen and (max-width: 560px) {
  .demonstrator-container div[role=tree] {
    width: 266.6666666667px;
  }
}
@media screen and (max-width: 559px) {
  .demonstrator-container div[role=tree] {
    width: 266.1904761905px;
  }
}
@media screen and (max-width: 558px) {
  .demonstrator-container div[role=tree] {
    width: 265.7142857143px;
  }
}
@media screen and (max-width: 557px) {
  .demonstrator-container div[role=tree] {
    width: 265.2380952381px;
  }
}
@media screen and (max-width: 556px) {
  .demonstrator-container div[role=tree] {
    width: 264.7619047619px;
  }
}
@media screen and (max-width: 555px) {
  .demonstrator-container div[role=tree] {
    width: 264.2857142857px;
  }
}
@media screen and (max-width: 554px) {
  .demonstrator-container div[role=tree] {
    width: 263.8095238095px;
  }
}
@media screen and (max-width: 553px) {
  .demonstrator-container div[role=tree] {
    width: 263.3333333333px;
  }
}
@media screen and (max-width: 552px) {
  .demonstrator-container div[role=tree] {
    width: 262.8571428571px;
  }
}
@media screen and (max-width: 551px) {
  .demonstrator-container div[role=tree] {
    width: 262.380952381px;
  }
}
@media screen and (max-width: 550px) {
  .demonstrator-container div[role=tree] {
    width: 261.9047619048px;
  }
}
@media screen and (max-width: 549px) {
  .demonstrator-container div[role=tree] {
    width: 261.4285714286px;
  }
}
@media screen and (max-width: 548px) {
  .demonstrator-container div[role=tree] {
    width: 260.9523809524px;
  }
}
@media screen and (max-width: 547px) {
  .demonstrator-container div[role=tree] {
    width: 260.4761904762px;
  }
}
@media screen and (max-width: 546px) {
  .demonstrator-container div[role=tree] {
    width: 260px;
  }
}
@media screen and (max-width: 545px) {
  .demonstrator-container div[role=tree] {
    width: 259.5238095238px;
  }
}
@media screen and (max-width: 544px) {
  .demonstrator-container div[role=tree] {
    width: 259.0476190476px;
  }
}
@media screen and (max-width: 543px) {
  .demonstrator-container div[role=tree] {
    width: 258.5714285714px;
  }
}
@media screen and (max-width: 542px) {
  .demonstrator-container div[role=tree] {
    width: 258.0952380952px;
  }
}
@media screen and (max-width: 541px) {
  .demonstrator-container div[role=tree] {
    width: 257.619047619px;
  }
}
@media screen and (max-width: 540px) {
  .demonstrator-container div[role=tree] {
    width: 257.1428571429px;
  }
}
@media screen and (max-width: 539px) {
  .demonstrator-container div[role=tree] {
    width: 256.6666666667px;
  }
}
@media screen and (max-width: 538px) {
  .demonstrator-container div[role=tree] {
    width: 256.1904761905px;
  }
}
@media screen and (max-width: 537px) {
  .demonstrator-container div[role=tree] {
    width: 255.7142857143px;
  }
}
@media screen and (max-width: 536px) {
  .demonstrator-container div[role=tree] {
    width: 255.2380952381px;
  }
}
@media screen and (max-width: 535px) {
  .demonstrator-container div[role=tree] {
    width: 254.7619047619px;
  }
}
@media screen and (max-width: 534px) {
  .demonstrator-container div[role=tree] {
    width: 254.2857142857px;
  }
}
@media screen and (max-width: 533px) {
  .demonstrator-container div[role=tree] {
    width: 253.8095238095px;
  }
}
@media screen and (max-width: 532px) {
  .demonstrator-container div[role=tree] {
    width: 253.3333333333px;
  }
}
@media screen and (max-width: 531px) {
  .demonstrator-container div[role=tree] {
    width: 252.8571428571px;
  }
}
@media screen and (max-width: 530px) {
  .demonstrator-container div[role=tree] {
    width: 252.380952381px;
  }
}
@media screen and (max-width: 529px) {
  .demonstrator-container div[role=tree] {
    width: 251.9047619048px;
  }
}
@media screen and (max-width: 528px) {
  .demonstrator-container div[role=tree] {
    width: 251.4285714286px;
  }
}
@media screen and (max-width: 527px) {
  .demonstrator-container div[role=tree] {
    width: 250.9523809524px;
  }
}
@media screen and (max-width: 526px) {
  .demonstrator-container div[role=tree] {
    width: 250.4761904762px;
  }
}
@media screen and (max-width: 525px) {
  .demonstrator-container div[role=tree] {
    width: 250px;
  }
}
@media screen and (max-width: 524px) {
  .demonstrator-container div[role=tree] {
    width: 249.5238095238px;
  }
}
@media screen and (max-width: 523px) {
  .demonstrator-container div[role=tree] {
    width: 249.0476190476px;
  }
}
@media screen and (max-width: 522px) {
  .demonstrator-container div[role=tree] {
    width: 248.5714285714px;
  }
}
@media screen and (max-width: 521px) {
  .demonstrator-container div[role=tree] {
    width: 248.0952380952px;
  }
}
@media screen and (max-width: 520px) {
  .demonstrator-container div[role=tree] {
    width: 247.619047619px;
  }
}
@media screen and (max-width: 519px) {
  .demonstrator-container div[role=tree] {
    width: 247.1428571429px;
  }
}
@media screen and (max-width: 518px) {
  .demonstrator-container div[role=tree] {
    width: 246.6666666667px;
  }
}
@media screen and (max-width: 517px) {
  .demonstrator-container div[role=tree] {
    width: 246.1904761905px;
  }
}
@media screen and (max-width: 516px) {
  .demonstrator-container div[role=tree] {
    width: 245.7142857143px;
  }
}
@media screen and (max-width: 515px) {
  .demonstrator-container div[role=tree] {
    width: 245.2380952381px;
  }
}
@media screen and (max-width: 514px) {
  .demonstrator-container div[role=tree] {
    width: 244.7619047619px;
  }
}
@media screen and (max-width: 513px) {
  .demonstrator-container div[role=tree] {
    width: 244.2857142857px;
  }
}
@media screen and (max-width: 512px) {
  .demonstrator-container div[role=tree] {
    width: 243.8095238095px;
  }
}
@media screen and (max-width: 511px) {
  .demonstrator-container div[role=tree] {
    width: 243.3333333333px;
  }
}
@media screen and (max-width: 510px) {
  .demonstrator-container div[role=tree] {
    width: 242.8571428571px;
  }
}
@media screen and (max-width: 509px) {
  .demonstrator-container div[role=tree] {
    width: 242.380952381px;
  }
}
@media screen and (max-width: 508px) {
  .demonstrator-container div[role=tree] {
    width: 241.9047619048px;
  }
}
@media screen and (max-width: 507px) {
  .demonstrator-container div[role=tree] {
    width: 241.4285714286px;
  }
}
@media screen and (max-width: 506px) {
  .demonstrator-container div[role=tree] {
    width: 240.9523809524px;
  }
}
@media screen and (max-width: 505px) {
  .demonstrator-container div[role=tree] {
    width: 240.4761904762px;
  }
}
@media screen and (max-width: 504px) {
  .demonstrator-container div[role=tree] {
    width: 240px;
  }
}
@media screen and (max-width: 503px) {
  .demonstrator-container div[role=tree] {
    width: 239.5238095238px;
  }
}
@media screen and (max-width: 502px) {
  .demonstrator-container div[role=tree] {
    width: 239.0476190476px;
  }
}
@media screen and (max-width: 501px) {
  .demonstrator-container div[role=tree] {
    width: 238.5714285714px;
  }
}
@media screen and (max-width: 500px) {
  .demonstrator-container div[role=tree] {
    width: 238.0952380952px;
  }
}
@media screen and (max-width: 499px) {
  .demonstrator-container div[role=tree] {
    width: 237.619047619px;
  }
}
@media screen and (max-width: 498px) {
  .demonstrator-container div[role=tree] {
    width: 237.1428571429px;
  }
}
@media screen and (max-width: 497px) {
  .demonstrator-container div[role=tree] {
    width: 236.6666666667px;
  }
}
@media screen and (max-width: 496px) {
  .demonstrator-container div[role=tree] {
    width: 236.1904761905px;
  }
}
@media screen and (max-width: 495px) {
  .demonstrator-container div[role=tree] {
    width: 235.7142857143px;
  }
}
@media screen and (max-width: 494px) {
  .demonstrator-container div[role=tree] {
    width: 235.2380952381px;
  }
}
@media screen and (max-width: 493px) {
  .demonstrator-container div[role=tree] {
    width: 234.7619047619px;
  }
}
@media screen and (max-width: 492px) {
  .demonstrator-container div[role=tree] {
    width: 234.2857142857px;
  }
}
@media screen and (max-width: 491px) {
  .demonstrator-container div[role=tree] {
    width: 233.8095238095px;
  }
}
@media screen and (max-width: 490px) {
  .demonstrator-container div[role=tree] {
    width: 233.3333333333px;
  }
}
@media screen and (max-width: 489px) {
  .demonstrator-container div[role=tree] {
    width: 232.8571428571px;
  }
}
@media screen and (max-width: 488px) {
  .demonstrator-container div[role=tree] {
    width: 232.380952381px;
  }
}
@media screen and (max-width: 487px) {
  .demonstrator-container div[role=tree] {
    width: 231.9047619048px;
  }
}
@media screen and (max-width: 486px) {
  .demonstrator-container div[role=tree] {
    width: 231.4285714286px;
  }
}
@media screen and (max-width: 485px) {
  .demonstrator-container div[role=tree] {
    width: 230.9523809524px;
  }
}
@media screen and (max-width: 484px) {
  .demonstrator-container div[role=tree] {
    width: 230.4761904762px;
  }
}
@media screen and (max-width: 483px) {
  .demonstrator-container div[role=tree] {
    width: 230px;
  }
}
@media screen and (max-width: 482px) {
  .demonstrator-container div[role=tree] {
    width: 229.5238095238px;
  }
}
@media screen and (max-width: 481px) {
  .demonstrator-container div[role=tree] {
    width: 229.0476190476px;
  }
}
@media screen and (max-width: 480px) {
  .demonstrator-container div[role=tree] {
    width: 228.5714285714px;
  }
}
@media screen and (max-width: 479px) {
  .demonstrator-container div[role=tree] {
    width: 228.0952380952px;
  }
}
@media screen and (max-width: 478px) {
  .demonstrator-container div[role=tree] {
    width: 227.619047619px;
  }
}
@media screen and (max-width: 477px) {
  .demonstrator-container div[role=tree] {
    width: 227.1428571429px;
  }
}
@media screen and (max-width: 476px) {
  .demonstrator-container div[role=tree] {
    width: 226.6666666667px;
  }
}
@media screen and (max-width: 475px) {
  .demonstrator-container div[role=tree] {
    width: 226.1904761905px;
  }
}
@media screen and (max-width: 474px) {
  .demonstrator-container div[role=tree] {
    width: 225.7142857143px;
  }
}
@media screen and (max-width: 473px) {
  .demonstrator-container div[role=tree] {
    width: 225.2380952381px;
  }
}
@media screen and (max-width: 472px) {
  .demonstrator-container div[role=tree] {
    width: 224.7619047619px;
  }
}
@media screen and (max-width: 471px) {
  .demonstrator-container div[role=tree] {
    width: 224.2857142857px;
  }
}
@media screen and (max-width: 470px) {
  .demonstrator-container div[role=tree] {
    width: 223.8095238095px;
  }
}
@media screen and (max-width: 469px) {
  .demonstrator-container div[role=tree] {
    width: 223.3333333333px;
  }
}
@media screen and (max-width: 468px) {
  .demonstrator-container div[role=tree] {
    width: 222.8571428571px;
  }
}
@media screen and (max-width: 467px) {
  .demonstrator-container div[role=tree] {
    width: 222.380952381px;
  }
}
@media screen and (max-width: 466px) {
  .demonstrator-container div[role=tree] {
    width: 221.9047619048px;
  }
}
@media screen and (max-width: 465px) {
  .demonstrator-container div[role=tree] {
    width: 221.4285714286px;
  }
}
@media screen and (max-width: 464px) {
  .demonstrator-container div[role=tree] {
    width: 220.9523809524px;
  }
}
@media screen and (max-width: 463px) {
  .demonstrator-container div[role=tree] {
    width: 220.4761904762px;
  }
}
@media screen and (max-width: 462px) {
  .demonstrator-container div[role=tree] {
    width: 220px;
  }
}
@media screen and (max-width: 461px) {
  .demonstrator-container div[role=tree] {
    width: 219.5238095238px;
  }
}
@media screen and (max-width: 460px) {
  .demonstrator-container div[role=tree] {
    width: 219.0476190476px;
  }
}
@media screen and (max-width: 459px) {
  .demonstrator-container div[role=tree] {
    width: 218.5714285714px;
  }
}
@media screen and (max-width: 458px) {
  .demonstrator-container div[role=tree] {
    width: 218.0952380952px;
  }
}
@media screen and (max-width: 457px) {
  .demonstrator-container div[role=tree] {
    width: 217.619047619px;
  }
}
@media screen and (max-width: 456px) {
  .demonstrator-container div[role=tree] {
    width: 217.1428571429px;
  }
}
@media screen and (max-width: 455px) {
  .demonstrator-container div[role=tree] {
    width: 216.6666666667px;
  }
}
@media screen and (max-width: 454px) {
  .demonstrator-container div[role=tree] {
    width: 216.1904761905px;
  }
}
@media screen and (max-width: 453px) {
  .demonstrator-container div[role=tree] {
    width: 215.7142857143px;
  }
}
@media screen and (max-width: 452px) {
  .demonstrator-container div[role=tree] {
    width: 215.2380952381px;
  }
}
@media screen and (max-width: 451px) {
  .demonstrator-container div[role=tree] {
    width: 214.7619047619px;
  }
}
@media screen and (max-width: 450px) {
  .demonstrator-container div[role=tree] {
    width: 214.2857142857px;
  }
}
@media screen and (max-width: 449px) {
  .demonstrator-container div[role=tree] {
    width: 213.8095238095px;
  }
}
@media screen and (max-width: 448px) {
  .demonstrator-container div[role=tree] {
    width: 213.3333333333px;
  }
}
@media screen and (max-width: 447px) {
  .demonstrator-container div[role=tree] {
    width: 212.8571428571px;
  }
}
@media screen and (max-width: 446px) {
  .demonstrator-container div[role=tree] {
    width: 212.380952381px;
  }
}
@media screen and (max-width: 445px) {
  .demonstrator-container div[role=tree] {
    width: 211.9047619048px;
  }
}
@media screen and (max-width: 444px) {
  .demonstrator-container div[role=tree] {
    width: 211.4285714286px;
  }
}
@media screen and (max-width: 443px) {
  .demonstrator-container div[role=tree] {
    width: 210.9523809524px;
  }
}
@media screen and (max-width: 442px) {
  .demonstrator-container div[role=tree] {
    width: 210.4761904762px;
  }
}
@media screen and (max-width: 441px) {
  .demonstrator-container div[role=tree] {
    width: 210px;
  }
}
@media screen and (max-width: 440px) {
  .demonstrator-container div[role=tree] {
    width: 209.5238095238px;
  }
}
@media screen and (max-width: 439px) {
  .demonstrator-container div[role=tree] {
    width: 209.0476190476px;
  }
}
@media screen and (max-width: 438px) {
  .demonstrator-container div[role=tree] {
    width: 208.5714285714px;
  }
}
@media screen and (max-width: 437px) {
  .demonstrator-container div[role=tree] {
    width: 208.0952380952px;
  }
}
@media screen and (max-width: 436px) {
  .demonstrator-container div[role=tree] {
    width: 207.619047619px;
  }
}
@media screen and (max-width: 435px) {
  .demonstrator-container div[role=tree] {
    width: 207.1428571429px;
  }
}
@media screen and (max-width: 434px) {
  .demonstrator-container div[role=tree] {
    width: 206.6666666667px;
  }
}
@media screen and (max-width: 433px) {
  .demonstrator-container div[role=tree] {
    width: 206.1904761905px;
  }
}
@media screen and (max-width: 432px) {
  .demonstrator-container div[role=tree] {
    width: 205.7142857143px;
  }
}
@media screen and (max-width: 431px) {
  .demonstrator-container div[role=tree] {
    width: 205.2380952381px;
  }
}
@media screen and (max-width: 430px) {
  .demonstrator-container div[role=tree] {
    width: 204.7619047619px;
  }
}
@media screen and (max-width: 429px) {
  .demonstrator-container div[role=tree] {
    width: 204.2857142857px;
  }
}
@media screen and (max-width: 428px) {
  .demonstrator-container div[role=tree] {
    width: 203.8095238095px;
  }
}
@media screen and (max-width: 427px) {
  .demonstrator-container div[role=tree] {
    width: 203.3333333333px;
  }
}
@media screen and (max-width: 426px) {
  .demonstrator-container div[role=tree] {
    width: 202.8571428571px;
  }
}
@media screen and (max-width: 425px) {
  .demonstrator-container div[role=tree] {
    width: 202.380952381px;
  }
}
@media screen and (max-width: 424px) {
  .demonstrator-container div[role=tree] {
    width: 201.9047619048px;
  }
}
@media screen and (max-width: 423px) {
  .demonstrator-container div[role=tree] {
    width: 201.4285714286px;
  }
}
@media screen and (max-width: 422px) {
  .demonstrator-container div[role=tree] {
    width: 200.9523809524px;
  }
}
@media screen and (max-width: 421px) {
  .demonstrator-container div[role=tree] {
    width: 200.4761904762px;
  }
}
@media screen and (max-width: 420px) {
  .demonstrator-container div[role=tree] {
    width: 200px;
  }
}
@media screen and (max-width: 419px) {
  .demonstrator-container div[role=tree] {
    width: 199.5238095238px;
  }
}
@media screen and (max-width: 418px) {
  .demonstrator-container div[role=tree] {
    width: 199.0476190476px;
  }
}
@media screen and (max-width: 417px) {
  .demonstrator-container div[role=tree] {
    width: 198.5714285714px;
  }
}
@media screen and (max-width: 416px) {
  .demonstrator-container div[role=tree] {
    width: 198.0952380952px;
  }
}
@media screen and (max-width: 415px) {
  .demonstrator-container div[role=tree] {
    width: 197.619047619px;
  }
}
@media screen and (max-width: 414px) {
  .demonstrator-container div[role=tree] {
    width: 197.1428571429px;
  }
}
@media screen and (max-width: 413px) {
  .demonstrator-container div[role=tree] {
    width: 196.6666666667px;
  }
}
@media screen and (max-width: 412px) {
  .demonstrator-container div[role=tree] {
    width: 196.1904761905px;
  }
}
@media screen and (max-width: 411px) {
  .demonstrator-container div[role=tree] {
    width: 195.7142857143px;
  }
}
@media screen and (max-width: 410px) {
  .demonstrator-container div[role=tree] {
    width: 195.2380952381px;
  }
}
@media screen and (max-width: 409px) {
  .demonstrator-container div[role=tree] {
    width: 194.7619047619px;
  }
}
@media screen and (max-width: 408px) {
  .demonstrator-container div[role=tree] {
    width: 194.2857142857px;
  }
}
@media screen and (max-width: 407px) {
  .demonstrator-container div[role=tree] {
    width: 193.8095238095px;
  }
}
@media screen and (max-width: 406px) {
  .demonstrator-container div[role=tree] {
    width: 193.3333333333px;
  }
}
@media screen and (max-width: 405px) {
  .demonstrator-container div[role=tree] {
    width: 192.8571428571px;
  }
}
@media screen and (max-width: 404px) {
  .demonstrator-container div[role=tree] {
    width: 192.380952381px;
  }
}
@media screen and (max-width: 403px) {
  .demonstrator-container div[role=tree] {
    width: 191.9047619048px;
  }
}
@media screen and (max-width: 402px) {
  .demonstrator-container div[role=tree] {
    width: 191.4285714286px;
  }
}
@media screen and (max-width: 401px) {
  .demonstrator-container div[role=tree] {
    width: 190.9523809524px;
  }
}
@media screen and (max-width: 400px) {
  .demonstrator-container div[role=tree] {
    width: 190.4761904762px;
  }
}
@media screen and (max-width: 399px) {
  .demonstrator-container div[role=tree] {
    width: 190px;
  }
}
@media screen and (max-width: 398px) {
  .demonstrator-container div[role=tree] {
    width: 189.5238095238px;
  }
}
@media screen and (max-width: 397px) {
  .demonstrator-container div[role=tree] {
    width: 189.0476190476px;
  }
}
@media screen and (max-width: 396px) {
  .demonstrator-container div[role=tree] {
    width: 188.5714285714px;
  }
}
@media screen and (max-width: 395px) {
  .demonstrator-container div[role=tree] {
    width: 188.0952380952px;
  }
}
@media screen and (max-width: 394px) {
  .demonstrator-container div[role=tree] {
    width: 187.619047619px;
  }
}
@media screen and (max-width: 393px) {
  .demonstrator-container div[role=tree] {
    width: 187.1428571429px;
  }
}
@media screen and (max-width: 392px) {
  .demonstrator-container div[role=tree] {
    width: 186.6666666667px;
  }
}
@media screen and (max-width: 391px) {
  .demonstrator-container div[role=tree] {
    width: 186.1904761905px;
  }
}
@media screen and (max-width: 390px) {
  .demonstrator-container div[role=tree] {
    width: 185.7142857143px;
  }
}
@media screen and (max-width: 389px) {
  .demonstrator-container div[role=tree] {
    width: 185.2380952381px;
  }
}
@media screen and (max-width: 388px) {
  .demonstrator-container div[role=tree] {
    width: 184.7619047619px;
  }
}
@media screen and (max-width: 387px) {
  .demonstrator-container div[role=tree] {
    width: 184.2857142857px;
  }
}
@media screen and (max-width: 386px) {
  .demonstrator-container div[role=tree] {
    width: 183.8095238095px;
  }
}
@media screen and (max-width: 385px) {
  .demonstrator-container div[role=tree] {
    width: 183.3333333333px;
  }
}
@media screen and (max-width: 384px) {
  .demonstrator-container div[role=tree] {
    width: 182.8571428571px;
  }
}
@media screen and (max-width: 383px) {
  .demonstrator-container div[role=tree] {
    width: 182.380952381px;
  }
}
@media screen and (max-width: 382px) {
  .demonstrator-container div[role=tree] {
    width: 181.9047619048px;
  }
}
@media screen and (max-width: 381px) {
  .demonstrator-container div[role=tree] {
    width: 181.4285714286px;
  }
}
@media screen and (max-width: 380px) {
  .demonstrator-container div[role=tree] {
    width: 180.9523809524px;
  }
}
@media screen and (max-width: 379px) {
  .demonstrator-container div[role=tree] {
    width: 180.4761904762px;
  }
}
@media screen and (max-width: 378px) {
  .demonstrator-container div[role=tree] {
    width: 180px;
  }
}
@media screen and (max-width: 377px) {
  .demonstrator-container div[role=tree] {
    width: 179.5238095238px;
  }
}
@media screen and (max-width: 376px) {
  .demonstrator-container div[role=tree] {
    width: 179.0476190476px;
  }
}
@media screen and (max-width: 375px) {
  .demonstrator-container div[role=tree] {
    width: 178.5714285714px;
  }
}
@media screen and (max-width: 374px) {
  .demonstrator-container div[role=tree] {
    width: 178.0952380952px;
  }
}
@media screen and (max-width: 373px) {
  .demonstrator-container div[role=tree] {
    width: 177.619047619px;
  }
}
@media screen and (max-width: 372px) {
  .demonstrator-container div[role=tree] {
    width: 177.1428571429px;
  }
}
@media screen and (max-width: 371px) {
  .demonstrator-container div[role=tree] {
    width: 176.6666666667px;
  }
}
@media screen and (max-width: 370px) {
  .demonstrator-container div[role=tree] {
    width: 176.1904761905px;
  }
}
@media screen and (max-width: 369px) {
  .demonstrator-container div[role=tree] {
    width: 175.7142857143px;
  }
}
@media screen and (max-width: 368px) {
  .demonstrator-container div[role=tree] {
    width: 175.2380952381px;
  }
}
@media screen and (max-width: 367px) {
  .demonstrator-container div[role=tree] {
    width: 174.7619047619px;
  }
}
@media screen and (max-width: 366px) {
  .demonstrator-container div[role=tree] {
    width: 174.2857142857px;
  }
}
@media screen and (max-width: 365px) {
  .demonstrator-container div[role=tree] {
    width: 173.8095238095px;
  }
}
@media screen and (max-width: 364px) {
  .demonstrator-container div[role=tree] {
    width: 173.3333333333px;
  }
}
@media screen and (max-width: 363px) {
  .demonstrator-container div[role=tree] {
    width: 172.8571428571px;
  }
}
@media screen and (max-width: 362px) {
  .demonstrator-container div[role=tree] {
    width: 172.380952381px;
  }
}
@media screen and (max-width: 361px) {
  .demonstrator-container div[role=tree] {
    width: 171.9047619048px;
  }
}
@media screen and (max-width: 360px) {
  .demonstrator-container div[role=tree] {
    width: 171.4285714286px;
  }
}
@media screen and (max-width: 359px) {
  .demonstrator-container div[role=tree] {
    width: 170.9523809524px;
  }
}
@media screen and (max-width: 358px) {
  .demonstrator-container div[role=tree] {
    width: 170.4761904762px;
  }
}
@media screen and (max-width: 357px) {
  .demonstrator-container div[role=tree] {
    width: 170px;
  }
}
@media screen and (max-width: 356px) {
  .demonstrator-container div[role=tree] {
    width: 169.5238095238px;
  }
}
@media screen and (max-width: 355px) {
  .demonstrator-container div[role=tree] {
    width: 169.0476190476px;
  }
}
@media screen and (max-width: 354px) {
  .demonstrator-container div[role=tree] {
    width: 168.5714285714px;
  }
}
@media screen and (max-width: 353px) {
  .demonstrator-container div[role=tree] {
    width: 168.0952380952px;
  }
}
@media screen and (max-width: 352px) {
  .demonstrator-container div[role=tree] {
    width: 167.619047619px;
  }
}
@media screen and (max-width: 351px) {
  .demonstrator-container div[role=tree] {
    width: 167.1428571429px;
  }
}
@media screen and (max-width: 350px) {
  .demonstrator-container div[role=tree] {
    width: 166.6666666667px;
  }
}
@media screen and (max-width: 349px) {
  .demonstrator-container div[role=tree] {
    width: 166.1904761905px;
  }
}
@media screen and (max-width: 348px) {
  .demonstrator-container div[role=tree] {
    width: 165.7142857143px;
  }
}
@media screen and (max-width: 347px) {
  .demonstrator-container div[role=tree] {
    width: 165.2380952381px;
  }
}
@media screen and (max-width: 346px) {
  .demonstrator-container div[role=tree] {
    width: 164.7619047619px;
  }
}
@media screen and (max-width: 345px) {
  .demonstrator-container div[role=tree] {
    width: 164.2857142857px;
  }
}
@media screen and (max-width: 344px) {
  .demonstrator-container div[role=tree] {
    width: 163.8095238095px;
  }
}
@media screen and (max-width: 343px) {
  .demonstrator-container div[role=tree] {
    width: 163.3333333333px;
  }
}
@media screen and (max-width: 342px) {
  .demonstrator-container div[role=tree] {
    width: 162.8571428571px;
  }
}
@media screen and (max-width: 341px) {
  .demonstrator-container div[role=tree] {
    width: 162.380952381px;
  }
}
@media screen and (max-width: 340px) {
  .demonstrator-container div[role=tree] {
    width: 161.9047619048px;
  }
}
@media screen and (max-width: 339px) {
  .demonstrator-container div[role=tree] {
    width: 161.4285714286px;
  }
}
@media screen and (max-width: 338px) {
  .demonstrator-container div[role=tree] {
    width: 160.9523809524px;
  }
}
@media screen and (max-width: 337px) {
  .demonstrator-container div[role=tree] {
    width: 160.4761904762px;
  }
}
@media screen and (max-width: 336px) {
  .demonstrator-container div[role=tree] {
    width: 160px;
  }
}
@media screen and (max-width: 335px) {
  .demonstrator-container div[role=tree] {
    width: 159.5238095238px;
  }
}
@media screen and (max-width: 334px) {
  .demonstrator-container div[role=tree] {
    width: 159.0476190476px;
  }
}
@media screen and (max-width: 333px) {
  .demonstrator-container div[role=tree] {
    width: 158.5714285714px;
  }
}
@media screen and (max-width: 332px) {
  .demonstrator-container div[role=tree] {
    width: 158.0952380952px;
  }
}
@media screen and (max-width: 331px) {
  .demonstrator-container div[role=tree] {
    width: 157.619047619px;
  }
}
@media screen and (max-width: 330px) {
  .demonstrator-container div[role=tree] {
    width: 157.1428571429px;
  }
}
@media screen and (max-width: 329px) {
  .demonstrator-container div[role=tree] {
    width: 156.6666666667px;
  }
}
@media screen and (max-width: 328px) {
  .demonstrator-container div[role=tree] {
    width: 156.1904761905px;
  }
}
@media screen and (max-width: 327px) {
  .demonstrator-container div[role=tree] {
    width: 155.7142857143px;
  }
}
@media screen and (max-width: 326px) {
  .demonstrator-container div[role=tree] {
    width: 155.2380952381px;
  }
}
@media screen and (max-width: 325px) {
  .demonstrator-container div[role=tree] {
    width: 154.7619047619px;
  }
}
@media screen and (max-width: 324px) {
  .demonstrator-container div[role=tree] {
    width: 154.2857142857px;
  }
}
@media screen and (max-width: 323px) {
  .demonstrator-container div[role=tree] {
    width: 153.8095238095px;
  }
}
@media screen and (max-width: 322px) {
  .demonstrator-container div[role=tree] {
    width: 153.3333333333px;
  }
}
@media screen and (max-width: 321px) {
  .demonstrator-container div[role=tree] {
    width: 152.8571428571px;
  }
}
@media screen and (max-width: 320px) {
  .demonstrator-container div[role=tree] {
    width: 152.380952381px;
  }
}
@media screen and (max-width: 319px) {
  .demonstrator-container div[role=tree] {
    width: 151.9047619048px;
  }
}
@media screen and (max-width: 318px) {
  .demonstrator-container div[role=tree] {
    width: 151.4285714286px;
  }
}
@media screen and (max-width: 317px) {
  .demonstrator-container div[role=tree] {
    width: 150.9523809524px;
  }
}
@media screen and (max-width: 316px) {
  .demonstrator-container div[role=tree] {
    width: 150.4761904762px;
  }
}
@media screen and (max-width: 315px) {
  .demonstrator-container div[role=tree] {
    width: 150px;
  }
}
@media screen and (max-width: 314px) {
  .demonstrator-container div[role=tree] {
    width: 149.5238095238px;
  }
}
@media screen and (max-width: 313px) {
  .demonstrator-container div[role=tree] {
    width: 149.0476190476px;
  }
}
@media screen and (max-width: 312px) {
  .demonstrator-container div[role=tree] {
    width: 148.5714285714px;
  }
}
@media screen and (max-width: 311px) {
  .demonstrator-container div[role=tree] {
    width: 148.0952380952px;
  }
}
@media screen and (max-width: 310px) {
  .demonstrator-container div[role=tree] {
    width: 147.619047619px;
  }
}
@media screen and (max-width: 309px) {
  .demonstrator-container div[role=tree] {
    width: 147.1428571429px;
  }
}
@media screen and (max-width: 308px) {
  .demonstrator-container div[role=tree] {
    width: 146.6666666667px;
  }
}
@media screen and (max-width: 307px) {
  .demonstrator-container div[role=tree] {
    width: 146.1904761905px;
  }
}
@media screen and (max-width: 306px) {
  .demonstrator-container div[role=tree] {
    width: 145.7142857143px;
  }
}
@media screen and (max-width: 305px) {
  .demonstrator-container div[role=tree] {
    width: 145.2380952381px;
  }
}
@media screen and (max-width: 304px) {
  .demonstrator-container div[role=tree] {
    width: 144.7619047619px;
  }
}
@media screen and (max-width: 303px) {
  .demonstrator-container div[role=tree] {
    width: 144.2857142857px;
  }
}
@media screen and (max-width: 302px) {
  .demonstrator-container div[role=tree] {
    width: 143.8095238095px;
  }
}
@media screen and (max-width: 301px) {
  .demonstrator-container div[role=tree] {
    width: 143.3333333333px;
  }
}
@media screen and (max-width: 300px) {
  .demonstrator-container div[role=tree] {
    width: 142.8571428571px;
  }
}
.demonstrator-container .example-card {
  padding: 10px;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ceddfd;
  margin-bottom: 5px;
}
.demonstrator-container .example-card:hover {
  background-color: #a2bdfc;
  color: white;
}
.demonstrator-container .example-card--selected {
  background-color: #5C83CE;
  color: white;
}
.demonstrator-container .inner-block {
  height: calc(100% - 32px);
  padding: 30px;
  border-radius: 30px;
  background-color: #f2f7fc;
}
@media screen and (max-width: 576px) {
  .demonstrator-container .inner-block {
    padding: 20px;
  }
}
.demonstrator-container .inner-block--blue {
  background-color: #ceddfd;
}
.demonstrator-container .parent {
  font-weight: bold;
  color: #5C83CE;
  cursor: pointer;
}
.demonstrator-container h5 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #5C83CE;
  margin-bottom: 10px;
  margin-top: 25px;
}
.demonstrator-container .tree {
  height: fit-content !important;
  width: 100% !important;
  text-wrap: nowrap;
}

.benefitsV2-container {
  padding-bottom: 50px;
  text-align: center;
}
.benefitsV2-container img {
  width: 85px;
}
.benefitsV2-container h4 {
  color: #5C83CE;
  font-size: 1.5rem;
  margin: 0;
}
.benefitsV2-container p {
  font-size: 1rem;
  margin: 0;
}

.benefitsEntityFramework-container {
  background: rgb(242, 247, 252);
  padding-bottom: 50px;
}
.benefitsEntityFramework-container h4 {
  margin: 0;
}
.benefitsEntityFramework-container button {
  margin: 0 auto;
  display: block;
}
@media screen and (min-width: 1024px) {
  .benefitsEntityFramework-container .col-lg-4 {
    flex: 0 0 32.3333333333%;
    max-width: 32.3333333333%;
  }
}

.benefitsDDAL-container .common-block-tree {
  flex-direction: row;
}
.benefitsDDAL-container button {
  margin: 0 auto;
  display: block;
}
.benefitsDDAL-container h4 {
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .benefitsDDAL-container .col-lg-4 {
    flex: 0 0 32.3333333333%;
    max-width: 32.3333333333%;
  }
}

/*************************************CustomerArea**************************************/
.container-navigation-customer {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
  display: flex;
}
@media screen and (max-width: 576px) {
  .container-navigation-customer {
    border-radius: 30px;
  }
  .container-navigation-customer ul {
    padding-left: 20px;
  }
}
.container-navigation-customer .navigation {
  width: 320px;
  background: #E4EDFF;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}
@media screen and (max-width: 576px) {
  .container-navigation-customer .navigation {
    width: fit-content;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
  }
}
.container-navigation-customer .navigation li {
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
}
.container-navigation-customer .navigation li.selected a {
  font-weight: 700;
}
.container-navigation-customer .navigation li.selected:after {
  opacity: 1;
}
.container-navigation-customer .navigation li:after {
  background: #5C83CE;
  border-radius: 5px 0 0 5px;
  content: "";
  height: 30px;
  opacity: 0;
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(50%, -50%);
  width: 5px;
}
.container-navigation-customer .navigation li a {
  color: #5C83CE;
  font-weight: 500;
}
@media screen and (max-width: 576px) {
  .container-navigation-customer .navigation li a {
    display: none;
  }
}
.container-navigation-customer .navigation li svg {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.data-personal-container {
  padding-top: 20px;
}
.data-personal-container p {
  text-transform: uppercase;
  font-weight: 700;
}
.data-personal-container .button-light {
  margin: 0 auto;
  display: block;
  padding: 15px 25px;
}

.my-society-container {
  padding-top: 20px;
}
.my-society-container .button-light {
  margin: 0 auto;
  display: block;
  padding: 15px 25px;
}

.status--canceled {
  color: #E11D1D;
  font-weight: bold;
}

.status--active {
  color: #46b642;
  font-weight: bold;
}

.status--pay {
  color: #f28315;
  font-weight: bold;
}

.status--trial {
  color: #5C83CE;
  font-weight: bold;
}

.status--incomplete {
  color: #f28315;
  font-weight: bold;
}

.status--incomplete_expired {
  color: #e14b1d;
  font-weight: bold;
}

.my-subscription-container table {
  margin-top: 50px;
  border-collapse: collapse;
}
.my-subscription-container table tr:nth-child(even) {
  background-color: #F3F7FF;
}
.my-subscription-container table td {
  padding: 5px 20px;
}
.my-subscription-container table th {
  border-bottom: #5C83CE 2px solid;
  padding: 10px 20px;
  color: #5C83CE;
  text-align: left;
}
.my-subscription-container table .button--cancel {
  background: none;
  border-radius: 5px;
  border: 1px solid #E11D1D;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 5px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #E11D1D;
  width: 120px;
}
.my-subscription-container table .button--cancel--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.my-subscription-container table .button--cancel--disabled:hover {
  background: none !important;
  color: #E11D1D !important;
}
.my-subscription-container table .button--cancel:hover {
  background: #E11D1D;
  color: white;
}
.my-subscription-container table .button--renew {
  background: none;
  border-radius: 5px;
  border: 1px solid #46b642;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 5px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #46b642;
  width: 120px;
}
.my-subscription-container table .button--renew--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.my-subscription-container table .button--renew--disabled:hover {
  background: none !important;
  color: #46b642 !important;
}
.my-subscription-container table .button--renew:hover {
  background: #46b642;
  color: white;
}
.my-subscription-container table .button--pay {
  background: none;
  border-radius: 5px;
  border: 1px solid #f28315;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 5px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #f28315;
  width: 120px;
}
.my-subscription-container table .button--pay--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.my-subscription-container table .button--pay--disabled:hover {
  background: none !important;
  color: #f28315 !important;
}
.my-subscription-container table .button--pay:hover {
  background: #f28315;
  color: white;
}
.my-subscription-container .subscription-card {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  width: fit-content;
  box-shadow: 0px 0px 15px 1px rgba(43, 49, 145, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
}
.my-subscription-container .subscription-card--title {
  box-shadow: none;
  margin-bottom: 0;
}
.my-subscription-container .subscription-card--title:hover {
  transform: translate(0, 0) !important;
}
.my-subscription-container .subscription-card p {
  margin: 0;
}
.my-subscription-container .subscription-card .button--cancel {
  background: none;
  border-radius: 5px;
  border: 1px solid #E11D1D;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 5px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #E11D1D;
  width: 120px;
}
.my-subscription-container .subscription-card .button--cancel--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.my-subscription-container .subscription-card .button--cancel--disabled:hover {
  background: none !important;
  color: #E11D1D !important;
}
.my-subscription-container .subscription-card .button--cancel:hover {
  background: #E11D1D;
  color: white;
}
.my-subscription-container .subscription-card .button--renew {
  background: none;
  border-radius: 5px;
  border: 1px solid #46b642;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 5px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #46b642;
  width: 120px;
}
.my-subscription-container .subscription-card .button--renew--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.my-subscription-container .subscription-card .button--renew--disabled:hover {
  background: none !important;
  color: #46b642 !important;
}
.my-subscription-container .subscription-card .button--renew:hover {
  background: #46b642;
  color: white;
}
.my-subscription-container .subscription-card .button--pay {
  background: none;
  border-radius: 5px;
  border: 1px solid #f28315;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 5px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #f28315;
  width: 120px;
}
.my-subscription-container .subscription-card .button--pay--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.my-subscription-container .subscription-card .button--pay--disabled:hover {
  background: none !important;
  color: #f28315 !important;
}
.my-subscription-container .subscription-card .button--pay:hover {
  background: #f28315;
  color: white;
}
.my-subscription-container .subscription-card .status--canceled {
  color: #E11D1D;
  font-weight: bold;
}
.my-subscription-container .subscription-card .status--active {
  color: #46b642;
  font-weight: bold;
}
.my-subscription-container .subscription-card .status--pay {
  color: #f28315;
  font-weight: bold;
}
.my-subscription-container .subscription-card .status--trial {
  color: #5C83CE;
  font-weight: bold;
}
.my-subscription-container .subscription-card .status--incomplete {
  color: #f28315;
  font-weight: bold;
}
.my-subscription-container .subscription-card:hover {
  transform: translate(0, -5px);
}
@media screen and (max-width: 1024px) {
  .my-subscription-container .subscription-card {
    justify-content: center;
  }
}
.my-subscription-container .spinner-loading svg {
  height: 80px;
  margin: 0;
}
.my-subscription-container .free-container {
  box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
  padding: 20px 10px;
  border-radius: 10px;
  margin-right: 50px;
  margin-bottom: 15px;
}
.my-subscription-container .free-container button {
  margin: 0 auto;
  display: block;
  padding: 10px 30px;
}
.my-subscription-container .free-container button label {
  color: white !important;
  font-weight: 700;
  font-size: 0.9rem;
}
.my-subscription-container .free-container label {
  color: #2b3191;
  font-weight: bold;
}
.my-subscription-container .free-container label span {
  color: #5C83CE;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
}
.my-subscription-container .free-container p {
  color: #202020;
  font-weight: bold;
  text-transform: lowercase;
}
.my-subscription-container .free-container p span {
  font-size: 1.2rem;
  color: #202020;
  font-weight: bold;
}
.my-subscription-container .pro-container {
  padding: 20px 10px;
  border-radius: 10px;
  background: linear-gradient(25deg, rgb(43, 49, 145) 0%, rgb(92, 131, 206) 100%);
  margin-bottom: 15px;
}
.my-subscription-container .pro-container svg {
  fill: white;
}
.my-subscription-container .pro-container p {
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}
.my-subscription-container .pro-container p span {
  text-transform: lowercase;
}
.my-subscription-container .pro-container button {
  margin: 0 auto;
  display: block;
  padding: 10px 30px;
}
.my-subscription-container .pro-container button label {
  font-weight: 700;
  font-size: 0.9rem;
}
.my-subscription-container svg {
  height: 30px;
  margin-right: 20px;
}

.payment-method-container table {
  margin-top: 15px;
  border-collapse: collapse;
}
.payment-method-container table tr:nth-child(even) {
  background-color: #F3F7FF;
}
.payment-method-container table td {
  padding: 5px 20px;
}
.payment-method-container table th {
  border-bottom: #5C83CE 2px solid;
  padding: 10px 20px;
  color: #5C83CE;
  text-align: left;
}
.payment-method-container .payment-method-card {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  width: fit-content;
}
.payment-method-container .payment-method-card p {
  margin: 0;
}
@media screen and (max-width: 1100px) {
  .payment-method-container .payment-method-card {
    gap: 20px;
    justify-content: center;
    border: #5C83CE 1px solid;
    border-radius: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  .payment-method-container .responsive-col {
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .payment-method-container .responsive-col p {
    margin: 0;
  }
}
.payment-method-container .default-button {
  background: none;
  background-image: linear-gradient(90deg, #5C83CE 50%, transparent 0);
  background-size: calc(200% + 4px) 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #5C83CE;
  border-radius: 10px;
  color: #5C83CE;
  cursor: pointer;
  width: 150px;
  height: 30px;
  transition: all 0.2s ease-in-out;
  text-wrap: nowrap;
}
.payment-method-container .default-button:hover {
  background-position: 0;
  color: white;
}
.payment-method-container .default-button--disabled {
  border: none;
  color: #46b642;
  font-weight: bold;
  background: none;
}
.payment-method-container .default-button--disabled:hover {
  background: none;
  color: #46b642;
}
.payment-method-container .delete-button {
  background: none;
  background-image: linear-gradient(90deg, #E11D1D 50%, transparent 0);
  background-size: calc(200% + 4px) 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #E11D1D;
  border-radius: 10px;
  padding: 5px 8px;
  color: #E11D1D;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 150px;
  height: 30px;
}
.payment-method-container .delete-button:hover {
  background-position: 0;
  color: white;
}
.payment-method-container .default-txt {
  padding: 10px 20px;
  color: #46b642;
  font-weight: bold;
  text-align: left;
}
.payment-method-container .card-container {
  width: 330px;
  position: relative;
  margin-bottom: 15px;
}
@media screen and (max-width: 576px) {
  .payment-method-container .card-container {
    width: 260px;
  }
}
.payment-method-container .card-container .number-card {
  position: absolute;
  color: white;
  font-weight: 500;
  top: 125px;
  left: 30px;
  font-size: 1.2rem;
}
@media screen and (max-width: 576px) {
  .payment-method-container .card-container .number-card {
    font-size: 1rem;
    top: 110px;
  }
}
.payment-method-container .card-container .date-card {
  position: absolute;
  color: white;
  font-weight: 500;
  top: 150px;
  left: 30px;
  font-size: 1.1rem;
}
@media screen and (max-width: 576px) {
  .payment-method-container .card-container .date-card {
    font-size: 0.9rem;
    top: 130px;
  }
}
.payment-method-container .card-container img {
  width: 100%;
}

.button-add {
  margin-bottom: 15px;
  background: none;
  background-image: linear-gradient(90deg, #5C83CE 50%, transparent 0);
  background-size: calc(200% + 4px) 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  border: 2px solid #5C83CE;
  border-radius: 10px;
  padding: 5px 0px;
  font-weight: bold;
  color: #5C83CE;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.button-add div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
}
.button-add svg {
  height: 15px;
  width: 15px;
  margin-bottom: 0px !important;
}
.button-add svg path {
  transition: all 0.3s ease-in-out;
  fill: #5C83CE;
}
.button-add .first-svg {
  margin-right: 20px;
}
.button-add:hover {
  background-position: 0;
  color: white;
}
.button-add:hover svg path {
  fill: white;
}

.invoices-container a {
  text-decoration: none;
  color: #202020;
}
.invoices-container .invoice-card {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border-radius: 20px;
  border: 2px solid #5C83CE;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.invoices-container .invoice-card--title {
  border: none;
  cursor: initial !important;
}
.invoices-container .invoice-card--title:hover {
  background: none !important;
}
.invoices-container .invoice-card p {
  font-weight: bold;
}
@media screen and (max-width: 1024px) {
  .invoices-container .invoice-card p {
    text-align: center !important;
  }
}
.invoices-container .invoice-card .amount {
  font-weight: bold;
  color: #5C83CE;
}
.invoices-container .invoice-card:hover {
  background: #F3F7FF;
}

.ddal-free-download-container a {
  padding: 5px 20px;
}

/*//////////////////////////////////////VIEWS//////////////////////////////////////*/
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.spinner-payment {
  display: block;
  margin: 0 auto;
  height: fit-content;
  width: fit-content;
}

.ul-text {
  padding: 0;
}
.ul-text p {
  margin: 0;
}
.ul-text p a {
  color: #5c83ce;
  text-decoration: underline;
}
.ul-text li {
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 300;
}
.ul-text li:before {
  content: "";
  background: #5c83ce;
  font-weight: bold;
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}

@media screen and (min-width: 576px) {
  .newsletter-container {
    margin-bottom: -80px;
  }
}

.benefit-block {
  padding: 20px;
  background: #f2f7fc;
  border-radius: 20px;
}
.benefit-block h4 {
  color: #5C83CE;
  font-weight: 500;
}

.common-block-tree {
  box-shadow: 0px 0px 30px 1px rgb(220, 233, 249);
  padding: 75px;
  border-radius: 60px;
  background-color: #fff;
  flex-direction: column;
}
@media screen and (max-width: 1203px) {
  .common-block-tree {
    padding: 40px !important;
  }
}

@media screen and (max-width: 576px) {
  .margin-top-30-sm {
    margin-top: 30px;
  }
}

h5 {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.spinner-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success-container {
  display: block;
  margin: 0 auto;
  width: fit-content;
  padding: 50px 0;
}
.success-container button {
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
}
.success-container button span {
  z-index: 1;
}
.success-container img {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
}
@media screen and (max-width: 576px) {
  .success-container img {
    width: 80%;
  }
}
.success-container p {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
  color: #5C83CE;
  text-align: center;
}
@media screen and (max-width: 576px) {
  .success-container p {
    font-size: 2rem;
  }
}

.common-container, .whoAreYou-container, .blog-container, .customer-area-container, .validation-mail-container, .register-container, .contact-container, .price-container, .connexion-container {
  background-image: url("./image/image-fond-haut-gauche.jpg");
  background-repeat: no-repeat;
  padding-top: 220px;
  padding-bottom: 220px;
}
@media screen and (max-width: 576px) {
  .common-container, .whoAreYou-container, .blog-container, .customer-area-container, .validation-mail-container, .register-container, .contact-container, .price-container, .connexion-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.common-container h1, .whoAreYou-container h1, .blog-container h1, .customer-area-container h1, .validation-mail-container h1, .register-container h1, .contact-container h1, .price-container h1, .connexion-container h1 {
  font-size: 2.5rem !important;
  text-align: center;
}
.common-container h1 span, .whoAreYou-container h1 span, .blog-container h1 span, .customer-area-container h1 span, .validation-mail-container h1 span, .register-container h1 span, .contact-container h1 span, .price-container h1 span, .connexion-container h1 span {
  color: #5c83ce;
}

.label-error {
  color: #e95958;
  margin-bottom: 20px;
  text-align: center;
}

.center-items-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row-art-1 {
  background-image: url("./image/image-fond-haut.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  height: fit-content;
}
.row-art-1 .container-image-column {
  padding-left: 40px;
}
@media screen and (max-width: 1024px) {
  .row-art-1 .container-image-column {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
.row-art-1 .ddal-h2 {
  font-size: 20px;
  font-weight: bold;
}
.row-art-1 .ddal-span {
  font-size: 75px;
  margin-right: 15px;
}
.row-art-1 .position-image {
  margin-top: 230px;
  margin-left: auto;
}
@media screen and (max-width: 1203px) {
  .row-art-1 .position-image {
    margin-top: 50px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1203px) {
  .marge-left {
    padding: 0 60px;
  }
}
@media screen and (max-width: 1024px) {
  .marge-left {
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
  .marge-left {
    padding: 0 15px;
  }
}

.row-art-2 {
  height: fit-content;
}

.row-art-2-1 {
  padding-bottom: 200px;
}
@media screen and (max-width: 1203px) {
  .row-art-2-1 {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 576px) {
  .row-art-2-1 {
    padding-bottom: 120px;
  }
}
.row-art-2-1 .container {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1203px) {
  .row-art-2-1 .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}
@media screen and (max-width: 576px) {
  .row-art-2-1 .container {
    gap: 20px;
  }
}
.row-art-2-1 .block-text h2 {
  font-size: 2rem;
}
.row-art-2-1 .position-image-girl {
  position: absolute;
  top: 40%;
  right: 0px;
}
@media screen and (max-width: 1500px) {
  .row-art-2-1 .position-image-girl {
    right: -100px;
  }
}
@media screen and (max-width: 1203px) {
  .row-art-2-1 .position-image-girl {
    position: inherit;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 576px) {
  .connexion-container {
    padding-top: 170px;
    padding-bottom: 100px;
  }
}
.connexion-container h2 {
  font-size: 1.3rem !important;
  font-weight: 700;
  margin-top: 0;
}
@media screen and (max-width: 576px) {
  .connexion-container h2 {
    text-align: center;
    margin: 0;
  }
}
.connexion-container .column-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #202020;
}
.connexion-container .column-container .input-container {
  font-weight: normal !important;
}
@media screen and (max-width: 576px) {
  .connexion-container .column-container {
    padding: 0 20px;
  }
}
.connexion-container .column-container .container-checkbox {
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .connexion-container .column-container .container-checkbox {
    flex-direction: column;
    gap: 25px;
  }
}
.connexion-container .column-container .container-checkbox label {
  cursor: pointer;
}
.connexion-container .column-container form {
  width: 100%;
  max-width: 500px;
  margin-top: 50px;
}
.connexion-container .column-container form input[type=text], .connexion-container .column-container form input[type=password] {
  margin-bottom: 20px;
}
.connexion-container .column-container form input[type=checkbox] {
  margin-right: 10px;
}
.connexion-container .column-container form button {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.connexion-container .column-container form button .span-dark {
  z-index: 1 !important;
}
.connexion-container .column-container a {
  color: #5c83ce;
  text-decoration: none;
  cursor: pointer;
}
.connexion-container .column-container a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 576px) {
  .price-container {
    padding-top: 170px;
  }
}
.price-container .advantage-block img {
  display: block;
  margin: 0 auto;
  width: 80px;
}
.price-container .advantage-block h4 {
  text-align: center;
  font-size: 1.3rem;
  color: #5C83CE;
}
.price-container .advantage-block p {
  text-align: center;
  color: #202020;
}
.price-container .advantage-block p a {
  color: #5C83CE;
  text-decoration: none;
}
.price-container .column-free {
  padding-top: 85px;
  padding-right: 30px;
  margin-right: 50px;
}
@media screen and (max-width: 576px) {
  .price-container .column-free {
    margin: 0;
    padding: 30px 30px;
  }
}
@media screen and (max-width: 768px) {
  .price-container .column-free {
    margin: 0;
  }
}
@media screen and (max-width: 1024px) {
  .price-container .column-free {
    margin: 0;
  }
}
.price-container .column-pro {
  margin-left: 50px;
  padding-top: 40px;
  padding-left: 10px;
}
@media screen and (max-width: 576px) {
  .price-container .column-pro {
    margin: 0;
    padding: 30px 30px;
    order: -1;
  }
}
@media screen and (max-width: 768px) {
  .price-container .column-pro {
    margin: 0;
  }
}
@media screen and (max-width: 1024px) {
  .price-container .column-pro {
    margin: 0;
  }
}
.price-container button {
  padding: 15px 45px !important;
}
@media screen and (max-width: 576px) {
  .price-container button {
    padding: 10px 30px !important;
  }
}
.price-container h4 {
  margin-bottom: 0;
  margin-top: 30px;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
}
@media screen and (max-width: 576px) {
  .price-container h4 {
    margin-top: 20px;
  }
}
.price-container svg {
  height: 110px;
  margin: 0 auto;
  display: block;
}
@media screen and (max-width: 576px) {
  .price-container svg {
    height: 80px;
  }
}
.price-container .container-pro, .price-container .container-free {
  box-shadow: 1px 1px 30px 0px rgba(43, 49, 145, 0.49);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}
@media screen and (max-width: 576px) {
  .price-container .container-pro, .price-container .container-free {
    padding-bottom: 60px;
  }
}
.price-container .container-free {
  background: white;
  padding-bottom: 60px;
  padding-top: 60px;
}
.price-container .container-free h2 {
  color: #5c83ce;
  text-transform: uppercase;
  margin-bottom: 0;
}
.price-container .container-free .button-light {
  margin: 0 auto;
  display: block;
}
.price-container .container-free .button-light .span-dark {
  z-index: 1 !important;
}
.price-container .container-free h4 {
  color: #5c83ce !important;
  font-size: 1.7rem;
}
@media screen and (max-width: 576px) {
  .price-container .container-free h4 {
    font-size: 1.3rem;
  }
}
.price-container .container-free p {
  color: #202020;
  position: relative;
  text-align: left;
  margin-bottom: 40px;
  font-size: 1.3rem;
  margin-top: 10px;
}
@media screen and (max-width: 576px) {
  .price-container .container-free p {
    font-size: 1.1rem;
  }
}
.price-container .container-free p:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  background: #202020;
  height: 2px;
}
.price-container .container-pro {
  background: linear-gradient(-25deg, rgb(167, 196, 252) 0%, rgb(43, 49, 145) 100%);
  padding-bottom: 60px;
  padding-top: 60px;
  margin-left: auto;
}
.price-container .container-pro h2 {
  color: white;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.price-container .container-pro .button-white {
  margin: 0 auto;
  display: block;
}
.price-container .container-pro .button-white .span-dark {
  z-index: 1 !important;
}
.price-container .container-pro .button-white label {
  font-size: 1.8rem !important;
  font-weight: 600;
}
.price-container .container-pro h4 {
  color: white !important;
  font-size: 2rem;
}
@media screen and (max-width: 576px) {
  .price-container .container-pro h4 {
    font-size: 1.7rem;
  }
}
.price-container .container-pro p {
  color: white;
  position: relative;
  text-align: left;
  margin-bottom: 60px;
  font-size: 1.7rem;
  margin-top: 10px;
}
@media screen and (max-width: 576px) {
  .price-container .container-pro p {
    font-size: 1.3rem;
  }
}
.price-container .container-pro p:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  background: white;
  height: 2px;
}
.price-container .container-pro svg {
  fill: white;
}
.price-container .pricing-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}
.price-container .pricing-table a {
  text-decoration: none;
  color: #5C83CE;
  cursor: pointer;
}
.price-container .pricing-table th {
  padding: 30px;
  font-weight: 400;
  line-height: 30px;
}
@media screen and (max-width: 576px) {
  .price-container .pricing-table th {
    font-size: 0.85rem;
    padding: 20px;
  }
}
.price-container .pricing-table th span {
  align-items: center;
  justify-content: center;
  display: flex;
}
.price-container .pricing-table svg {
  height: 30px;
  fill: #2C358B;
}
.price-container .pricing-table tr:nth-child(even) {
  background-color: #f0f0f0;
}
.price-container .check-background {
  fill: #2C358B;
}
.price-container .check-inner-background {
  fill: #FFFFFF;
}
.price-container .header-th {
  font-weight: bold !important;
  font-size: 1.2rem;
}
@media screen and (max-width: 576px) {
  .price-container .header-th {
    font-size: 0.9rem;
  }
}
.price-container .header-th svg {
  height: 50px;
}
.price-container .header-th p {
  font-size: 1.1rem;
  font-weight: bold;
  color: #2C358B;
  text-transform: uppercase;
}

@media screen and (max-width: 576px) {
  .contact-container {
    padding-top: 170px;
  }
}
.contact-container p {
  font-weight: 700;
  margin: 0 0 80px 0;
  font-size: 1.2rem;
}
@media screen and (max-width: 576px) {
  .contact-container p {
    text-align: center;
  }
}
.contact-container .double-textbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.contact-container .double-textbox div[class*=input-container] {
  width: 49%;
}
@media screen and (max-width: 576px) {
  .contact-container .double-textbox {
    flex-direction: column;
  }
  .contact-container .double-textbox div[class*=input-container] {
    width: 100%;
  }
}
.contact-container .button-light {
  margin: 0 auto;
  display: block;
  padding: 15px 40px;
}

@media screen and (max-width: 576px) {
  .register-container {
    padding-top: 170px;
  }
}
.register-container .title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.register-container .title-container p {
  font-weight: 700;
  margin: 0 0 80px 0;
  font-size: 1.2rem;
}
.register-container p {
  font-weight: bold;
}
.register-container form p {
  margin-bottom: 20px;
  margin-top: 45px;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.register-container form .button-light {
  margin: 0 auto;
  display: block;
  padding: 15px 40px;
  margin-bottom: 30px;
}
.register-container a {
  color: #5c83ce;
  text-decoration: none;
  cursor: pointer;
}
.register-container a:hover {
  text-decoration: underline;
}
.register-container .fiscal-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 576px) {
  .register-container .fiscal-container {
    flex-direction: column;
  }
}
.register-container .fiscal-container div[class*=input-container] {
  width: -webkit-fill-available;
}
.register-container .width-100-sm {
  width: 45%;
}
@media screen and (max-width: 576px) {
  .register-container .width-100-sm {
    width: 100%;
  }
}
.register-container .double-textbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.register-container .double-textbox div[class*=input-container] {
  width: 49%;
}
@media screen and (max-width: 576px) {
  .register-container .double-textbox {
    flex-direction: column;
  }
  .register-container .double-textbox div[class*=input-container] {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .validation-mail-container {
    padding: 20px;
  }
}

@media screen and (max-width: 1203px) {
  .prelaunch-container .mb-50 {
    margin-bottom: 0px !important;
  }
  .prelaunch-container .row, .prelaunch-container .row-centered {
    padding: 0 50px;
  }
}
@media screen and (max-width: 576px) {
  .prelaunch-container .row, .prelaunch-container .row-centered {
    padding: 0 5px;
  }
}
@media screen and (max-width: 1203px) {
  .prelaunch-container .no-padding .row, .prelaunch-container .no-padding .row-centered {
    padding: 0 !important;
  }
}
@media screen and (max-width: 1203px) {
  .prelaunch-container .responsive-double-img {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1203px) {
  .prelaunch-container .order-responsive {
    order: 2;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1203px) {
  .prelaunch-container .row-art-2-1 {
    padding-bottom: 0 !important;
  }
}
.prelaunch-container .background-image {
  background-position: right top;
  background-image: url("./image/image-fond-haut.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
.prelaunch-container .background-end-image {
  background-image: url("./image/image-fond-bas.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
}
.prelaunch-container h2 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: #5c83ce;
}
@media screen and (max-width: 576px) {
  .prelaunch-container h2 {
    font-size: 2rem;
  }
}
.prelaunch-container .history-block-container .common-block {
  background-color: #f2f7fc;
  box-shadow: none;
}
.prelaunch-container .row-art-2-1 {
  padding-bottom: 100px;
}
.prelaunch-container .svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prelaunch-container .benefits-block {
  display: flex;
  align-items: center;
  justify-content: start;
}
.prelaunch-container .benefits-block h3 {
  font-size: 1.2rem;
  text-transform: none;
}
.prelaunch-container .benefits-block p {
  margin: 7px 0 0 0;
}
.prelaunch-container .benefits-block img {
  margin-right: 30px;
}
.prelaunch-container .formulas-block-container a {
  display: block;
  margin: 0 auto;
  width: 35%;
  text-align: center;
  text-decoration: none;
}
.prelaunch-container .common-block {
  box-shadow: 0px 0px 30px 1px rgb(220, 233, 249);
  padding: 75px;
  border-radius: 60px;
  background-color: #fff;
}
@media screen and (max-width: 1203px) {
  .prelaunch-container .common-block {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 576px) {
  .prelaunch-container .common-block {
    padding: 40px;
  }
}
.prelaunch-container .common-block img {
  margin: 0 auto;
  display: block;
}
.prelaunch-container .common-block button {
  display: block;
  margin: 0 auto;
}
.prelaunch-container p {
  font-weight: 300;
}
.prelaunch-container p span {
  font-weight: bold;
}
@media screen and (min-width: 1203px) {
  .prelaunch-container div[class*=col-xl-5] {
    flex: 0 0 47.6666666667%;
  }
}
.prelaunch-container h3 {
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;
  color: #202020;
}
@media screen and (max-width: 1500px) {
  .prelaunch-container h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 576px) {
  .prelaunch-container h3 {
    font-size: 1.2rem;
  }
}

.loading-container-customer-area {
  position: absolute;
  transform: translate(-100%, 0);
  transition: all 0.5s ease-in-out;
}
.loading-container-customer-area--show {
  transform: translate(0, 0);
}

@media screen and (max-width: 576px) {
  .customer-area-container {
    padding-top: 200px;
  }
}
@media screen and (max-width: 576px) {
  .customer-area-container .p-30-responsive {
    padding: 0 !important;
  }
}
@media screen and (max-width: 1203px) {
  .customer-area-container .margin-responsive {
    margin-top: 30px !important;
  }
}
.customer-area-container h1 {
  text-align: center;
}
.customer-area-container .inner-container {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  background: white;
  min-height: 800px;
}
@media screen and (max-width: 576px) {
  .customer-area-container .inner-container {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
  }
}

.payment-container {
  background: #f2f7fc;
  height: fit-content;
  min-height: 100%;
  transition: all 0.5s ease-in-out;
  transform: translate(-100%, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.payment-container svg {
  margin-bottom: 50px;
}
.payment-container .card-container, .payment-container .info-container, .payment-container .confirmation-container {
  box-shadow: 0px 0px 30px 1px rgb(220, 233, 249);
  padding: 75px;
  border-radius: 60px;
  background-color: #fff;
}
.payment-container .confirmation-container {
  text-align: center;
}
.payment-container .confirmation-container img {
  width: 50px;
}
.payment-container .confirmation-container a {
  text-decoration: none;
}
.payment-container .confirmation-container h2 {
  color: #46b642;
  margin-top: 0;
  margin-bottom: 40px;
}
.payment-container .confirmation-container .p-ammount-title {
  font-size: 1.1rem;
  font-weight: 700;
  color: #2b3191;
  margin: 0;
}
.payment-container .confirmation-container .p-ammount {
  font-size: 2rem;
  font-weight: 700;
  color: #2b3191;
  margin: 0;
  margin-bottom: 50px;
}
.payment-container .info-container .total-number {
  font-size: 40px;
  font-weight: 700;
  color: #2b3191;
  margin-right: 15px;
}
.payment-container .info-container .input-dropdown {
  height: 30px;
  width: 67px;
}
.payment-container .info-container .add-promo-container {
  font-weight: 600;
  width: 100%;
}
.payment-container .info-container .add-promo-container .input-container {
  display: block;
  width: 100%;
  padding-right: 15px;
}
.payment-container .info-container .add-promo-container button {
  padding: 0px 20px;
}
.payment-container .info-container .add-promo-container button label {
  font-size: 0.9rem;
}
.payment-container .info-container .reduction-number {
  font-weight: 700;
  color: #62dc31;
}
.payment-container .info-container .p-period {
  color: #8f8f8f;
  font-weight: 600;
  font-size: 0.9rem;
}
.payment-container .info-container .payment-info-line {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  align-items: center;
  margin: 0 0 20px 0;
}
.payment-container .info-container .payment-info-line .input-dropdown {
  padding: 0 13px;
  border-radius: 6px;
}
.payment-container .info-container .payment-info-line p {
  margin: 0;
}
.payment-container .card-container .p-terms {
  font-size: 0.7rem;
  font-weight: 400;
  color: #6d6e78;
  margin: 0;
  line-height: 17px;
}
.payment-container .card-container .p-terms a {
  color: #5c83ce;
  text-decoration: underline;
}
.payment-container .card-container button {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  padding: 15px 20px;
}
.payment-container .card-container button label {
  font-size: 0.9rem;
}
.payment-container .form-container .list-payment-method-modal {
  padding: 0px 10px !important;
  overflow: auto;
  max-height: 250px;
}
.payment-container .form-container .checkbox {
  width: 45px;
}
.payment-container .form-container .button-add {
  margin-top: 0 !important;
}
.payment-container--open {
  transform: translate(0, 0);
}

.rocket-loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: #f2f7fc;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.rocket-loading-container .rocket-full {
  z-index: 2;
  display: flex;
  align-items: center;
}
.rocket-loading-container .rocket-full .rocket {
  height: 150px;
  animation: rocket_animation 0.42s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
}
.rocket-loading-container .rocket-full .rocket-fire {
  position: relative;
  width: 90px;
  height: 45px;
  background: #ffe36c;
  border-radius: 100px 60px 60px 100px/50px 50px 50px 50px;
  box-shadow: -5px 0px 100px 0px #ffad00;
  animation: flame-twirl 0.9s ease infinite, rocket_animation 0.42s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
}
.rocket-loading-container .rocket-full .rocket-fire-red {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 40px;
  height: 20px;
  background: #f6a32c;
  border-radius: 40px 20px 20px 40px/16px 16px 16px 16px;
  box-shadow: 0px 0px 60px 0px #ffad00;
  animation: flame-twirl 0.9s ease infinite;
}
.rocket-loading-container p {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Noto sans, sans-serif;
  color: #5c83ce;
  z-index: 2;
}
.rocket-loading-container p .dot {
  opacity: 0;
  animation: dot 1s ease infinite;
}
.rocket-loading-container p .dot1 {
  animation-delay: 0;
}
.rocket-loading-container p .dot2 {
  animation-delay: 0.33s;
}
.rocket-loading-container p .dot3 {
  animation-delay: 0.66s;
}
.rocket-loading-container .planet1 {
  position: absolute;
  width: 500px;
  right: -30%;
  top: 10%;
  animation: planet 1.5s linear infinite;
}
.rocket-loading-container .planet2 {
  position: absolute;
  width: 100px;
  right: -30%;
  top: 60%;
  animation: planet 3s linear infinite;
}
.rocket-loading-container .planet3 {
  position: absolute;
  width: 200px;
  right: -30%;
  top: 75%;
  animation: planet 1.9s linear infinite;
}
.rocket-loading-container--close {
  transform: translate(100%, 0);
}
.rocket-loading-container--hide {
  display: none;
}

/********** ANIMATIONS **********/
@keyframes rocket_animation {
  10%, 90% {
    transform: translate3d(-1px, 1px, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 2px, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, -2px, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 2px, 0);
  }
}
@keyframes flame-twirl {
  0%, 22%, 49%, 62%, 81%, 100% {
    border-radius: 100px 60px 60px 100px/60px 50px 50px 40px;
  }
  14%, 32%, 56%, 70%, 89% {
    border-radius: 180px 60px 60px 180px/40px 50px 50px 60px;
  }
}
@keyframes planet {
  from {
    right: -30%;
  }
  to {
    right: 130%;
  }
}
@keyframes dot {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.forgot-password-container {
  background: #f2f7fc;
  height: fit-content;
  min-height: 100%;
}
.forgot-password-container .common-block {
  box-shadow: 0px 0px 30px 1px rgb(220, 233, 249);
  padding: 75px;
  border-radius: 60px;
  background-color: #fff;
}
.forgot-password-container .button-light {
  margin: 0 auto;
  display: block;
  padding: 15px 40px;
}

.success-password-container p {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  color: #5C83CE;
}
.success-password-container img {
  display: block;
  margin: 0 auto;
}

.api {
  line-height: 25px;
  padding: 10px 40px;
}
.api h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2b3191;
}
.api h2 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #5C83CE;
}
.api h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #5C83CE;
}
.api h4 {
  font-size: 1.1rem;
  font-weight: 700;
  color: #7491d2;
  margin: 20px 0;
  text-transform: none;
}
.api h4:before {
  content: "";
}
.api a.link-scroll {
  color: #5C83CE;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}
.api a.link-scroll:hover {
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .article-container div[class*=cm-theme] {
    width: 760px;
  }
}
@media screen and (max-width: 759px) {
  .article-container div[class*=cm-theme] {
    width: 759px;
  }
}
@media screen and (max-width: 758px) {
  .article-container div[class*=cm-theme] {
    width: 758px;
  }
}
@media screen and (max-width: 757px) {
  .article-container div[class*=cm-theme] {
    width: 757px;
  }
}
@media screen and (max-width: 756px) {
  .article-container div[class*=cm-theme] {
    width: 756px;
  }
}
@media screen and (max-width: 755px) {
  .article-container div[class*=cm-theme] {
    width: 755px;
  }
}
@media screen and (max-width: 754px) {
  .article-container div[class*=cm-theme] {
    width: 754px;
  }
}
@media screen and (max-width: 753px) {
  .article-container div[class*=cm-theme] {
    width: 753px;
  }
}
@media screen and (max-width: 752px) {
  .article-container div[class*=cm-theme] {
    width: 752px;
  }
}
@media screen and (max-width: 751px) {
  .article-container div[class*=cm-theme] {
    width: 751px;
  }
}
@media screen and (max-width: 750px) {
  .article-container div[class*=cm-theme] {
    width: 750px;
  }
}
@media screen and (max-width: 749px) {
  .article-container div[class*=cm-theme] {
    width: 749px;
  }
}
@media screen and (max-width: 748px) {
  .article-container div[class*=cm-theme] {
    width: 748px;
  }
}
@media screen and (max-width: 747px) {
  .article-container div[class*=cm-theme] {
    width: 747px;
  }
}
@media screen and (max-width: 746px) {
  .article-container div[class*=cm-theme] {
    width: 746px;
  }
}
@media screen and (max-width: 745px) {
  .article-container div[class*=cm-theme] {
    width: 745px;
  }
}
@media screen and (max-width: 744px) {
  .article-container div[class*=cm-theme] {
    width: 744px;
  }
}
@media screen and (max-width: 743px) {
  .article-container div[class*=cm-theme] {
    width: 743px;
  }
}
@media screen and (max-width: 742px) {
  .article-container div[class*=cm-theme] {
    width: 742px;
  }
}
@media screen and (max-width: 741px) {
  .article-container div[class*=cm-theme] {
    width: 741px;
  }
}
@media screen and (max-width: 740px) {
  .article-container div[class*=cm-theme] {
    width: 740px;
  }
}
@media screen and (max-width: 739px) {
  .article-container div[class*=cm-theme] {
    width: 739px;
  }
}
@media screen and (max-width: 738px) {
  .article-container div[class*=cm-theme] {
    width: 738px;
  }
}
@media screen and (max-width: 737px) {
  .article-container div[class*=cm-theme] {
    width: 737px;
  }
}
@media screen and (max-width: 736px) {
  .article-container div[class*=cm-theme] {
    width: 736px;
  }
}
@media screen and (max-width: 735px) {
  .article-container div[class*=cm-theme] {
    width: 735px;
  }
}
@media screen and (max-width: 734px) {
  .article-container div[class*=cm-theme] {
    width: 734px;
  }
}
@media screen and (max-width: 733px) {
  .article-container div[class*=cm-theme] {
    width: 733px;
  }
}
@media screen and (max-width: 732px) {
  .article-container div[class*=cm-theme] {
    width: 732px;
  }
}
@media screen and (max-width: 731px) {
  .article-container div[class*=cm-theme] {
    width: 731px;
  }
}
@media screen and (max-width: 730px) {
  .article-container div[class*=cm-theme] {
    width: 730px;
  }
}
@media screen and (max-width: 729px) {
  .article-container div[class*=cm-theme] {
    width: 729px;
  }
}
@media screen and (max-width: 728px) {
  .article-container div[class*=cm-theme] {
    width: 728px;
  }
}
@media screen and (max-width: 727px) {
  .article-container div[class*=cm-theme] {
    width: 727px;
  }
}
@media screen and (max-width: 726px) {
  .article-container div[class*=cm-theme] {
    width: 726px;
  }
}
@media screen and (max-width: 725px) {
  .article-container div[class*=cm-theme] {
    width: 725px;
  }
}
@media screen and (max-width: 724px) {
  .article-container div[class*=cm-theme] {
    width: 724px;
  }
}
@media screen and (max-width: 723px) {
  .article-container div[class*=cm-theme] {
    width: 723px;
  }
}
@media screen and (max-width: 722px) {
  .article-container div[class*=cm-theme] {
    width: 722px;
  }
}
@media screen and (max-width: 721px) {
  .article-container div[class*=cm-theme] {
    width: 721px;
  }
}
@media screen and (max-width: 720px) {
  .article-container div[class*=cm-theme] {
    width: 720px;
  }
}
@media screen and (max-width: 719px) {
  .article-container div[class*=cm-theme] {
    width: 719px;
  }
}
@media screen and (max-width: 718px) {
  .article-container div[class*=cm-theme] {
    width: 718px;
  }
}
@media screen and (max-width: 717px) {
  .article-container div[class*=cm-theme] {
    width: 717px;
  }
}
@media screen and (max-width: 716px) {
  .article-container div[class*=cm-theme] {
    width: 716px;
  }
}
@media screen and (max-width: 715px) {
  .article-container div[class*=cm-theme] {
    width: 715px;
  }
}
@media screen and (max-width: 714px) {
  .article-container div[class*=cm-theme] {
    width: 714px;
  }
}
@media screen and (max-width: 713px) {
  .article-container div[class*=cm-theme] {
    width: 713px;
  }
}
@media screen and (max-width: 712px) {
  .article-container div[class*=cm-theme] {
    width: 712px;
  }
}
@media screen and (max-width: 711px) {
  .article-container div[class*=cm-theme] {
    width: 711px;
  }
}
@media screen and (max-width: 710px) {
  .article-container div[class*=cm-theme] {
    width: 710px;
  }
}
@media screen and (max-width: 709px) {
  .article-container div[class*=cm-theme] {
    width: 709px;
  }
}
@media screen and (max-width: 708px) {
  .article-container div[class*=cm-theme] {
    width: 708px;
  }
}
@media screen and (max-width: 707px) {
  .article-container div[class*=cm-theme] {
    width: 707px;
  }
}
@media screen and (max-width: 706px) {
  .article-container div[class*=cm-theme] {
    width: 706px;
  }
}
@media screen and (max-width: 705px) {
  .article-container div[class*=cm-theme] {
    width: 705px;
  }
}
@media screen and (max-width: 704px) {
  .article-container div[class*=cm-theme] {
    width: 704px;
  }
}
@media screen and (max-width: 703px) {
  .article-container div[class*=cm-theme] {
    width: 703px;
  }
}
@media screen and (max-width: 702px) {
  .article-container div[class*=cm-theme] {
    width: 702px;
  }
}
@media screen and (max-width: 701px) {
  .article-container div[class*=cm-theme] {
    width: 701px;
  }
}
@media screen and (max-width: 700px) {
  .article-container div[class*=cm-theme] {
    width: 700px;
  }
}
@media screen and (max-width: 699px) {
  .article-container div[class*=cm-theme] {
    width: 699px;
  }
}
@media screen and (max-width: 698px) {
  .article-container div[class*=cm-theme] {
    width: 698px;
  }
}
@media screen and (max-width: 697px) {
  .article-container div[class*=cm-theme] {
    width: 697px;
  }
}
@media screen and (max-width: 696px) {
  .article-container div[class*=cm-theme] {
    width: 696px;
  }
}
@media screen and (max-width: 695px) {
  .article-container div[class*=cm-theme] {
    width: 695px;
  }
}
@media screen and (max-width: 694px) {
  .article-container div[class*=cm-theme] {
    width: 694px;
  }
}
@media screen and (max-width: 693px) {
  .article-container div[class*=cm-theme] {
    width: 693px;
  }
}
@media screen and (max-width: 692px) {
  .article-container div[class*=cm-theme] {
    width: 692px;
  }
}
@media screen and (max-width: 691px) {
  .article-container div[class*=cm-theme] {
    width: 691px;
  }
}
@media screen and (max-width: 690px) {
  .article-container div[class*=cm-theme] {
    width: 690px;
  }
}
@media screen and (max-width: 689px) {
  .article-container div[class*=cm-theme] {
    width: 689px;
  }
}
@media screen and (max-width: 688px) {
  .article-container div[class*=cm-theme] {
    width: 688px;
  }
}
@media screen and (max-width: 687px) {
  .article-container div[class*=cm-theme] {
    width: 687px;
  }
}
@media screen and (max-width: 686px) {
  .article-container div[class*=cm-theme] {
    width: 686px;
  }
}
@media screen and (max-width: 685px) {
  .article-container div[class*=cm-theme] {
    width: 685px;
  }
}
@media screen and (max-width: 684px) {
  .article-container div[class*=cm-theme] {
    width: 684px;
  }
}
@media screen and (max-width: 683px) {
  .article-container div[class*=cm-theme] {
    width: 683px;
  }
}
@media screen and (max-width: 682px) {
  .article-container div[class*=cm-theme] {
    width: 682px;
  }
}
@media screen and (max-width: 681px) {
  .article-container div[class*=cm-theme] {
    width: 681px;
  }
}
@media screen and (max-width: 680px) {
  .article-container div[class*=cm-theme] {
    width: 680px;
  }
}
@media screen and (max-width: 679px) {
  .article-container div[class*=cm-theme] {
    width: 679px;
  }
}
@media screen and (max-width: 678px) {
  .article-container div[class*=cm-theme] {
    width: 678px;
  }
}
@media screen and (max-width: 677px) {
  .article-container div[class*=cm-theme] {
    width: 677px;
  }
}
@media screen and (max-width: 676px) {
  .article-container div[class*=cm-theme] {
    width: 676px;
  }
}
@media screen and (max-width: 675px) {
  .article-container div[class*=cm-theme] {
    width: 675px;
  }
}
@media screen and (max-width: 674px) {
  .article-container div[class*=cm-theme] {
    width: 674px;
  }
}
@media screen and (max-width: 673px) {
  .article-container div[class*=cm-theme] {
    width: 673px;
  }
}
@media screen and (max-width: 672px) {
  .article-container div[class*=cm-theme] {
    width: 672px;
  }
}
@media screen and (max-width: 671px) {
  .article-container div[class*=cm-theme] {
    width: 671px;
  }
}
@media screen and (max-width: 670px) {
  .article-container div[class*=cm-theme] {
    width: 670px;
  }
}
@media screen and (max-width: 669px) {
  .article-container div[class*=cm-theme] {
    width: 669px;
  }
}
@media screen and (max-width: 668px) {
  .article-container div[class*=cm-theme] {
    width: 668px;
  }
}
@media screen and (max-width: 667px) {
  .article-container div[class*=cm-theme] {
    width: 667px;
  }
}
@media screen and (max-width: 666px) {
  .article-container div[class*=cm-theme] {
    width: 666px;
  }
}
@media screen and (max-width: 665px) {
  .article-container div[class*=cm-theme] {
    width: 665px;
  }
}
@media screen and (max-width: 664px) {
  .article-container div[class*=cm-theme] {
    width: 664px;
  }
}
@media screen and (max-width: 663px) {
  .article-container div[class*=cm-theme] {
    width: 663px;
  }
}
@media screen and (max-width: 662px) {
  .article-container div[class*=cm-theme] {
    width: 662px;
  }
}
@media screen and (max-width: 661px) {
  .article-container div[class*=cm-theme] {
    width: 661px;
  }
}
@media screen and (max-width: 660px) {
  .article-container div[class*=cm-theme] {
    width: 660px;
  }
}
@media screen and (max-width: 659px) {
  .article-container div[class*=cm-theme] {
    width: 659px;
  }
}
@media screen and (max-width: 658px) {
  .article-container div[class*=cm-theme] {
    width: 658px;
  }
}
@media screen and (max-width: 657px) {
  .article-container div[class*=cm-theme] {
    width: 657px;
  }
}
@media screen and (max-width: 656px) {
  .article-container div[class*=cm-theme] {
    width: 656px;
  }
}
@media screen and (max-width: 655px) {
  .article-container div[class*=cm-theme] {
    width: 655px;
  }
}
@media screen and (max-width: 654px) {
  .article-container div[class*=cm-theme] {
    width: 654px;
  }
}
@media screen and (max-width: 653px) {
  .article-container div[class*=cm-theme] {
    width: 653px;
  }
}
@media screen and (max-width: 652px) {
  .article-container div[class*=cm-theme] {
    width: 652px;
  }
}
@media screen and (max-width: 651px) {
  .article-container div[class*=cm-theme] {
    width: 651px;
  }
}
@media screen and (max-width: 650px) {
  .article-container div[class*=cm-theme] {
    width: 650px;
  }
}
@media screen and (max-width: 649px) {
  .article-container div[class*=cm-theme] {
    width: 649px;
  }
}
@media screen and (max-width: 648px) {
  .article-container div[class*=cm-theme] {
    width: 648px;
  }
}
@media screen and (max-width: 647px) {
  .article-container div[class*=cm-theme] {
    width: 647px;
  }
}
@media screen and (max-width: 646px) {
  .article-container div[class*=cm-theme] {
    width: 646px;
  }
}
@media screen and (max-width: 645px) {
  .article-container div[class*=cm-theme] {
    width: 645px;
  }
}
@media screen and (max-width: 644px) {
  .article-container div[class*=cm-theme] {
    width: 644px;
  }
}
@media screen and (max-width: 643px) {
  .article-container div[class*=cm-theme] {
    width: 643px;
  }
}
@media screen and (max-width: 642px) {
  .article-container div[class*=cm-theme] {
    width: 642px;
  }
}
@media screen and (max-width: 641px) {
  .article-container div[class*=cm-theme] {
    width: 641px;
  }
}
@media screen and (max-width: 640px) {
  .article-container div[class*=cm-theme] {
    width: 640px;
  }
}
@media screen and (max-width: 639px) {
  .article-container div[class*=cm-theme] {
    width: 639px;
  }
}
@media screen and (max-width: 638px) {
  .article-container div[class*=cm-theme] {
    width: 638px;
  }
}
@media screen and (max-width: 637px) {
  .article-container div[class*=cm-theme] {
    width: 637px;
  }
}
@media screen and (max-width: 636px) {
  .article-container div[class*=cm-theme] {
    width: 636px;
  }
}
@media screen and (max-width: 635px) {
  .article-container div[class*=cm-theme] {
    width: 635px;
  }
}
@media screen and (max-width: 634px) {
  .article-container div[class*=cm-theme] {
    width: 634px;
  }
}
@media screen and (max-width: 633px) {
  .article-container div[class*=cm-theme] {
    width: 633px;
  }
}
@media screen and (max-width: 632px) {
  .article-container div[class*=cm-theme] {
    width: 632px;
  }
}
@media screen and (max-width: 631px) {
  .article-container div[class*=cm-theme] {
    width: 631px;
  }
}
@media screen and (max-width: 630px) {
  .article-container div[class*=cm-theme] {
    width: 630px;
  }
}
@media screen and (max-width: 629px) {
  .article-container div[class*=cm-theme] {
    width: 629px;
  }
}
@media screen and (max-width: 628px) {
  .article-container div[class*=cm-theme] {
    width: 628px;
  }
}
@media screen and (max-width: 627px) {
  .article-container div[class*=cm-theme] {
    width: 627px;
  }
}
@media screen and (max-width: 626px) {
  .article-container div[class*=cm-theme] {
    width: 626px;
  }
}
@media screen and (max-width: 625px) {
  .article-container div[class*=cm-theme] {
    width: 625px;
  }
}
@media screen and (max-width: 624px) {
  .article-container div[class*=cm-theme] {
    width: 624px;
  }
}
@media screen and (max-width: 623px) {
  .article-container div[class*=cm-theme] {
    width: 623px;
  }
}
@media screen and (max-width: 622px) {
  .article-container div[class*=cm-theme] {
    width: 622px;
  }
}
@media screen and (max-width: 621px) {
  .article-container div[class*=cm-theme] {
    width: 621px;
  }
}
@media screen and (max-width: 620px) {
  .article-container div[class*=cm-theme] {
    width: 620px;
  }
}
@media screen and (max-width: 619px) {
  .article-container div[class*=cm-theme] {
    width: 619px;
  }
}
@media screen and (max-width: 618px) {
  .article-container div[class*=cm-theme] {
    width: 618px;
  }
}
@media screen and (max-width: 617px) {
  .article-container div[class*=cm-theme] {
    width: 617px;
  }
}
@media screen and (max-width: 616px) {
  .article-container div[class*=cm-theme] {
    width: 616px;
  }
}
@media screen and (max-width: 615px) {
  .article-container div[class*=cm-theme] {
    width: 615px;
  }
}
@media screen and (max-width: 614px) {
  .article-container div[class*=cm-theme] {
    width: 614px;
  }
}
@media screen and (max-width: 613px) {
  .article-container div[class*=cm-theme] {
    width: 613px;
  }
}
@media screen and (max-width: 612px) {
  .article-container div[class*=cm-theme] {
    width: 612px;
  }
}
@media screen and (max-width: 611px) {
  .article-container div[class*=cm-theme] {
    width: 611px;
  }
}
@media screen and (max-width: 610px) {
  .article-container div[class*=cm-theme] {
    width: 610px;
  }
}
@media screen and (max-width: 609px) {
  .article-container div[class*=cm-theme] {
    width: 609px;
  }
}
@media screen and (max-width: 608px) {
  .article-container div[class*=cm-theme] {
    width: 608px;
  }
}
@media screen and (max-width: 607px) {
  .article-container div[class*=cm-theme] {
    width: 607px;
  }
}
@media screen and (max-width: 606px) {
  .article-container div[class*=cm-theme] {
    width: 606px;
  }
}
@media screen and (max-width: 605px) {
  .article-container div[class*=cm-theme] {
    width: 605px;
  }
}
@media screen and (max-width: 604px) {
  .article-container div[class*=cm-theme] {
    width: 604px;
  }
}
@media screen and (max-width: 603px) {
  .article-container div[class*=cm-theme] {
    width: 603px;
  }
}
@media screen and (max-width: 602px) {
  .article-container div[class*=cm-theme] {
    width: 602px;
  }
}
@media screen and (max-width: 601px) {
  .article-container div[class*=cm-theme] {
    width: 601px;
  }
}
@media screen and (max-width: 600px) {
  .article-container div[class*=cm-theme] {
    width: 600px;
  }
}
@media screen and (max-width: 599px) {
  .article-container div[class*=cm-theme] {
    width: 599px;
  }
}
@media screen and (max-width: 598px) {
  .article-container div[class*=cm-theme] {
    width: 598px;
  }
}
@media screen and (max-width: 597px) {
  .article-container div[class*=cm-theme] {
    width: 597px;
  }
}
@media screen and (max-width: 596px) {
  .article-container div[class*=cm-theme] {
    width: 596px;
  }
}
@media screen and (max-width: 595px) {
  .article-container div[class*=cm-theme] {
    width: 595px;
  }
}
@media screen and (max-width: 594px) {
  .article-container div[class*=cm-theme] {
    width: 594px;
  }
}
@media screen and (max-width: 593px) {
  .article-container div[class*=cm-theme] {
    width: 593px;
  }
}
@media screen and (max-width: 592px) {
  .article-container div[class*=cm-theme] {
    width: 592px;
  }
}
@media screen and (max-width: 591px) {
  .article-container div[class*=cm-theme] {
    width: 591px;
  }
}
@media screen and (max-width: 590px) {
  .article-container div[class*=cm-theme] {
    width: 590px;
  }
}
@media screen and (max-width: 589px) {
  .article-container div[class*=cm-theme] {
    width: 589px;
  }
}
@media screen and (max-width: 588px) {
  .article-container div[class*=cm-theme] {
    width: 588px;
  }
}
@media screen and (max-width: 587px) {
  .article-container div[class*=cm-theme] {
    width: 587px;
  }
}
@media screen and (max-width: 586px) {
  .article-container div[class*=cm-theme] {
    width: 586px;
  }
}
@media screen and (max-width: 585px) {
  .article-container div[class*=cm-theme] {
    width: 585px;
  }
}
@media screen and (max-width: 584px) {
  .article-container div[class*=cm-theme] {
    width: 584px;
  }
}
@media screen and (max-width: 583px) {
  .article-container div[class*=cm-theme] {
    width: 583px;
  }
}
@media screen and (max-width: 582px) {
  .article-container div[class*=cm-theme] {
    width: 582px;
  }
}
@media screen and (max-width: 581px) {
  .article-container div[class*=cm-theme] {
    width: 581px;
  }
}
@media screen and (max-width: 580px) {
  .article-container div[class*=cm-theme] {
    width: 580px;
  }
}
@media screen and (max-width: 579px) {
  .article-container div[class*=cm-theme] {
    width: 579px;
  }
}
@media screen and (max-width: 578px) {
  .article-container div[class*=cm-theme] {
    width: 578px;
  }
}
@media screen and (max-width: 577px) {
  .article-container div[class*=cm-theme] {
    width: 577px;
  }
}
@media screen and (max-width: 576px) {
  .article-container div[class*=cm-theme] {
    width: 576px;
  }
}
@media screen and (max-width: 575px) {
  .article-container div[class*=cm-theme] {
    width: 575px;
  }
}
@media screen and (max-width: 574px) {
  .article-container div[class*=cm-theme] {
    width: 574px;
  }
}
@media screen and (max-width: 573px) {
  .article-container div[class*=cm-theme] {
    width: 573px;
  }
}
@media screen and (max-width: 572px) {
  .article-container div[class*=cm-theme] {
    width: 572px;
  }
}
@media screen and (max-width: 571px) {
  .article-container div[class*=cm-theme] {
    width: 571px;
  }
}
@media screen and (max-width: 570px) {
  .article-container div[class*=cm-theme] {
    width: 570px;
  }
}
@media screen and (max-width: 569px) {
  .article-container div[class*=cm-theme] {
    width: 569px;
  }
}
@media screen and (max-width: 568px) {
  .article-container div[class*=cm-theme] {
    width: 568px;
  }
}
@media screen and (max-width: 567px) {
  .article-container div[class*=cm-theme] {
    width: 567px;
  }
}
@media screen and (max-width: 566px) {
  .article-container div[class*=cm-theme] {
    width: 566px;
  }
}
@media screen and (max-width: 565px) {
  .article-container div[class*=cm-theme] {
    width: 565px;
  }
}
@media screen and (max-width: 564px) {
  .article-container div[class*=cm-theme] {
    width: 564px;
  }
}
@media screen and (max-width: 563px) {
  .article-container div[class*=cm-theme] {
    width: 563px;
  }
}
@media screen and (max-width: 562px) {
  .article-container div[class*=cm-theme] {
    width: 562px;
  }
}
@media screen and (max-width: 561px) {
  .article-container div[class*=cm-theme] {
    width: 561px;
  }
}
@media screen and (max-width: 560px) {
  .article-container div[class*=cm-theme] {
    width: 560px;
  }
}
@media screen and (max-width: 559px) {
  .article-container div[class*=cm-theme] {
    width: 559px;
  }
}
@media screen and (max-width: 558px) {
  .article-container div[class*=cm-theme] {
    width: 558px;
  }
}
@media screen and (max-width: 557px) {
  .article-container div[class*=cm-theme] {
    width: 557px;
  }
}
@media screen and (max-width: 556px) {
  .article-container div[class*=cm-theme] {
    width: 556px;
  }
}
@media screen and (max-width: 555px) {
  .article-container div[class*=cm-theme] {
    width: 555px;
  }
}
@media screen and (max-width: 554px) {
  .article-container div[class*=cm-theme] {
    width: 554px;
  }
}
@media screen and (max-width: 553px) {
  .article-container div[class*=cm-theme] {
    width: 553px;
  }
}
@media screen and (max-width: 552px) {
  .article-container div[class*=cm-theme] {
    width: 552px;
  }
}
@media screen and (max-width: 551px) {
  .article-container div[class*=cm-theme] {
    width: 551px;
  }
}
@media screen and (max-width: 550px) {
  .article-container div[class*=cm-theme] {
    width: 550px;
  }
}
@media screen and (max-width: 549px) {
  .article-container div[class*=cm-theme] {
    width: 549px;
  }
}
@media screen and (max-width: 548px) {
  .article-container div[class*=cm-theme] {
    width: 548px;
  }
}
@media screen and (max-width: 547px) {
  .article-container div[class*=cm-theme] {
    width: 547px;
  }
}
@media screen and (max-width: 546px) {
  .article-container div[class*=cm-theme] {
    width: 546px;
  }
}
@media screen and (max-width: 545px) {
  .article-container div[class*=cm-theme] {
    width: 545px;
  }
}
@media screen and (max-width: 544px) {
  .article-container div[class*=cm-theme] {
    width: 544px;
  }
}
@media screen and (max-width: 543px) {
  .article-container div[class*=cm-theme] {
    width: 543px;
  }
}
@media screen and (max-width: 542px) {
  .article-container div[class*=cm-theme] {
    width: 542px;
  }
}
@media screen and (max-width: 541px) {
  .article-container div[class*=cm-theme] {
    width: 541px;
  }
}
@media screen and (max-width: 540px) {
  .article-container div[class*=cm-theme] {
    width: 540px;
  }
}
@media screen and (max-width: 539px) {
  .article-container div[class*=cm-theme] {
    width: 539px;
  }
}
@media screen and (max-width: 538px) {
  .article-container div[class*=cm-theme] {
    width: 538px;
  }
}
@media screen and (max-width: 537px) {
  .article-container div[class*=cm-theme] {
    width: 537px;
  }
}
@media screen and (max-width: 536px) {
  .article-container div[class*=cm-theme] {
    width: 536px;
  }
}
@media screen and (max-width: 535px) {
  .article-container div[class*=cm-theme] {
    width: 535px;
  }
}
@media screen and (max-width: 534px) {
  .article-container div[class*=cm-theme] {
    width: 534px;
  }
}
@media screen and (max-width: 533px) {
  .article-container div[class*=cm-theme] {
    width: 533px;
  }
}
@media screen and (max-width: 532px) {
  .article-container div[class*=cm-theme] {
    width: 532px;
  }
}
@media screen and (max-width: 531px) {
  .article-container div[class*=cm-theme] {
    width: 531px;
  }
}
@media screen and (max-width: 530px) {
  .article-container div[class*=cm-theme] {
    width: 530px;
  }
}
@media screen and (max-width: 529px) {
  .article-container div[class*=cm-theme] {
    width: 529px;
  }
}
@media screen and (max-width: 528px) {
  .article-container div[class*=cm-theme] {
    width: 528px;
  }
}
@media screen and (max-width: 527px) {
  .article-container div[class*=cm-theme] {
    width: 527px;
  }
}
@media screen and (max-width: 526px) {
  .article-container div[class*=cm-theme] {
    width: 526px;
  }
}
@media screen and (max-width: 525px) {
  .article-container div[class*=cm-theme] {
    width: 525px;
  }
}
@media screen and (max-width: 524px) {
  .article-container div[class*=cm-theme] {
    width: 524px;
  }
}
@media screen and (max-width: 523px) {
  .article-container div[class*=cm-theme] {
    width: 523px;
  }
}
@media screen and (max-width: 522px) {
  .article-container div[class*=cm-theme] {
    width: 522px;
  }
}
@media screen and (max-width: 521px) {
  .article-container div[class*=cm-theme] {
    width: 521px;
  }
}
@media screen and (max-width: 520px) {
  .article-container div[class*=cm-theme] {
    width: 520px;
  }
}
@media screen and (max-width: 519px) {
  .article-container div[class*=cm-theme] {
    width: 519px;
  }
}
@media screen and (max-width: 518px) {
  .article-container div[class*=cm-theme] {
    width: 518px;
  }
}
@media screen and (max-width: 517px) {
  .article-container div[class*=cm-theme] {
    width: 517px;
  }
}
@media screen and (max-width: 516px) {
  .article-container div[class*=cm-theme] {
    width: 516px;
  }
}
@media screen and (max-width: 515px) {
  .article-container div[class*=cm-theme] {
    width: 515px;
  }
}
@media screen and (max-width: 514px) {
  .article-container div[class*=cm-theme] {
    width: 514px;
  }
}
@media screen and (max-width: 513px) {
  .article-container div[class*=cm-theme] {
    width: 513px;
  }
}
@media screen and (max-width: 512px) {
  .article-container div[class*=cm-theme] {
    width: 512px;
  }
}
@media screen and (max-width: 511px) {
  .article-container div[class*=cm-theme] {
    width: 511px;
  }
}
@media screen and (max-width: 510px) {
  .article-container div[class*=cm-theme] {
    width: 510px;
  }
}
@media screen and (max-width: 509px) {
  .article-container div[class*=cm-theme] {
    width: 509px;
  }
}
@media screen and (max-width: 508px) {
  .article-container div[class*=cm-theme] {
    width: 508px;
  }
}
@media screen and (max-width: 507px) {
  .article-container div[class*=cm-theme] {
    width: 507px;
  }
}
@media screen and (max-width: 506px) {
  .article-container div[class*=cm-theme] {
    width: 506px;
  }
}
@media screen and (max-width: 505px) {
  .article-container div[class*=cm-theme] {
    width: 505px;
  }
}
@media screen and (max-width: 504px) {
  .article-container div[class*=cm-theme] {
    width: 504px;
  }
}
@media screen and (max-width: 503px) {
  .article-container div[class*=cm-theme] {
    width: 503px;
  }
}
@media screen and (max-width: 502px) {
  .article-container div[class*=cm-theme] {
    width: 502px;
  }
}
@media screen and (max-width: 501px) {
  .article-container div[class*=cm-theme] {
    width: 501px;
  }
}
@media screen and (max-width: 500px) {
  .article-container div[class*=cm-theme] {
    width: 500px;
  }
}
@media screen and (max-width: 499px) {
  .article-container div[class*=cm-theme] {
    width: 499px;
  }
}
@media screen and (max-width: 498px) {
  .article-container div[class*=cm-theme] {
    width: 498px;
  }
}
@media screen and (max-width: 497px) {
  .article-container div[class*=cm-theme] {
    width: 497px;
  }
}
@media screen and (max-width: 496px) {
  .article-container div[class*=cm-theme] {
    width: 496px;
  }
}
@media screen and (max-width: 495px) {
  .article-container div[class*=cm-theme] {
    width: 495px;
  }
}
@media screen and (max-width: 494px) {
  .article-container div[class*=cm-theme] {
    width: 494px;
  }
}
@media screen and (max-width: 493px) {
  .article-container div[class*=cm-theme] {
    width: 493px;
  }
}
@media screen and (max-width: 492px) {
  .article-container div[class*=cm-theme] {
    width: 492px;
  }
}
@media screen and (max-width: 491px) {
  .article-container div[class*=cm-theme] {
    width: 491px;
  }
}
@media screen and (max-width: 490px) {
  .article-container div[class*=cm-theme] {
    width: 490px;
  }
}
@media screen and (max-width: 489px) {
  .article-container div[class*=cm-theme] {
    width: 489px;
  }
}
@media screen and (max-width: 488px) {
  .article-container div[class*=cm-theme] {
    width: 488px;
  }
}
@media screen and (max-width: 487px) {
  .article-container div[class*=cm-theme] {
    width: 487px;
  }
}
@media screen and (max-width: 486px) {
  .article-container div[class*=cm-theme] {
    width: 486px;
  }
}
@media screen and (max-width: 485px) {
  .article-container div[class*=cm-theme] {
    width: 485px;
  }
}
@media screen and (max-width: 484px) {
  .article-container div[class*=cm-theme] {
    width: 484px;
  }
}
@media screen and (max-width: 483px) {
  .article-container div[class*=cm-theme] {
    width: 483px;
  }
}
@media screen and (max-width: 482px) {
  .article-container div[class*=cm-theme] {
    width: 482px;
  }
}
@media screen and (max-width: 481px) {
  .article-container div[class*=cm-theme] {
    width: 481px;
  }
}
@media screen and (max-width: 480px) {
  .article-container div[class*=cm-theme] {
    width: 480px;
  }
}
@media screen and (max-width: 479px) {
  .article-container div[class*=cm-theme] {
    width: 479px;
  }
}
@media screen and (max-width: 478px) {
  .article-container div[class*=cm-theme] {
    width: 478px;
  }
}
@media screen and (max-width: 477px) {
  .article-container div[class*=cm-theme] {
    width: 477px;
  }
}
@media screen and (max-width: 476px) {
  .article-container div[class*=cm-theme] {
    width: 476px;
  }
}
@media screen and (max-width: 475px) {
  .article-container div[class*=cm-theme] {
    width: 475px;
  }
}
@media screen and (max-width: 474px) {
  .article-container div[class*=cm-theme] {
    width: 474px;
  }
}
@media screen and (max-width: 473px) {
  .article-container div[class*=cm-theme] {
    width: 473px;
  }
}
@media screen and (max-width: 472px) {
  .article-container div[class*=cm-theme] {
    width: 472px;
  }
}
@media screen and (max-width: 471px) {
  .article-container div[class*=cm-theme] {
    width: 471px;
  }
}
@media screen and (max-width: 470px) {
  .article-container div[class*=cm-theme] {
    width: 470px;
  }
}
@media screen and (max-width: 469px) {
  .article-container div[class*=cm-theme] {
    width: 469px;
  }
}
@media screen and (max-width: 468px) {
  .article-container div[class*=cm-theme] {
    width: 468px;
  }
}
@media screen and (max-width: 467px) {
  .article-container div[class*=cm-theme] {
    width: 467px;
  }
}
@media screen and (max-width: 466px) {
  .article-container div[class*=cm-theme] {
    width: 466px;
  }
}
@media screen and (max-width: 465px) {
  .article-container div[class*=cm-theme] {
    width: 465px;
  }
}
@media screen and (max-width: 464px) {
  .article-container div[class*=cm-theme] {
    width: 464px;
  }
}
@media screen and (max-width: 463px) {
  .article-container div[class*=cm-theme] {
    width: 463px;
  }
}
@media screen and (max-width: 462px) {
  .article-container div[class*=cm-theme] {
    width: 462px;
  }
}
@media screen and (max-width: 461px) {
  .article-container div[class*=cm-theme] {
    width: 461px;
  }
}
@media screen and (max-width: 460px) {
  .article-container div[class*=cm-theme] {
    width: 460px;
  }
}
@media screen and (max-width: 459px) {
  .article-container div[class*=cm-theme] {
    width: 459px;
  }
}
@media screen and (max-width: 458px) {
  .article-container div[class*=cm-theme] {
    width: 458px;
  }
}
@media screen and (max-width: 457px) {
  .article-container div[class*=cm-theme] {
    width: 457px;
  }
}
@media screen and (max-width: 456px) {
  .article-container div[class*=cm-theme] {
    width: 456px;
  }
}
@media screen and (max-width: 455px) {
  .article-container div[class*=cm-theme] {
    width: 455px;
  }
}
@media screen and (max-width: 454px) {
  .article-container div[class*=cm-theme] {
    width: 454px;
  }
}
@media screen and (max-width: 453px) {
  .article-container div[class*=cm-theme] {
    width: 453px;
  }
}
@media screen and (max-width: 452px) {
  .article-container div[class*=cm-theme] {
    width: 452px;
  }
}
@media screen and (max-width: 451px) {
  .article-container div[class*=cm-theme] {
    width: 451px;
  }
}
@media screen and (max-width: 450px) {
  .article-container div[class*=cm-theme] {
    width: 450px;
  }
}
@media screen and (max-width: 449px) {
  .article-container div[class*=cm-theme] {
    width: 449px;
  }
}
@media screen and (max-width: 448px) {
  .article-container div[class*=cm-theme] {
    width: 448px;
  }
}
@media screen and (max-width: 447px) {
  .article-container div[class*=cm-theme] {
    width: 447px;
  }
}
@media screen and (max-width: 446px) {
  .article-container div[class*=cm-theme] {
    width: 446px;
  }
}
@media screen and (max-width: 445px) {
  .article-container div[class*=cm-theme] {
    width: 445px;
  }
}
@media screen and (max-width: 444px) {
  .article-container div[class*=cm-theme] {
    width: 444px;
  }
}
@media screen and (max-width: 443px) {
  .article-container div[class*=cm-theme] {
    width: 443px;
  }
}
@media screen and (max-width: 442px) {
  .article-container div[class*=cm-theme] {
    width: 442px;
  }
}
@media screen and (max-width: 441px) {
  .article-container div[class*=cm-theme] {
    width: 441px;
  }
}
@media screen and (max-width: 440px) {
  .article-container div[class*=cm-theme] {
    width: 440px;
  }
}
@media screen and (max-width: 439px) {
  .article-container div[class*=cm-theme] {
    width: 439px;
  }
}
@media screen and (max-width: 438px) {
  .article-container div[class*=cm-theme] {
    width: 438px;
  }
}
@media screen and (max-width: 437px) {
  .article-container div[class*=cm-theme] {
    width: 437px;
  }
}
@media screen and (max-width: 436px) {
  .article-container div[class*=cm-theme] {
    width: 436px;
  }
}
@media screen and (max-width: 435px) {
  .article-container div[class*=cm-theme] {
    width: 435px;
  }
}
@media screen and (max-width: 434px) {
  .article-container div[class*=cm-theme] {
    width: 434px;
  }
}
@media screen and (max-width: 433px) {
  .article-container div[class*=cm-theme] {
    width: 433px;
  }
}
@media screen and (max-width: 432px) {
  .article-container div[class*=cm-theme] {
    width: 432px;
  }
}
@media screen and (max-width: 431px) {
  .article-container div[class*=cm-theme] {
    width: 431px;
  }
}
@media screen and (max-width: 430px) {
  .article-container div[class*=cm-theme] {
    width: 430px;
  }
}
@media screen and (max-width: 429px) {
  .article-container div[class*=cm-theme] {
    width: 429px;
  }
}
@media screen and (max-width: 428px) {
  .article-container div[class*=cm-theme] {
    width: 428px;
  }
}
@media screen and (max-width: 427px) {
  .article-container div[class*=cm-theme] {
    width: 427px;
  }
}
@media screen and (max-width: 426px) {
  .article-container div[class*=cm-theme] {
    width: 426px;
  }
}
@media screen and (max-width: 425px) {
  .article-container div[class*=cm-theme] {
    width: 425px;
  }
}
@media screen and (max-width: 424px) {
  .article-container div[class*=cm-theme] {
    width: 424px;
  }
}
@media screen and (max-width: 423px) {
  .article-container div[class*=cm-theme] {
    width: 423px;
  }
}
@media screen and (max-width: 422px) {
  .article-container div[class*=cm-theme] {
    width: 422px;
  }
}
@media screen and (max-width: 421px) {
  .article-container div[class*=cm-theme] {
    width: 421px;
  }
}
@media screen and (max-width: 420px) {
  .article-container div[class*=cm-theme] {
    width: 420px;
  }
}
@media screen and (max-width: 419px) {
  .article-container div[class*=cm-theme] {
    width: 419px;
  }
}
@media screen and (max-width: 418px) {
  .article-container div[class*=cm-theme] {
    width: 418px;
  }
}
@media screen and (max-width: 417px) {
  .article-container div[class*=cm-theme] {
    width: 417px;
  }
}
@media screen and (max-width: 416px) {
  .article-container div[class*=cm-theme] {
    width: 416px;
  }
}
@media screen and (max-width: 415px) {
  .article-container div[class*=cm-theme] {
    width: 415px;
  }
}
@media screen and (max-width: 414px) {
  .article-container div[class*=cm-theme] {
    width: 414px;
  }
}
@media screen and (max-width: 413px) {
  .article-container div[class*=cm-theme] {
    width: 413px;
  }
}
@media screen and (max-width: 412px) {
  .article-container div[class*=cm-theme] {
    width: 412px;
  }
}
@media screen and (max-width: 411px) {
  .article-container div[class*=cm-theme] {
    width: 411px;
  }
}
@media screen and (max-width: 410px) {
  .article-container div[class*=cm-theme] {
    width: 410px;
  }
}
@media screen and (max-width: 409px) {
  .article-container div[class*=cm-theme] {
    width: 409px;
  }
}
@media screen and (max-width: 408px) {
  .article-container div[class*=cm-theme] {
    width: 408px;
  }
}
@media screen and (max-width: 407px) {
  .article-container div[class*=cm-theme] {
    width: 407px;
  }
}
@media screen and (max-width: 406px) {
  .article-container div[class*=cm-theme] {
    width: 406px;
  }
}
@media screen and (max-width: 405px) {
  .article-container div[class*=cm-theme] {
    width: 405px;
  }
}
@media screen and (max-width: 404px) {
  .article-container div[class*=cm-theme] {
    width: 404px;
  }
}
@media screen and (max-width: 403px) {
  .article-container div[class*=cm-theme] {
    width: 403px;
  }
}
@media screen and (max-width: 402px) {
  .article-container div[class*=cm-theme] {
    width: 402px;
  }
}
@media screen and (max-width: 401px) {
  .article-container div[class*=cm-theme] {
    width: 401px;
  }
}
@media screen and (max-width: 400px) {
  .article-container div[class*=cm-theme] {
    width: 400px;
  }
}
@media screen and (max-width: 399px) {
  .article-container div[class*=cm-theme] {
    width: 399px;
  }
}
@media screen and (max-width: 398px) {
  .article-container div[class*=cm-theme] {
    width: 398px;
  }
}
@media screen and (max-width: 397px) {
  .article-container div[class*=cm-theme] {
    width: 397px;
  }
}
@media screen and (max-width: 396px) {
  .article-container div[class*=cm-theme] {
    width: 396px;
  }
}
@media screen and (max-width: 395px) {
  .article-container div[class*=cm-theme] {
    width: 395px;
  }
}
@media screen and (max-width: 394px) {
  .article-container div[class*=cm-theme] {
    width: 394px;
  }
}
@media screen and (max-width: 393px) {
  .article-container div[class*=cm-theme] {
    width: 393px;
  }
}
@media screen and (max-width: 392px) {
  .article-container div[class*=cm-theme] {
    width: 392px;
  }
}
@media screen and (max-width: 391px) {
  .article-container div[class*=cm-theme] {
    width: 391px;
  }
}
@media screen and (max-width: 390px) {
  .article-container div[class*=cm-theme] {
    width: 390px;
  }
}
@media screen and (max-width: 389px) {
  .article-container div[class*=cm-theme] {
    width: 389px;
  }
}
@media screen and (max-width: 388px) {
  .article-container div[class*=cm-theme] {
    width: 388px;
  }
}
@media screen and (max-width: 387px) {
  .article-container div[class*=cm-theme] {
    width: 387px;
  }
}
@media screen and (max-width: 386px) {
  .article-container div[class*=cm-theme] {
    width: 386px;
  }
}
@media screen and (max-width: 385px) {
  .article-container div[class*=cm-theme] {
    width: 385px;
  }
}
@media screen and (max-width: 384px) {
  .article-container div[class*=cm-theme] {
    width: 384px;
  }
}
@media screen and (max-width: 383px) {
  .article-container div[class*=cm-theme] {
    width: 383px;
  }
}
@media screen and (max-width: 382px) {
  .article-container div[class*=cm-theme] {
    width: 382px;
  }
}
@media screen and (max-width: 381px) {
  .article-container div[class*=cm-theme] {
    width: 381px;
  }
}
@media screen and (max-width: 380px) {
  .article-container div[class*=cm-theme] {
    width: 380px;
  }
}
@media screen and (max-width: 379px) {
  .article-container div[class*=cm-theme] {
    width: 379px;
  }
}
@media screen and (max-width: 378px) {
  .article-container div[class*=cm-theme] {
    width: 378px;
  }
}
@media screen and (max-width: 377px) {
  .article-container div[class*=cm-theme] {
    width: 377px;
  }
}
@media screen and (max-width: 376px) {
  .article-container div[class*=cm-theme] {
    width: 376px;
  }
}
@media screen and (max-width: 375px) {
  .article-container div[class*=cm-theme] {
    width: 375px;
  }
}
@media screen and (max-width: 374px) {
  .article-container div[class*=cm-theme] {
    width: 374px;
  }
}
@media screen and (max-width: 373px) {
  .article-container div[class*=cm-theme] {
    width: 373px;
  }
}
@media screen and (max-width: 372px) {
  .article-container div[class*=cm-theme] {
    width: 372px;
  }
}
@media screen and (max-width: 371px) {
  .article-container div[class*=cm-theme] {
    width: 371px;
  }
}
@media screen and (max-width: 370px) {
  .article-container div[class*=cm-theme] {
    width: 370px;
  }
}
@media screen and (max-width: 369px) {
  .article-container div[class*=cm-theme] {
    width: 369px;
  }
}
@media screen and (max-width: 368px) {
  .article-container div[class*=cm-theme] {
    width: 368px;
  }
}
@media screen and (max-width: 367px) {
  .article-container div[class*=cm-theme] {
    width: 367px;
  }
}
@media screen and (max-width: 366px) {
  .article-container div[class*=cm-theme] {
    width: 366px;
  }
}
@media screen and (max-width: 365px) {
  .article-container div[class*=cm-theme] {
    width: 365px;
  }
}
@media screen and (max-width: 364px) {
  .article-container div[class*=cm-theme] {
    width: 364px;
  }
}
@media screen and (max-width: 363px) {
  .article-container div[class*=cm-theme] {
    width: 363px;
  }
}
@media screen and (max-width: 362px) {
  .article-container div[class*=cm-theme] {
    width: 362px;
  }
}
@media screen and (max-width: 361px) {
  .article-container div[class*=cm-theme] {
    width: 361px;
  }
}
@media screen and (max-width: 360px) {
  .article-container div[class*=cm-theme] {
    width: 360px;
  }
}
@media screen and (max-width: 359px) {
  .article-container div[class*=cm-theme] {
    width: 359px;
  }
}
@media screen and (max-width: 358px) {
  .article-container div[class*=cm-theme] {
    width: 358px;
  }
}
@media screen and (max-width: 357px) {
  .article-container div[class*=cm-theme] {
    width: 357px;
  }
}
@media screen and (max-width: 356px) {
  .article-container div[class*=cm-theme] {
    width: 356px;
  }
}
@media screen and (max-width: 355px) {
  .article-container div[class*=cm-theme] {
    width: 355px;
  }
}
@media screen and (max-width: 354px) {
  .article-container div[class*=cm-theme] {
    width: 354px;
  }
}
@media screen and (max-width: 353px) {
  .article-container div[class*=cm-theme] {
    width: 353px;
  }
}
@media screen and (max-width: 352px) {
  .article-container div[class*=cm-theme] {
    width: 352px;
  }
}
@media screen and (max-width: 351px) {
  .article-container div[class*=cm-theme] {
    width: 351px;
  }
}
@media screen and (max-width: 350px) {
  .article-container div[class*=cm-theme] {
    width: 350px;
  }
}
@media screen and (max-width: 349px) {
  .article-container div[class*=cm-theme] {
    width: 349px;
  }
}
@media screen and (max-width: 348px) {
  .article-container div[class*=cm-theme] {
    width: 348px;
  }
}
@media screen and (max-width: 347px) {
  .article-container div[class*=cm-theme] {
    width: 347px;
  }
}
@media screen and (max-width: 346px) {
  .article-container div[class*=cm-theme] {
    width: 346px;
  }
}
@media screen and (max-width: 345px) {
  .article-container div[class*=cm-theme] {
    width: 345px;
  }
}
@media screen and (max-width: 344px) {
  .article-container div[class*=cm-theme] {
    width: 344px;
  }
}
@media screen and (max-width: 343px) {
  .article-container div[class*=cm-theme] {
    width: 343px;
  }
}
@media screen and (max-width: 342px) {
  .article-container div[class*=cm-theme] {
    width: 342px;
  }
}
@media screen and (max-width: 341px) {
  .article-container div[class*=cm-theme] {
    width: 341px;
  }
}
@media screen and (max-width: 340px) {
  .article-container div[class*=cm-theme] {
    width: 340px;
  }
}
@media screen and (max-width: 339px) {
  .article-container div[class*=cm-theme] {
    width: 339px;
  }
}
@media screen and (max-width: 338px) {
  .article-container div[class*=cm-theme] {
    width: 338px;
  }
}
@media screen and (max-width: 337px) {
  .article-container div[class*=cm-theme] {
    width: 337px;
  }
}
@media screen and (max-width: 336px) {
  .article-container div[class*=cm-theme] {
    width: 336px;
  }
}
@media screen and (max-width: 335px) {
  .article-container div[class*=cm-theme] {
    width: 335px;
  }
}
@media screen and (max-width: 334px) {
  .article-container div[class*=cm-theme] {
    width: 334px;
  }
}
@media screen and (max-width: 333px) {
  .article-container div[class*=cm-theme] {
    width: 333px;
  }
}
@media screen and (max-width: 332px) {
  .article-container div[class*=cm-theme] {
    width: 332px;
  }
}
@media screen and (max-width: 331px) {
  .article-container div[class*=cm-theme] {
    width: 331px;
  }
}
@media screen and (max-width: 330px) {
  .article-container div[class*=cm-theme] {
    width: 330px;
  }
}
@media screen and (max-width: 329px) {
  .article-container div[class*=cm-theme] {
    width: 329px;
  }
}
@media screen and (max-width: 328px) {
  .article-container div[class*=cm-theme] {
    width: 328px;
  }
}
@media screen and (max-width: 327px) {
  .article-container div[class*=cm-theme] {
    width: 327px;
  }
}
@media screen and (max-width: 326px) {
  .article-container div[class*=cm-theme] {
    width: 326px;
  }
}
@media screen and (max-width: 325px) {
  .article-container div[class*=cm-theme] {
    width: 325px;
  }
}
@media screen and (max-width: 324px) {
  .article-container div[class*=cm-theme] {
    width: 324px;
  }
}
@media screen and (max-width: 323px) {
  .article-container div[class*=cm-theme] {
    width: 323px;
  }
}
@media screen and (max-width: 322px) {
  .article-container div[class*=cm-theme] {
    width: 322px;
  }
}
@media screen and (max-width: 321px) {
  .article-container div[class*=cm-theme] {
    width: 321px;
  }
}
@media screen and (max-width: 320px) {
  .article-container div[class*=cm-theme] {
    width: 320px;
  }
}
@media screen and (max-width: 319px) {
  .article-container div[class*=cm-theme] {
    width: 319px;
  }
}
@media screen and (max-width: 318px) {
  .article-container div[class*=cm-theme] {
    width: 318px;
  }
}
@media screen and (max-width: 317px) {
  .article-container div[class*=cm-theme] {
    width: 317px;
  }
}
@media screen and (max-width: 316px) {
  .article-container div[class*=cm-theme] {
    width: 316px;
  }
}
@media screen and (max-width: 315px) {
  .article-container div[class*=cm-theme] {
    width: 315px;
  }
}
@media screen and (max-width: 314px) {
  .article-container div[class*=cm-theme] {
    width: 314px;
  }
}
@media screen and (max-width: 313px) {
  .article-container div[class*=cm-theme] {
    width: 313px;
  }
}
@media screen and (max-width: 312px) {
  .article-container div[class*=cm-theme] {
    width: 312px;
  }
}
@media screen and (max-width: 311px) {
  .article-container div[class*=cm-theme] {
    width: 311px;
  }
}
@media screen and (max-width: 310px) {
  .article-container div[class*=cm-theme] {
    width: 310px;
  }
}
@media screen and (max-width: 309px) {
  .article-container div[class*=cm-theme] {
    width: 309px;
  }
}
@media screen and (max-width: 308px) {
  .article-container div[class*=cm-theme] {
    width: 308px;
  }
}
@media screen and (max-width: 307px) {
  .article-container div[class*=cm-theme] {
    width: 307px;
  }
}
@media screen and (max-width: 306px) {
  .article-container div[class*=cm-theme] {
    width: 306px;
  }
}
@media screen and (max-width: 305px) {
  .article-container div[class*=cm-theme] {
    width: 305px;
  }
}
@media screen and (max-width: 304px) {
  .article-container div[class*=cm-theme] {
    width: 304px;
  }
}
@media screen and (max-width: 303px) {
  .article-container div[class*=cm-theme] {
    width: 303px;
  }
}
@media screen and (max-width: 302px) {
  .article-container div[class*=cm-theme] {
    width: 302px;
  }
}
@media screen and (max-width: 301px) {
  .article-container div[class*=cm-theme] {
    width: 301px;
  }
}
@media screen and (max-width: 300px) {
  .article-container div[class*=cm-theme] {
    width: 300px;
  }
}
.article-container .background-image {
  background-image: url("./image/image-fond-haut.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
.article-container ul li {
  list-style: none;
  margin-bottom: 10px;
}
.article-container ul li:before {
  display: inline-block;
  content: "";
  background: #5c83ce;
  font-weight: bold;
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: -15px;
  margin-bottom: 2px;
}
.article-container h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: #5C83CE;
}
@media screen and (max-width: 576px) {
  .article-container h1 {
    font-size: 2rem;
  }
}
.article-container h2 {
  margin-top: 40px;
  color: #5C83CE;
}

@media screen and (max-width: 576px) {
  .blog-container {
    padding-top: 170px;
  }
}
.blog-container .block {
  box-shadow: 0px 0px 30px 1px rgb(220, 233, 249);
  border-radius: 30px;
  background: white;
  overflow: hidden;
  cursor: pointer;
  height: -webkit-fill-available;
}
.blog-container .block a {
  text-decoration: none !important;
  color: #202020;
}
.blog-container .block p {
  font-weight: bold;
}
.blog-container .block .svg-container {
  border: 2px solid #5C83CE;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  display: flex;
  margin: 0 90%;
  transform: translate(-50%, 0);
  transition: all 0.2s ease-in-out;
}
.blog-container .block .svg-container:hover {
  background-color: #5C83CE;
}
.blog-container .block .svg-container:hover svg path {
  fill: white;
}
.blog-container .block .svg-container svg {
  height: 25px;
}
.blog-container .block .container {
  position: relative;
}
.blog-container .block .container h2 {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
}
.blog-container .block img {
  width: 100%;
}

@media screen and (max-height: 700px) {
  .whoAreYou-container {
    padding-top: 150px;
  }
}
@media screen and (max-width: 1204px) {
  .whoAreYou-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 576px) {
  .whoAreYou-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.whoAreYou-container h1 {
  color: #5C83CE;
  font-size: 3.5rem !important;
  text-align: center;
}
@media screen and (max-height: 700px) {
  .whoAreYou-container h1 {
    font-size: 2.5rem !important;
  }
}
.whoAreYou-container .header-size {
  height: calc(100vh - 220px);
}
@media screen and (max-width: 768px) {
  .whoAreYou-container .header-size {
    height: calc(100vh - 100px);
  }
}
@media screen and (max-height: 850px) {
  .whoAreYou-container .header-size {
    height: calc(100vh - 150px);
  }
}
.whoAreYou-container li span {
  color: #5C83CE;
  font-weight: bold;
}
@media screen and (max-width: 1204px) {
  .whoAreYou-container .header-img {
    display: none;
  }
}
@media screen and (max-height: 850px) {
  .whoAreYou-container .header-img {
    width: 70%;
  }
}
.whoAreYou-container .header-title h2 {
  color: #202020;
  text-align: center;
  font-size: 3rem;
  font-weight: normal;
}
@media screen and (max-height: 850px) {
  .whoAreYou-container .header-title h2 {
    font-size: 2rem !important;
  }
}
.whoAreYou-container .header-title h2 span {
  font-weight: bold;
}
.whoAreYou-container h2 {
  color: #5C83CE;
  font-size: 2.5rem;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .whoAreYou-container .col-lg-5 {
    flex: 0 0 48.6666666667%;
    max-width: 48.666667%;
  }
}
.whoAreYou-container .block {
  padding: 30px;
  border-radius: 30px;
  background: white;
  box-shadow: 0px 0px 30px 1px rgb(220, 233, 249);
}
.whoAreYou-container .character-block {
  padding: 30px;
  border-radius: 30px;
  background: white;
  box-shadow: 0px 0px 30px 1px rgb(220, 233, 249);
}
.whoAreYou-container .character-block img {
  width: 100%;
  margin-bottom: 20px;
}
.whoAreYou-container .character-block p {
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
  color: #5C83CE;
}
.whoAreYou-container .character-block h3 {
  font-size: 0.8rem;
  text-align: center;
  font-weight: normal;
  color: #202020;
  margin: 0;
}

/*//////////////////////////////THEMES//////////////////////////////*/
/*__________________LIGHT THEME__________________*/
html[data-theme=light] .dropdown-list, html[data-theme=light] .input-dropdown, html[data-theme=light] .input {
  background: white;
  color: #202020;
  border: #E9E9E9 solid 1px;
}
html[data-theme=light] .input:focus {
  outline: 0;
}
html[data-theme=light] .input-dropdown + svg {
  fill: #202020;
}
html[data-theme=light] .input-dropdown--search:focus {
  outline: 0;
}
html[data-theme=light] .dropdown-list li:hover {
  background: #f3f6f9;
}
html[data-theme=light] .dropdown-list-item--active {
  background: #f3f6f9;
}
html[data-theme=light] .navigation-view-mobile--sticky .line, html[data-theme=light] .navigation-view-mobile--blue .line {
  stroke: #2b3191 !important;
}
html[data-theme=light] .navigation-view-mobile .line {
  stroke: #FFFFFF;
}
html[data-theme=light] .checkbox::before {
  border-color: #bac4ce;
}
html[data-theme=light] .checkbox::after {
  box-shadow: 4px 4px 0 0 white;
}
html[data-theme=light] .checkbox:checked::before {
  border-color: #5C83CE;
  background-color: #5C83CE;
}
html[data-theme=light] .item-treeview span {
  color: #2b587b;
}
html[data-theme=light] .item-treeview--selected .inner-icon-container {
  background-color: #e7eef4;
}
html[data-theme=light] .item-treeview:before {
  border-top: 1px dashed #a9bfd1;
}
html[data-theme=light] ul[role=group]:before {
  border-left: 1px dashed #a9bfd1;
}

/*//////////////////////////////VENDORS//////////////////////////////*/
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  min-height: 100%;
}

#root {
  width: 100%;
}

p {
  line-height: 1.3;
}

