@mixin InitializeImage(){
  .container-img{
    box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.5);
    border-radius: 37px;
    overflow: hidden;
    height: fit-content;
    width: fit-content;
    display: flex;
    position: relative;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(25deg, rgba(1,4,57,1) 0%, rgba(43,49,145,0) 100%);
  }

  .img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include InitializeImage();