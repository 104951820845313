@mixin InitializeDetailsSubscription(){
  .details-subscription-container{
    background: white;
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
    border-radius: 30px;

    .title{
      color: #2b3191;
        font-weight: bold;
      font-size: 1.2rem;
    }

    .button-clipboard{
      background: none;
      border: none;
      cursor: pointer;
      align-items: center;
        display: flex;
      svg{
        margin: 0;
        height: 20px;
        fill: #5C83CE;
      }
    }

    .line{
      display: flex;
      p{
        font-weight: bold;
        margin-right: 10px;
      }
      a{
        color: #5C83CE;
        text-decoration: underline;
        cursor: pointer;
      }
      .mount{
        color: #5C83CE;
      }
    }
  }
}

@include InitializeDetailsSubscription();