@mixin checkboxInitialize() {

  //----------------PROPERTIES-CHECKBOX-INITIALIZE----------------//

  .checkbox {
    position: relative;
    appearance: none;
    box-sizing: content-box;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: fit-content;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
    width: 25px;
    &::before {
      content: '';
      display: block;
      box-sizing: content-box;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      transition: 0.2s border-color ease;
      border: 1px solid;
      background: white;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      box-sizing: content-box;
      top: 50%;
      left: 43%;
      transform-origin: 50% 50%;
      width: 9.6px;
      height: 16px;
      border-radius: 2px;
      transform: translate(-50%, -85%) scale(0) rotate(45deg);
      background-color: transparent;
    }

    &:checked::before {
      transition: 0.5s border-color ease;
    }

    &:checked::after {
      animation: toggleOnCheckbox 0.2s ease forwards;
    }

  }

  @keyframes toggleOnCheckbox {
    0% {
      opacity: 0;
      transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }

    70% {
      opacity: 1;
      transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }

    100% {
      transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
  }

}

@include checkboxInitialize();

@mixin checkboxColor($theme) {
  $color: (
          light: (
                  border:$light-grey,
                  border-checked:#5C83CE,
                  background:#5C83CE,
                  check:white,
          )
  );

  .checkbox {
    &::before {
      border-color: map-get(map-get($color, $theme), border);
    }

    &::after {
      box-shadow: 4px 4px 0 0 map-get(map-get($color, $theme), check);
    }

    &:checked::before {
      border-color: map-get(map-get($color, $theme), border-checked);
      background-color: map-get(map-get($color, $theme), background);
    }
  }

}