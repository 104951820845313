@mixin InitializeListPaymentMethod(){
  .list-payment-method-modal{
    background: white;
    border-radius: 20px;
    padding: 40px;

    .payment-method-card-list{
      &--selected{
        background: #5C83CE;
        color: #f2f7fc;
      }
      text-align: center;
      img{
        margin-left: 10px;
      }
      border: 2px solid #5C83CE;
      border-radius: 10px;
      margin: 10px 0;
      display: flex;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      width: fit-content;
      &:hover{
        background: #F3F7FF;
        color: #202020;
      }
    }

  }
}

@include InitializeListPaymentMethod();
