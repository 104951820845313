 @mixin InitializePaymentMethod(){
    .payment-method-container{
      table{
        margin-top: 15px;
        border-collapse: collapse;
        tr:nth-child(even){
          background-color: #F3F7FF;
        }
        td{
          padding: 5px 20px;
        }
        th{
          border-bottom: #5C83CE 2px solid;
          padding: 10px 20px;
          color:#5C83CE;
          text-align: left;
        }
      }

      .payment-method-card{
        padding: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        width: fit-content;
        p{
          margin: 0;
        }

        @media screen and (max-width: 1100px) {
          gap: 20px;
          justify-content: center;
          border: #5C83CE 1px solid;
          border-radius: 20px;
          margin-bottom: 20px;
        }


        @include breakpointDown('lg'){

        }
      }

      .responsive-col{
        @include breakpointDown('sm'){
          align-items: center;
          justify-content: center;
          padding: 10px;
          p{
            margin: 0;
          }
        }
      }

      .default-button{
        background: none;
        background-image: linear-gradient(90deg,#5C83CE 50%,transparent 0);
        background-size: calc(200% + 4px) 100%;
        background-position: 100%;
        background-repeat: no-repeat;
        border: 1px solid #5C83CE;
        border-radius: 10px;
        color: #5C83CE;
        cursor: pointer;
        width: 150px;
        height: 30px;
        transition: all 0.2s ease-in-out;
        text-wrap: nowrap;
        &:hover{
          background-position: 0;
          color: white;
        }
        &--disabled{
          border: none;
          color: #46b642;
          font-weight: bold;
          background: none;
            &:hover{
                background: none;
                color: #46b642;
            }
        }
      }
      .delete-button{
        background: none;
        background-image: linear-gradient(90deg,#E11D1D 50%,transparent 0);
        background-size: calc(200% + 4px) 100%;
        background-position: 100%;
        background-repeat: no-repeat;
        border: 1px solid #E11D1D;
        border-radius: 10px;
        padding: 5px 8px;
        color: #E11D1D;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        width: 150px;
        height: 30px;
        &:hover{
          background-position: 0;
          color: white;
        }
      }

      .default-txt{
        padding: 10px 20px;
        color:#46b642;
        font-weight: bold;
        text-align: left;
      }

      .card-container{
        width: 330px;
        position: relative;
        margin-bottom: 15px;
        @include breakpointDown('sm'){
          width: 260px;
        }
        .number-card{
          position: absolute;
          color:white;
          font-weight: 500;
          top: 125px;
          left: 30px;
            font-size: 1.2rem;
          @include breakpointDown('sm'){
            font-size: 1rem;
            top:110px
          }
        }
        .date-card{
          position: absolute;
          color:white;
          font-weight: 500;
          top: 150px;
          left: 30px;
            font-size: 1.1rem;
          @include breakpointDown('sm'){
            font-size: 0.9rem;
            top:130px
          }
        }
        img{
            width: 100%;
        }
      }
    }
   .button-add{
     margin-bottom: 15px;
     div{
       display: flex;
       justify-content: center;
       align-items: center;
       gap: 10px;
       padding: 0px 10px;
     }
     svg{
       height: 15px;
       width: 15px;
       margin-bottom: 0px!important;
       path{
         transition: all 0.3s ease-in-out;
         fill: #5C83CE;
       }
     }
     .first-svg{
       margin-right: 20px;
     }
     background: none;
     background-image: linear-gradient(90deg,#5C83CE 50%,transparent 0);
     background-size: calc(200% + 4px) 100%;
     background-position: 100%;
     background-repeat: no-repeat;
     border: 2px solid #5C83CE;
     border-radius: 10px;
     padding: 5px 0px;
     font-weight: bold;
     color: #5C83CE;
     cursor: pointer;
     transition: all 0.3s ease-in-out;
     &:hover{
       background-position: 0;
       color: white;
       svg{
         path{
           fill: white;
         }
       }
     }
   }
 }

 @include InitializePaymentMethod();