$grey-blue: #e7eef4;
$blue: #2b587b;
$light-blue: #f3f6f9;
$white50: rgba(255, 255, 255, 0.5);
$turquoise: #41b9c1;
$turquoise20: rgba(144, 248, 255, 0.2);
$dark-blue16: rgba(39, 95, 107, 0.16);
$dark-blue: #275f6b;
$light-grey: #bac4ce;
$light-blue-th: #7eA0b9;
$light-blue-disabled: #b8cadc;
$light-grey-svg-disabled: rgba(211, 222, 232, 0.7);
$light-blue-td: #f4f7fa;
$light-green: #eeffed;
$dark-green: #93c883;
$grey: #a9bfd1;
$orange: #ffc484;
$orange20: rgba(255, 196, 132, 0.2);
$ocean: #a2dafa;
$light-turquoise: #c4ecee;
$green: #83c06b;
$red: #e95958;