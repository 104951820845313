@mixin InitializeApi(){
  .api{
    line-height: 25px;
    pre{
    }
    padding: 10px 40px;
    h1{
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1rem;
      color: #2b3191;
    }
    h2{
        font-size: 1.4rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #5C83CE;
    }
    h3{
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #5C83CE;
    }
    h4{
        font-size: 1.1rem;
        font-weight: 700;
        color: #7491d2;
      margin: 20px 0;
      text-transform: none;
      &:before{
        content: '';
      }
    }
    a.link-scroll{
        color: #5C83CE;
        text-decoration: none;
      cursor: pointer;
      font-weight: bold;
        &:hover{
            text-decoration: underline;
        }
    }
  }
}

@include InitializeApi();