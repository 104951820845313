@mixin InitializeBlog() {
  .blog-container {
    @extend .common-container;
    @include breakpointDown(sm){
      padding-top: 170px;
    }
    .block{
      box-shadow: 0px 0px 30px 1px rgba(220, 233, 249, 1);
      border-radius: 30px;
      background: white;
      overflow: hidden;
      cursor: pointer;
      height: -webkit-fill-available;
      a{
        text-decoration: none!important;
        color: #202020;
      }
      p{
        font-weight: bold;
      }
      .svg-container{
        border : 2px solid #5C83CE;
        border-radius: 50%;
        width: fit-content;
        height: fit-content;
        padding: 5px;
        display: flex;
        margin: 0 90%;
        transform: translate(-50%,0);
        transition: all 0.2s ease-in-out;
        &:hover{
          background-color: #5C83CE;
          svg{
            path{
              fill: white;
            }
          }
        }
        svg{
          height: 25px;
        }
      }
      .container{
        position: relative;
        h2{
          position: absolute;
          color: white;
          top:50%;
          left: 50%;
          margin: 0;
          transform: translate(-50%, -50%);
        }
      }
      img{
        width: 100%;
      }
    }
  }
}

@include InitializeBlog();