* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body{
  min-height: 100%;
}

#root{
  width: 100%;
}

p{
  line-height: 1.3;
}