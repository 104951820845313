@mixin InitializeNewsletter{
  .newsletter-container{

    &--article{
      h2{
        text-align: center;
      }
      p{
        text-align: center;
      }
      padding: 0;
    }

    background: #A7c4fc;
    border-radius: 50px;
    padding: 20px 60px;
    h2{
      color:#2b3191!important;
      font-size: 1.5rem!important;
      line-height: 1.5;
    }
    p{
      margin-top: 0;
        margin-bottom: 40px;
      font-weight: 500;
      @include breakpointDown(md){
        text-align: center;
        padding: 20px;
      }
    }
    .margin-bottom-responsive{
      @include breakpointDown(md){
        margin-bottom: 0;
      }
    }
    form{
      .button-light{
        margin: 0 auto;
        display: block;
        padding: 15px 40px;
        margin-bottom: 30px;
        .span-dark{
          z-index: 1;
        }
      }
      .double-textbox{
        display: flex;
        justify-content: space-between;
        div[class*='input-container']{
          width: 49%;
          @include breakpointDown(sm){
            width: 100%;
            margin-bottom: 30px;
          }
        }
        margin-bottom: 30px;
        @include breakpointDown(sm){
          flex-direction: column;

        }
      }
    }
  }
}

@include InitializeNewsletter();