@mixin InitializeBenefitsEntityFramework{
  .benefitsEntityFramework-container{
    background:rgb(242, 247, 252);
    padding-bottom: 50px;
    h4{
      margin: 0;
    }
    button{
      margin: 0 auto;
      display: block;
    }

    @include breakpointUp(lg){
      .col-lg-4{
        flex: 0 0 32.3333333333%;
        max-width: 32.3333333333%;
      }
    }

  }
}

@include InitializeBenefitsEntityFramework();