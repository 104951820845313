@mixin InitializeQuestionElement(){
  .question-container{
    position: fixed;
    top: 50%;
    left: 0;
    background: #A7c4fc;
    display: flex;
    border-radius: 0 20px 20px 0;
    width: 400px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    animation: bounceHorizontal 1s infinite;
z-index: 1000;
    cursor: pointer;
    @include breakpointDown(sm){
      width: 98%;
    }
    &--open{
      animation: open 0.3s ease-in-out;
    }
    &--close{
      animation: close 0.3s ease-in-out;
    }
    .inner-div{
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 30px;
      a{
          cursor: pointer;
            color: white;
      }
      p{
        font-size: 1.2rem;
        color: #202020;
        font-weight: bold;
        margin: 0;
        margin-bottom: 20px;
      }
    }
    .title-div{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 10px;
      p{
        color: white;
        font-weight: bold;
        text-orientation: mixed;
        writing-mode: vertical-rl;
        margin: 0;
        font-size: 1.4rem;
        @include breakpointDown(sm){
          font-size: 1.2rem;
        }
      }
    }
  }
  @keyframes bounceHorizontal {
    0% {
      transform: translateX(-87%);
    }
    50% {
      transform: translateX(-88%);
    }
    100% {
      transform: translateX(-87%);
    }
  }
  @keyframes open {
    0% {
      transform: translateX(-87%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes close {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-87%);
    }
  }
}

@include InitializeQuestionElement();