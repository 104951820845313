@mixin initializeFooter{
  .preLaunch-padding{
    padding-top: 130px!important;
    @include breakpointDown(md){
      padding-top: 100px!important;
    }
    @include breakpointDown(sm){
      padding-top: 50px!important;
    }
  }
  .container-p-svg{
    text-decoration: none;
    p {
      color:#2b3191;
      font-weight: 500;
    }
  }
  .ghost-column{
    @include breakpointDown(lg){
    }
  }
  .p-footer{
    color:#2b3191;
    font-weight: 700;
    margin-top: 0;
  }
  .column-logo{
    @include breakpointDown(lg){
      padding-right: 20px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    p{
      text-align: center;
      font-size: 13px;
      span{
        font-weight: bold;
      }
    }
    .logo-ddal{
      @include breakpointDown(lg){
        width: 300px;
      }
      @include breakpointDown(sm){
        width: 100%;
      }
      width: 100%;
    }
  }

  .nav-footer{
    a{
      color:#2b3191;
      text-decoration: none;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      gap : 10px;
    }
    &--preLaunch{
      justify-content: start;
    }
    font-weight: 500;
    @include breakpointDown(lg){
      align-items: center;
      margin-bottom: 30px;
    }
  }
  .column-information-contact{
    @include breakpointDown(lg){
      align-items: center;
      display: flex;
      flex-direction: column;
    }

  }
  .container-principal-footer{
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #FBFBFB;
    @include breakpointDown(lg){
      text-align: center;
    }
  }
}

@include initializeFooter();