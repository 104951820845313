@mixin InitializeWhoAreYou() {
  .whoAreYou-container {
    @media screen and (max-height: 700px) {
      padding-top:150px;
    }

    @media screen and (max-width: 1204px){
      padding-left: 30px;
      padding-right: 30px;
    }
    @include breakpointDown(sm){
      padding-left: 5px;
      padding-right: 5px;
    }
    @extend .common-container;
    h1{
      color: #5C83CE;
        font-size: 3.5rem!important;
      text-align: center;
      @media screen and (max-height: 700px) {
        font-size: 2.5rem!important;
      }
    }
    .header-size{
      height: calc(100vh - 220px);
      @include breakpointDown(md){
        height: calc(100vh - 100px);
      }
      @media screen and (max-height: 850px) {
        height: calc(100vh - 150px);
      }
    }
    li{
      span{
        color: #5C83CE;
        font-weight: bold;
      }
    }
    .header-img{
      @media screen and (max-width: 1204px){
        display: none;
      }
      @media screen and (max-height: 850px) {
        width: 70%;
      }
    }
    .header-title{

      h2{
        color: #202020;
        text-align: center;
        font-size: 3rem;
        font-weight: normal;
        @media screen and (max-height: 850px) {
          font-size: 2rem!important;
        }
        span{
          font-weight: bold;
        }
      }
    }

    h2{
      color: #5C83CE;
      font-size: 2.5rem;
      text-align: center;
    }

    .col-lg-5{
      @include breakpointUp(lg){
        flex: 0 0 48.6666666667%;
        max-width: 48.666667%;
      }

    }

    .block{
      padding: 30px;
        border-radius: 30px;
        background: white;
        box-shadow: 0px 0px 30px 1px rgba(220, 233, 249, 1);
    }

    .character-block{
      padding: 30px;
        border-radius: 30px;
        background: white;
        box-shadow: 0px 0px 30px 1px rgba(220, 233, 249, 1);
      img{
        width: 100%;
        margin-bottom: 20px;
      }
      p{
        font-weight: bold;
        text-align: center;
        font-size: 1.5rem;
        margin:0;
        color: #5C83CE;
      }
      h3{
        font-size: 0.8rem;
        text-align: center;
        font-weight: normal;
        color: #202020;
        margin: 0;
      }
    }

  }
}

@include InitializeWhoAreYou();