@mixin InitializeConfirmation(){
  .confirmation-modal-container{
    background: white;
    border-radius: 5px;
    padding: 40px;
    text-align: center;
    box-shadow: 0px 0px 30px 1px rgba(43, 49, 145, 0.1);
    border-radius: 30px;
    p{
      margin-top: 30px;
    }
  }



  .button-container{
    display: flex;
    justify-content: center;
    gap : 20px;
    margin-top: 30px;
  }

  .button-confirmation{
    background: none;
    border: 2px solid #5C83CE;
    border-radius: 5px;
    color: #5C83CE;
    padding: 5px 20px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    &:hover{
      background: #5C83CE;
      color: white;
    }
  }
}

@include InitializeConfirmation();