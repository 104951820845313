@mixin InitializeArticle(){
  .article-container{
    div[class*='cm-theme']{
      @for $i from 760 through 300  {
        @media screen and (max-width: #{$i}px){
            width: #{$i}px;
        }
      }
    }
    .background-image{
      background-image: url("./image/image-fond-haut.jpg") ;
      background-size: contain;
      background-repeat: no-repeat;
    }
    ul{
      li{
        list-style: none;
        margin-bottom: 10px;
        &:before{
          display: inline-block;
          content: "";
          background: #5c83ce;
          font-weight: bold;
          min-width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 5px;
          margin-left: -15px;
          margin-bottom: 2px;
        }
      }
    }
    h1{
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
      color: #5C83CE;
        @include breakpointDown(sm){
            font-size: 2rem;
        }
    }

    h2{
      margin-top: 40px;
      color: #5C83CE;
    }
  }
}

@include InitializeArticle();